import React from "react";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";
import $ from 'jquery';
import { flexbox } from "@material-ui/system";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import axios from "axios";
import * as Constants from "utils/constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flex: '1',
  },
  container: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  headline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.5rem'
  },
  shortdesc: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    marginTop: '10px',
    fontSize: '0.65rem',
    fontFamily: 'gilroy-regular',
  },
  tagline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.25rem',
    marginTop: '100px',
    "& p": {
      transform: 'rotate(90deg)',
      width: '0',
      margin: '0 0 0 10px'
    }
  },
  catName: {
    fontFamily: "gilroy-regular",
    fontSize: "0.8rem",
    "& p": {
      textTransform:"capitalize",
      fontSize: "0.6rem"
    }
  },
  paperContainer:{
    display:'inline-flex',
    flexWrap: 'wrap',
    gap: '8vw',
    justifyContent: 'center'
  },
  paperBkgnd: {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(0,0,0,0.3)',
    borderRadius: '20px',
    width: '100%',
    height: '100%'
  },
  paper: {
    width: '40vw',
    height: '40vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: 'white',
    position: 'relative',
    zIndex: '1',
    border: '1px solid #000000',
    borderRadius: '20px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    fontSize: '1.2rem',
    "&.selected" : {
      border: '1px solid #E04420'
    },
    "& i": {
      fontSize: '2rem'
    },
    "& span": {
      fontSize: '10px'
    },
    "& label": {
      fontSize: '14px',
      fontFamily: 'gilroy-bold'
    },
    "&::after": {
      content: "",
      position: 'absolute',
      opacity: '0.7',
      filter: 'alpha(opacity=70)',
    }
  },
  checkout: {
    padding: '15px 25px',
    borderRadius: '20px',
    width: '85vw',
    marginBottom: '80px',
    backgroundColor: '#E04420',
    fontFamily: 'gilroy-regular !important',
    textTransform: 'uppercase',
    "& div.MuiAlert-message" : {
      width: '100%',
    },
    "& p": {
      fontSize: '0.6rem',
      letterSpacing: '2px',
    },
    "& a": {
      fontSize: '0.6rem',
      letterSpacing: '2px',
      padding: '5px 10px',
      borderRadius: '15px',
      color: 'black',
      backgroundColor: 'white',
      marginTop: '10px'
    },
    "& span": {
      fontSize: '1rem',
      display: 'block',
      marginTop: '10px'
    },
    "& label": {
      fontSize: '1.5rem',
      fontFamily: 'gilroy-bold',
    },
    buy: {
      background: '#E04420',
      padding: '2px 3px',
      margin: '5px 0',
      color: 'white'
    },
  }
}));

export default function ShopCategory(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [shopList, setShopList] = React.useState([]);
  const [totalItems, setTotalItems] = React.useState(localStorage.tapx_cart == undefined ? 0 : JSON.parse(localStorage.tapx_cart).length);
  const [totalAmount, setTotalAmount] = React.useState(localStorage.tapx_cart == undefined ? 0 : JSON.parse(localStorage.tapx_cart).reduce((a, {shop_price,qty}) => a + (shop_price*qty), 0));
  const [tapxcart, setTapxCart] = React.useState(localStorage.tapx_cart == undefined ? [] : JSON.parse(localStorage.tapx_cart));
  let { catid } = useParams();

  const handleAddCart = (event) => {
    event.preventDefault();
    //if(event.currentTarget.parentElement.parentElement.classList.contains('selected')) return;
    if(event.currentTarget.dataset.change == "add") {
      //Get Item Id and Item Qty
      const shop_obj = {};
      shop_obj.shop_id = parseInt(event.currentTarget.parentElement.parentElement.dataset.shopid);
      shop_obj.shop_price = parseInt(event.currentTarget.parentElement.parentElement.dataset.shopprice);
      shop_obj.qty = 1;
      tapxcart.push(shop_obj);
      setTapxCart(tapxcart);
      //Add or Update LocalStorage
      setTotalItems(parseInt(totalItems) + 1);
      setTotalAmount(parseInt(totalAmount) + parseInt(event.currentTarget.parentElement.parentElement.dataset.shopprice));
      localStorage.setItem("tapx_cart", JSON.stringify(tapxcart));
      // event.currentTarget.parentElement.parentElement.classList.add('selected');
      // event.currentTarget.setAttribute("data-change","remove");
      // event.currentTarget.innerHTML = "<i class='icon-Icons_Block'></i>";
      // setOpen(true);
    }
    else {
      if(localStorage.tapx_cart == undefined) return;
      const shopid = parseInt(event.currentTarget.parentElement.parentElement.dataset.shopid);
      const shopprice = parseInt(event.currentTarget.parentElement.parentElement.dataset.shopprice);
      let tapx_cart = JSON.parse(localStorage.tapx_cart);
      const updateCart = tapxcart.findIndex(x => x.shop_id == shopid);
      // setTotalItems(parseInt(totalItems) - 1);
      // setTotalAmount(parseInt(totalAmount) - (shopprice*tapx_cart[updateCart].qty));

      //Remove Item
      const idToRemove = shopid;
      const filteredCart = tapx_cart.filter((item) => item.shop_id !== idToRemove);
      // setTapxCart(filteredCart);
      localStorage.setItem("tapx_cart", JSON.stringify(filteredCart));

      //Remove Selected
      // event.currentTarget.parentElement.parentElement.classList.remove('selected');
      // event.currentTarget.setAttribute("data-change","add");
      // event.currentTarget.innerHTML = "<i class='icon-Icons_Add'></i>";
      // (parseInt(totalItems) - 1) != 0 ? setOpen(true) : setOpen(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    if(catid == "all") {
      axios.get(Constants.API_URL+"/api/shops").then((response) => {
          setShopList(response.data.items);
          setLoading(false);
          (tapxcart.length > 0) ? setOpen(true) : setOpen(false);
      });
    }else {
      axios.get(Constants.API_URL+"/api/shops/"+catid).then((response) => {
          setShopList(response.data.items);
          setLoading(false);
          (tapxcart.length > 0) ? setOpen(true) : setOpen(false);
      });
    }
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container spacing={5} maxWidth="md" component="main">
        <Box pt={4}>
            <React.Fragment>
              <Typography className={classes.headline} variant="h4" component="h2" >
                Shop
              </Typography>
              {shopList.length > 0 ? (
                <Typography className={classes.shortdesc} component="p" >
                  {shopList.length} Items Found
                </Typography>
              ) : "No Item Found" }

            </React.Fragment>
        </Box>
        {!loading ? (
            <Box className={classes.paperContainer} mt={2} mb={12}>
              {shopList.map((items, index) => (
                items.active ? (
                <Link to={"/shop/details/"+items.id}>
                  <Paper
                    key={index}
                    data-shopid={items.id}
                    data-shopprice={parseInt(items.shop_price)}
                    className={`${tapxcart.findIndex(x => x.shop_id === items.id) == -1 ? "" : "selected"} ${classes.paper}`}
                    style={{"background-image":"url("+items.shop_thumb+")"}}
                    elevation={0}>
                    <Box className={classes.paperBkgnd} ></Box>
                    <Box display="flex" justifyContent="flex-start" width="90%" alignItems="flex-start" flexWrap="wrap" flexDirection="column" className={classes.catName}>
                        <Typography className={classes.shopType} variant="p" component="p">
                          {items.shop_type}
                        </Typography>
                        <Typography className={classes.shopName} variant="p" component="h3">
                          {items.shop_name}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="90%" alignItems="center">
                        <Typography className={classes.shopPrice} variant="p" component="p">
                          <span class="icon-Icons_Rupee"><label>{parseInt(items.shop_price)}</label></span>
                        </Typography>
                      <Typography component="div">
                        <Link to={"/shop/checkout?item_id="+items.id}>
                        <Typography data-change="add" className={classes.shopAddCart} variant="p" component="h3">
                          <i class="icon-Icons_Add" style={{fontSize:"1.5rem"}}></i>
                        </Typography>
                        </Link>
                        {/*<Link to={"/shop/checkout?item_id="+items.id}>*/}
                        {/*  <Button variant="contained" color="secondary">*/}
                        {/*    <b>BUY</b>*/}
                        {/*  </Button>*/}
                        {/*</Link>*/}
                      </Typography>
                    </Box>
                  </Paper>
                </Link>
                ) : ""
              ))}
              {/*<Snackbar open={open} onClose={handleCloseSnackbar}>*/}
              {/*  <Alert icon={false} className={classes.checkout} severity="warning">*/}
              {/*    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">*/}
              {/*      <Typography>*/}
              {/*        Total Amount*/}
              {/*      </Typography>*/}
              {/*      <Typography>*/}
              {/*        {totalItems} items added*/}
              {/*      </Typography>*/}
              {/*    </Box>*/}

              {/*    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">*/}
              {/*      <Typography>*/}
              {/*        <span class="icon-Icons_Rupee"><label>{totalAmount}</label></span>*/}
              {/*      </Typography>*/}
              {/*      <Link to="/shop/checkout">*/}
              {/*        Checkout*/}
              {/*      </Link>*/}
              {/*    </Box>*/}
              {/*  </Alert>*/}
              {/*</Snackbar>*/}
            </Box>
        ) : (
            <Box class="loader loader-quart"></Box>
        )}
      </Container>
    </React.Fragment>
  );
}


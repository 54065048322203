import React from "react";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { flexbox } from "@material-ui/system";
// import {merchantHome, merchantHomeCategories} from '../merchants/merchantdata';
import {merchantHome, merchantList} from '../merchants/merchantdata';
import axios from "axios";
import * as Constants from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flex: '1',
  },
  merchants: {
    background: 'url(/assets/images/merchant/Links_Merchants.jpg) center top fixed',
    backgroundSize: 'cover'
    // background: 'linear-gradient(106deg, #c31432 63%, #240b36 60%) fixed'
  },
  media: {
    height: 140
  },
  container: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  headline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.5rem'
  },
  shortdesc: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    marginTop: '10px',
    fontSize: '0.75rem'
  },
  tagline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.25rem',
    marginTop: '100px',
    "& p": {
      transform: 'rotate(90deg)',
      width: '0',
      margin: '0 0 0 10px'
    }
  },
  catName: {
    textAlign: 'center',
    padding: '0 5px'
  },
  paperContainer:{
    display:'flex',
    flexWrap: 'wrap'
  },
  paper: {
    width: '40vw',
    height: '40vw',
    margin: '30px 5vw 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '1.2rem',
    "& i": {
      fontSize: '3rem'
    }
  },
  merchantName: {
    fontFamily: "gilroy-bold",
    fontSize: "1rem",
    padding: "0 8px",
    textTransform: "capitalize",
  }
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function MerchantHome(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000)
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container spacing={5} className={classes.merchants} maxWidth="md" component="main">
        <Box pt={4}>
          {merchantHome.map((items, index) => (
            <React.Fragment>
              <Typography className={classes.headline} variant="h4" component="h2" >
                {items.title}
              </Typography>
              <Typography className={classes.shortdesc} component="p" >
                {items.short_desc}
              </Typography>
              <Typography className={classes.tagline} variant="h5" component="h4">
                {items.description}
                  <p><i class="icon-Icons_Go-To"></i></p>
              </Typography>
            </React.Fragment>
          ))}
        </Box>

        <Box className={classes.paperContainer} mt={2}>
          {merchantList.map((items, index) => (
              <Link to={"/merchants/"+items.id}>
                <Paper key={index} className={classes.paper} style={{"background":"linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("+items.merchant_thumb+") no-repeat center center","background-size":"cover"}} elevation={0}>
                  <Box className={classes.merchantName}>
                    {items.merchant_name}
                  </Box>
                </Paper>
              </Link>
          ))}
        </Box>
        {/*<Box className={classes.paperContainer} mt={2}>*/}
        {/*  {merchantHomeCategories.map((items, index) => (*/}
        {/*    items.active ? (*/}
        {/*      <Link to={"merchants/category/"+items.id}>*/}
        {/*        <Paper key={index} className={classes.paper} style={{"backgroundColor":items.cat_color}} elevation={0}>*/}
        {/*          <Box>*/}
        {/*            <i class={"icon-"+items.cat_icon}></i>*/}
        {/*          </Box>*/}
        {/*          <Box className={classes.catName}>*/}
        {/*            {items.cat_name}*/}
        {/*          </Box>*/}
        {/*        </Paper>*/}
        {/*      </Link>*/}
        {/*    ) : ""*/}
        {/*  ))}*/}
        {/*</Box>*/}
      </Container>
    </React.Fragment>
  );
}

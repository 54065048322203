import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';

export default function NoMatch() {
    return (
        <Box mt={10}>
            <img width="100%" src="https://cdn.searchenginejournal.com/wp-content/uploads/2017/08/25-Outstanding-404-Page-Examples-You-Have-to-See.png"  />
        </Box>
    )
}
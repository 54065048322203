import React from "react";
import axios from "axios";
import * as Constants from "utils/constants";
import cardsService from "../../../services/cards.service";
import { Button, Container, Card, CardContent, CardActions, CssBaseline, Box, Typography, MobileStepper, Paper} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Link } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import { deepOrange, green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import moment from "moment";


const AutoPlaySwipeableViews = SwipeableViews;

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 400,
      flexGrow: 1,
    },
    list: {
        width: '100%',
        backgroundColor: 'transparent',
        maxHeight: '70vh',
        overflowX: 'auto',
        "& li": {
            margin: '0 0 15px 0',
            borderRadius: '20px',
            color: 'white'
        },
        "& li.completedtopup": {
            backgroundColor: 'rgb(114, 69, 88)',
            // backgroundColor: 'rgb(80, 80, 242)',
        },
        "& li.completedsale": {
            // backgroundColor: 'rgb(79, 69, 128)',
            backgroundColor: 'rgb(80, 80, 242)',
        },
        "& li.pendingtopup,li.pendingsale , li.device_pendingtopup, li.card_pendingtopup": {
            backgroundColor: 'rgb(246, 147, 18)',
        },
        "& li.payment_failedtopup, li.payment_timeouttopup": {
            backgroundColor: 'rgb(224, 68, 30)',
        }
    },
    listPrimary : {
      color: '#ffffff',
      fontSize: '0.85rem',
      "& label" : {
        fontSize: "1.15rem",
        fontFamily: "gilroy-bold"
      },
      "& label.txnno" : {
        fontSize: '0.75rem'
      }
    },
    listSecondary : {
      color: '#ffffff',
    },
    stepper: {
        backgroundColor: 'transparent',
        justifyContent: 'center',
        "& .MuiMobileStepper-dot" : {
            border: '1px solid #ffffff',
            background: 'transparent'
        },
        "& .MuiMobileStepper-dotActive" : {
            background: '#ffffff'
        }
    },
    header: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    container: {
        // background: 'linear-gradient(106deg, #ff7e5f 63%, #feb47b 60%) fixed'
    },
    addFirst: {
        color: 'rgb(246, 147, 18)'
    },
    transactionsView: {
        marginTop: "16px",
    },
    img: {
      height: 255,
      display: 'block',
      maxWidth: 400,
      overflow: 'hidden',
      width: '100%',
    },
    headline: {
      fontFamily: 'gilroy-extrabold',
      fontSize: '1.6rem'
    },
    tagContainer : {
        display: 'flex',
        justifyContent: 'center',
    },
    tagList : {
        display: 'flex',
        background: '#323031',
        borderRadius: '10px',
        width: '80vw',
        "& img" : {
            boxShadow:'0 0 10px rgba(0, 0, 0, 0.5)',
            borderRadius: '10px',
            width: '50%',
            height: '100%',
        },
        "& div.info" : {
            padding: '10px',
            textAlign: 'left',
            position: 'relative',
            width: '50%',
            "& p": {
                fontFamily: 'gilroy-regular',
                fontSize: '0.75rem',
                margin: '0',
                "&.tagid": {
                    letterSpacing: '0.1rem',
                    fontSize: '0.6rem'
                },
                "&.tagname": {
                    fontSize: '1.3rem'
                },
                "&.tagbalance": {
                    fontSize: '1.15rem',
                    "& label": {
                        fontSize: '1.5rem',
                        fontFamily: 'gilroy-bold'
                    }
                },
            },
            "& div.tagblock": {
                position: 'absolute',
                right: '10px',
                fontSize: '1.25rem',
                bottom: '0',
            }
        }
    },
    rounded: {
      color: '#fff',
      backgroundColor: "#323031",
      fontSize: "0.75rem",
      padding: "0 8px"
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    internalGrid: {
        borderTop: "1px solid",
    },
    itemText:{
        fontSize: ".7rem",
        letterSpacing:"1px"
    },
    tagIDText:{
        fontFamily:"gilroy-regular",
        fontSize:"14px"
    },
    tagTypeText:{
        fontFamily:"gilroy-bold",
        fontSize:"20px"
    },
    tagBalanceText:{
        fontFamily:"gilroy-bold",fontSize:"20px",letterSpacing:"1px"
    },
    tagBalanceIcon:{

    },
    currentBalanceText:{
        fontFamily:"gilroy-regular",fontSize:"10px",letterSpacing:"1px"
    },
    defaultTagTaxt:{
        fontSize:".7rem",
        fontFamily:"gilroy-regular",
        letterSpacing:"1px"
    }

}));

export default function Transaction(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [showInfo, setShowInfo] = React.useState();
    const [activeStep, setActiveStep] = React.useState(0);
    const [txnData, setTxnData] = React.useState([]);
    const [allTags, setAllTags] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openAlert, setOpenAlert] = React.useState(false);
    const open = Boolean(anchorEl);
    const [isBusy, setBusy] = React.useState(true);
    const maxSteps = allTags.length;
    const user_id = props.currUser.id;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
            setBusy(true);
        axios.get(Constants.API_URL+"/api/transactions/" + allTags[step].tag_number+"/"+user_id).then((response) => {
            const data = [];
            console.log("response.data");
            console.log(response.data);
            data.push(response.data);
            setTxnData(data);
            setBusy(false);
        });
    };

    const handleTxnList = (index) => {
        if (selectedIndex === index) {
            setSelectedIndex("")
        } else {
            setSelectedIndex(index)
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    React.useEffect(() => {
        if(props.currUser.default_tag == null ) {
            setBusy(false);
        }else {
            cardsService.cardsInfo(props.currUser.id).then((response) => {
                setAllTags(response.TagData);
                setBusy(true);

                axios.get(Constants.API_URL+"/api/transactions/" + response.TagData[0].tag_number+"/"+user_id).then((response) => {
                    const data = [];
                    console.log("responce");
                    console.log(response.data);
                    data.push(response.data);
                    setTxnData(data);
                    setBusy(false);
                })

            })
        }
    }, []);

    return (
        <React.Fragment>
            <CssBaseline />
            <Container spacing={2} className={classes.container} maxWidth="md" component="main">
                <Box pt={4}>
                    <Typography className={classes.headline} >
                        Transactions
                    </Typography>
                    {props.currUser != "" ? (
                        <span className={classes.defaultTagTaxt}>
                            DEFAULT TAG : ( {props.currUser.default_tag == null ? (
                                <Link className={classes.addFirst} to={"/tags/"+props.currUser.id}>
                                    <b>Add First Tag</b>
                                </Link>
                            ) : props.currUser.default_tag
                            } )
                        </span>
                    ) : ""}

                </Box>
                {props.currUser != "" ? (
                    <React.Fragment>
                        {isBusy ? (
                            <Box class="loader loader-quart"></Box>
                        ) : (
                        <Box className={classes.transactionsView}>
                            <AutoPlaySwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {allTags.map((tags, index) => (

                                        <div className={classes.tagContainer} key={tags.tag_id}>
                                    {Math.abs(activeStep - index) <= 2 ? (
                                            <Grid container direction="row"  style={{ height: "165px"}}>
                                                <Grid item xs={6}>
                                                    <img style={{objectFit:"cover",width: "100%",height: "160px",borderRadius:"16px"}} src={tags.tag_img} />
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <Grid item xs container direction="column" justify="space-between" alignItems="baseline" style={{height: "100%",padding:"8px"}}>
                                                        <Grid item xs alignItems="center">
                                                        <Typography  className={classes.currentBalanceText}> TAG ID<br/></Typography>
                                                            <Typography className={classes.tagIDText}>
                                                               {tags.tag_number}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs alignItems="center">
                                                             <span className={classes.tagTypeText}>   {tags.tag_name} </span>
                                                        </Grid>
                                                        <Grid item xs alignItems="center" style={{display:"contents"}}>
                                                            <Typography  className={classes.currentBalanceText}>Balance <br/>
                                                            </Typography>
                                                            <Typography  className={classes.tagBalanceText}>
                                                                
                                                                    <span class='icon-Icons_Rupee' style={{fontSize: '0.98rem'}}></span>{parseInt(tags.tag_balance).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </Typography>
                                                        </Grid>

                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                    // <img className={classes.img} src={step.imgPath} alt={step.label} />
                                    // <Box mb={2} key={tags.tag_id} className={classes.tagList} style={{textAlign: 'center'}} >
                                    //     <img src={tags.tag_img} />
                                    //     <Box class="info">
                                    //
                                    //         {/* <Typography component="div" onClick={handleClickAlert} class="tagblock"> <span class='icon-Icons_Block'></span></Typography>  */}
                                    //     </Box>
                                    // </Box>
                                    ) : null}
                                </div>
                                ))}
                            </AutoPlaySwipeableViews>
                            <MobileStepper
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                className={classes.stepper}
                            />

                            <Dialog
                                open={openAlert}
                                onClose={handleCloseAlert}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Do you really want to block this tag?"}</DialogTitle>
                                <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    This tag allows you transact at different merchants of your choice. Blocking the card will
                                    disable to work anywhere on TapX merchants. You can unblock the tag when you want to reuse.
                                </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleCloseAlert} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleCloseAlert} color="primary" autoFocus>
                                    Block
                                </Button>
                                </DialogActions>
                            </Dialog>

                            <Paper square elevation={3} className={classes.header}>
                                <Box pt={2}>
                                    {() => {
                                        const txnInfo = txnData[0];
                                        if(txnInfo != undefined) {
                                            return (
                                                // console.log(data.createdAt + "-" +data.amount);
                                                // showData += data.amount;
                                                <List className={classes.list}>
                                                    {(txnInfo.length == 0) ? (
                                                            <React.Fragment>
                                                                <ListItem>
                                                                    No Transactions Found.
                                                                </ListItem>
                                                            </React.Fragment>
                                                        ) : (
                                                        txnInfo.map((data, index) => (
                                                            <React.Fragment key={index} >
                                                                <ListItem onClick={()=>{handleTxnList(index)}} className={data.status+data.type}>
                                                                    <Grid container spacing={2} direction="row" justify="space-between" alignItems="flex-start">
                                                                        <Grid item xs style={{padding:"12px"}}>
                                                                            <Typography style={{fontFamily:"gilroy-bold",fontSize:"15px"}}>
                                                                            {data.name}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item spacing={2} style={{ marginTop: "8px",marginRight:"8px" }} xs={9} alignItems="flex-end" >
                                                                            <Grid container  className={classes.internalGrid}  alignItems="flex-end" >
                                                                                <Grid item  xs>
                                                                                    <Grid container justify="space-between" alignItems="flex-end">
                                                                                        <Grid item  direction="row" style={{marginTop:"10px"}}>
                                                                                            <Typography className={classes.itemText}>
                                                                                                {moment(data.txn_at).utc().format("DD/MM/YY")}
                                                                                            </Typography>
                                                                                            {(data.id!=null) ? (
                                                                                            <Typography
                                                                                                className={classes.itemText}
                                                                                                style={{display: "Inline"}}>
                                                                                                TXN. NO.{data.id}
                                                                                            </Typography>
                                                                                                ):(<Typography className={classes.itemText} >
                                                                                                {(data.status == 'completed')?"COMPLETED":((data.status == 'card_pending' || data.status == 'device_pending' || data.status == 'pending' )?"PENDING":((data.status == 'payment_failed' || data.status == 'payment_timeout')?"FAILED":""))}
                                                                                            </Typography>)}

                                                                                        </Grid>
                                                                                        <Grid item xs direction="row" style={{paddingRight:"10px",textAlign: "end",letterSpacing:"1px"}}>

                                                                                            <Typography component="span">
                                                                                                <span style={{fontSize:"1rem"}}> {(data.type == 'topup')?"+":"-"}</span>
                                                                                                <span class='icon-Icons_Rupee' style={{fontSize: '0.7rem'}}></span>
                                                                                               <span style={{fontFamily:"gilroy-bold"}}>{parseInt(data.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item className="gly-rotate-45" style={{paddingLeft: "5px",paddingBottom: "8px"}}>
                                                                                    <span className="icon-Icons_Go-To" style={{fontSize: '1.2rem'}}></span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {/*<ListItemAvatar>*/}
                                                                    {/*    <Typography>*/}
                                                                    {/*        {data.name}*/}
                                                                    {/*    </Typography>*/}
                                                                        {/*<Avatar variant="rounded" className={classes.rounded}>*/}
                                                                        {/*    { (() => {*/}
                                                                        {/*        const txnDate = new Date(data.txn_at);*/}
                                                                        {/*        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];*/}
                                                                        {/*        console.log(new Date(data.txn_date));*/}
                                                                        {/*        return (txnDate.getDate().toString() + " " +*/}
                                                                        {/*        months[txnDate.getMonth()] + " " +*/}
                                                                        {/*        txnDate.getFullYear().toString());*/}
                                                                        {/*    })() }*/}
                                                                        {/*</Avatar>*/}
                                                                    {/*</ListItemAvatar>*/}
                                                                    {/*<ListItemText*/}
                                                                    {/*    primary={<span class='icon-Icons_Rupee'>*/}
                                                                    {/*        <label>{data.amount}</label>*/}
                                                                    {/*        <label class="txnno"><br/>TXN. No. {data.txn_id}</label>*/}
                                                                    {/*        </span>}*/}
                                                                    {/*    secondary={data.txn_at}*/}
                                                                    {/*    classes= {{*/}
                                                                    {/*        primary : classes.listPrimary,*/}
                                                                    {/*        secondary: classes.listSecondary*/}
                                                                    {/*    }}*/}
                                                                    {/*/>*/}

                                                                </ListItem>
                                                            </React.Fragment>
                                                        )
                                                    ))}
                                                </List>
                                            )
                                        }
                                    }}
                                </Box>
                            </Paper>

                        </Box>
                        )}
                    </React.Fragment>
                ) : (
                    <Box className="login_message">
                        You need to <Link to="/signin">LOGIN</Link> to view Transactions
                    </Box>
                )}
            </Container>
        </React.Fragment>
    )
}

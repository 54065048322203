import React from "react";

export default function TestSubmit() {
    React.useEffect(() => {
        
    document.getElementById("redirectForm").submit();
    }, []);
    return (
        <div>
        </div>
    )
}
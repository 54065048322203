import React from "react";
import { Link } from 'react-router-dom';
import { flexbox } from "@material-ui/system";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import axios from "axios";
import * as Constants from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flex: '1',
  },
  shops: {
    background: 'url(/assets/images/Links_Shops.jpg) center top fixed',
    backgroundSize: 'cover'
    // background: 'linear-gradient(106deg, #c31432 63%, #240b36 60%) fixed'
  },
  media: {
    height: 140
  },
  container: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  headline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.5rem'
  },
  shortdesc: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    marginTop: '10px',
    fontSize: '0.75rem'
  },
  tagline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.25rem',
    marginTop: '100px',
    "& p": {
      transform: 'rotate(90deg)',
      width: '0',
      margin: '0 0 0 10px'
    }
  },
  catName: {
    textAlign: 'center',
    padding: '10px 5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing:"2px",
    fontSize: '.8rem',
  },
  paperContainer:{
    display:'flex',
    flexWrap: 'wrap'
  },
  paper: {
    width: '40vw',
    height: '40vw',
    margin: '30px 5vw 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    color: 'white',
    fontSize: '1.2rem',
    "& i": {
      fontSize: '3rem'
    },
    borderRadius:'25px'
  },
  step: {
    paddingTop: '20px',
    borderTop: '1px solid rgba(255,255,255,0.15)',
    fontFamily: 'gilroy-regular',
    letterSpacing:"1px",
    fontSize:".7rem",
  },
}));

export default function Shop(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [tagCategories, setTagCategories] = React.useState([]);

  React.useEffect(() => {
    axios.get(Constants.API_URL+"/api/tags/categories").then((response) => {
        setTagCategories(response.data.shopCategory);
        setLoading(false);
    });
  }, []);

  return (
    <React.Fragment >
      <CssBaseline />
      <Container spacing={5} className={classes.shops} maxWidth="md" component="main" >
        <Box pt={4}>
            <React.Fragment>
              <Typography className={classes.headline} variant="h4" component="h2" >
                Shop
              </Typography>
              <Typography className={classes.shortdesc} component="p" >
                Explore wide range of Tags.
              </Typography>
              <Typography className={classes.tagline} variant="h5" component="h4">
                TapX Tags
                <p><i class="icon-Icons_Go-To"></i></p>
              </Typography>
            </React.Fragment>
        </Box>
        {!loading ? (
            <Box>
              <Box pt={3}>
                <Typography className={classes.step}  >
                  <span style={{marginTop:"20px"}}>CATEGORIES</span>
                </Typography>
              </Box>
            <Box className={classes.paperContainer} >

                {tagCategories.length > 1 ? (
                <Link to={"shop/category/all"}>
                    <Paper className={classes.paper} style={{"backgroundColor":"#323031"}} elevation={0}>
                    <Box className={classes.catName}>
                        See All
                    </Box>
                    </Paper>
                </Link>
                ) : "" }

                {tagCategories.map((items, index) => (
                items.active ? (
                <Link to={"shop/category/"+items.id}>
                    <Paper key={index} className={classes.paper} style={{"backgroundColor":items.tag_color}} elevation={0}>
                    <Box>
                        <i class={"icon-"+items.tag_icon}></i>
                    </Box>
                    <Box className={classes.catName}>
                        {items.tag_name}
                    </Box>
                    </Paper>
                </Link>
                ) : ""
            ))}
            </Box>
            </Box>
        ) : (
            <Box class="loader loader-quart"></Box>
        )}
      </Container>
    </React.Fragment>
  );
}

import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import cardsService from "../../../services/cards.service"; 

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    }
}));


export default function AddCard(props) {
    const classes = useStyles();
    const [tag_type, setTagType] = React.useState('');
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    console.log(props.currUser.id);

    const handleCards = () => {
        cardsService.addCard(
          props.currUser.id ,
          document.getElementsByName("tag_number")[0].value.toString(), 
          document.getElementsByName("tag_type")[0].value.toString(), 
          "0",
          0).then((response) => {
            console.log(response); 
          },
          (error) => {
            const resError =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            //console.log(resError); 
          });
        setOpen(false);
      };

      const handleChange = (event) => {
        setTagType(event.target.value);
      };


    return (
        <React.Fragment>
            <Tooltip placement="right" onClick={handleClickOpen} title="Add New Card" aria-label="add">
                <Fab color="primary" className={classes.fab}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Add New Card</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Add New Tag to Top-Up Your Account.
                </DialogContentText>

                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="card-type-label">Tag Type</InputLabel>
                    <Select
                      labelId="card-type-label"
                      id="tag_type"
                      name="tag_type"
                      value={tag_type}
                      onChange={handleChange}
                    >
                      <MenuItem value="card">Card</MenuItem>
                      <MenuItem value="wristband">Wristband</MenuItem>
                      <MenuItem value="ring">Ring</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="tag_number"
                      name="tag_number"
                      label="Tag Number"
                      type="text"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleCards} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
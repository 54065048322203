import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import { Box, Paper, Grid, Typography, Button, Chip } from "@material-ui/core/";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
// import LinkMat from '@material-ui/core/Link';
// import { Link } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import * as Constants from "utils/constants";

const useStyles = makeStyles((theme) => ({
	formControl: {
		minWidth: "100%",
		color: "#ffffff",
	},
	tag_amount: {
		fontFamily: "gilroy-bold",
		letterSpacing: "0.1rem",
		fontSize: "0.75rem",
		"& label": {
			color: "white",
		},
		"& .MuiFormLabel-root.Mui-focused": {
			color: "white",
		},
		"& input": {
			color: "white",
		},
		"& .MuiInput-underline:before": {
			borderBottom: "1px solid #ffffff",
		},
		"& .MuiInput-underline:after": {
			borderBottom: "1px solid #ffffff",
		},
		"& .MuiInputBase-root": {
			fontSize: "1.5rem",
		},
	},
	amountList: {
		display: "flex",
		marginBottom: "20px",
	},
	chips: {
		color: "#ffffff",
		border: "1px solid #ffffff",
		marginRight: "10px",
		padding: "0 5px",
		borderRadius: "15px",
		"& span.icon-Icons_Rupee": {
			fontSize: "0.65rem",
		},
		"&.selected": {
			border: "1px solid #E04420",
			backgroundColor: "#E04420",
		},
	},
}));

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function SelectAmount() {
	const classes = useStyles();
	let query = useQuery();
	const { register, errors, control, handleSubmit } = useForm({
		mode: "onChange",
	});
	const [tagAmount, setTagAmount] = React.useState(
		query.get("amount") != undefined ? query.get("amount") :
										query.get("eventId") == 973 ? "1000" :
										"500"
	);

	const [topupAmounts, setTopupAmounts] = React.useState(
		query.get("eventId") == 973 ? [ "1000", "5000", "10000", ] : [ "500", "1000", "5000", "10000", ]
	);

	const [eventId, setEventId] = React.useState("");

	const [activeStep, setActiveStep] = React.useState(0);

	const handleChipClick = (event) => {
		$(".amountChip").removeClass("selected");
		console.log(event.currentTarget.getAttribute("data-amount"));
		event.currentTarget.classList.add("selected");
		$("#pay_amount").val(
			parseInt(event.currentTarget.getAttribute("data-amount"))
		);
		//event.currentTarget.classList.add('selected');
		//setTagAmount(parseInt(event.currentTarget.innerText));
	};
	const handleChipChange = (value) => {
		setTagAmount(value);
	};

	React.useEffect(() => {
		if (query.get("eventId")) {
			setEventId(query.get("eventId"));
			// props.handler(true);
		} else {
			// if(parseInt(query.get("step")) != 3) {
			// 	document.getElementById("step"+(activeStep + 1)).scrollIntoView(true);
			// }
			// else {
			// 	document.getElementById("step3").scrollIntoView(true);
			// }
		}
	}, []);

	// React.useEffect(() => {
	// 	axios.get(Constants.API_URL + "/api/topup/amounts").then((response) => {
	// 		setTopupAmounts(response.data.allAmounts);
	// 	});
	// }, []);
	return (
		<React.Fragment>
			<Grid container alignItems="flex-start" direction="row">
				<Grid item>
					<Box className={classes.amountList}>
						{topupAmounts.map((items, index) => (
							<Typography
								component="div"
								key={index}
								onClick={handleChipClick}
								className={`
                              ${classes.chips}
                              amountChip
                              ${items == tagAmount ? "selected" : ""}
                            `}
								data-amount={items}
							>
								<Typography component="span">
									{eventId == 282 ? (
										<small>AED </small>
									) : (
										<span class="icon-Icons_Rupee"></span>
									)}
									<label>{items}</label>
								</Typography>
							</Typography>
						))}
					</Box>
					<FormControl className={classes.formControl}>
						OR <br />
						<TextField
							id="pay_amount"
							label="ENTER AMOUNT"
							name="pay_amount"
							type="number"
							inputMode="numeric"
							defaultValue={tagAmount != "" ? tagAmount : ""}
							className={classes.tag_amount}
							error={!!errors.pay_amount}
							onChange={(e) => handleChipChange(e.target.value)}
							inputRef={register({
								required: "Amount is required.",
								pattern: {
									value: /^[0-9\b]+$/,
									message: "Please enter numbers only.",
								},
								maxLength: {
									value: 5,
									message:
										"Amount cannot be greater than 99999",
								},
							})}
							helperText={
								errors.pay_amount && errors.pay_amount.message
							}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

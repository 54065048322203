import React from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import momentz from 'moment-timezone';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import LinkMat from '@material-ui/core/Link';
import { Link } from 'react-router-dom';

import Rating from '@material-ui/lab/Rating';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MapIcon from 'assets/images/icons/PNG/loc_white.png'

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import axios from "axios";
import * as Constants from "utils/constants";

const StyledRating = withStyles({
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
})(Rating);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    border:'1px solid lightgray',
  },
  card: {
    margin: '10px 0',
    color:'white',
    background: 'rgb(99 94 96)'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  chip: {
    margin: "8px 0 0 0",
    backgroundColor: "black",
    height: "auto",
    "& span": {
      fontSize : "1.5rem",
      "&.icon-Icons_Rupee": {
        fontSize : "1.05rem"
      }
    }
  },
  tabsroot: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
  media: {
    height: 140
  },
  rootDeals: {
    maxWidth: 345,
    flex: '1',
  },
  headlineDiv:{
    display:"flex"
  },
  headline: {
    fontFamily: 'gilroy-bold',
    fontSize: '1.75em',
    textTransform: "capitalize",
    width: '95%',
  },
  mapIcon:{
    width: '35px',
    height:'35px'
  },
  merchantImage: {
    width: '100%',
    margin: '20px 0 0 0',
    borderRadius: '20px'
  },
  emptyIcon : {
    color: 'rgba(255,255,255, 0.5)'
  },
  tagline: {
    margin: '20px 0 0 0',
    fontFamily: 'gilroy-bold',
    fontSize: '1.25rem'
  },
  shortdesc: {
    margin: '20px 0 0 0',
    fontFamily: 'gilroy-regular',
    fontSize: '1rem'
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontFamily: 'sourcesanspro-Regular',
    textTransform: 'uppercase',
    fontSize: '0.85rem',
    letterSpacing: '0.1rem',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListTile: {
    borderRadius: '30px',
    marginRight: theme.spacing(3),
    height: '150px',
    minWidth: '130px',
    fontFamily: 'gilroy-extrabold',
  },title: {
    color: 'white',
    fontWeight: 'bold',
  },
  titleBar: {
    background:'none',
    textAlign: 'center'
  },
  dealsImg: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    "& img": {
      width: '100px',
      height: '100px',
      borderRadius: '50%'
    }
  },
  redeem: {
    background: '#E04420',
    padding: '2px 3px',
    margin: '5px 0',
    color: 'white'
  },
  list: {
      width: '100%',
      backgroundColor: 'transparent',
      maxHeight: '60vh',
      overflowX: 'auto',
      "& li": {
          backgroundColor: '#724457',
          margin: '0 0 10px 0',
          borderRadius: '20px',
          color: 'white'
      },
      "& li.success": {
          borderLeft: '5px solid green',
      },
      "& li.failed": {
          borderLeft: '5px solid #E04420',
      },
      "& li.cancel": {
          backgroundColor: '#F89613',
      }
  },
  listPrimary : {
    color: '#ffffff',
    fontSize: '0.85rem',
    "& label" : {
      fontSize: "1.15rem",
      fontFamily: "gilroy-bold"
    },
    "& label.txnno" : {
      fontSize: '0.75rem'
    }
  },
  listSecondary : {
    color: '#ffffff',
  },
  rounded: {
    color: '#fff',
    backgroundColor: "#323031",
    fontSize: "0.75rem",
    padding: "0 8px"
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: 'white',
    position: 'relative',
    zIndex: '1',
    borderRadius: '20px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    fontSize: '1.2rem',
    "&.selected": {
      border: '1px solid #E04420'
    },
    "& i": {
      fontSize: '2rem'
    },
    "& span": {
      fontSize: '10px'
    },
    "& label": {
      fontSize: '14px',
      fontFamily: 'gilroy-bold'
    },
    "&::after": {
      content: "",
      position: 'absolute',
      opacity: '0.7',
      filter: 'alpha(opacity=70)',
    }
  },
  eventImage: {
    width: '100%',
    height: '50vw',
    borderRadius: '20px'
  },buyButton:{
    color:"white",
    borderColor:"white",
    letterSpacing: "1.5px",
    fontFamily: 'gilroy-bold'
  },shopName: {
    letterSpacing: '1.5px',
    fontSize: '6vw',
    fontFamily: 'gilroy-bold',
    "& a": {
      color: 'white',
    }
  },
  shopPrice: {
    fontSize: '5vw',
    marginTop: '10px',
    letterSpacing:'2px',
    "& label": {
      fontSize: '6vw',
      fontFamily: 'gilroy-bold',
    }
  },

}));


export default function MerchantDetails(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [merchantInfo, setMerchantInfo] = React.useState({});
  const [allDeals, setDeals] = React.useState([]);
  const [tickets, setTickets] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);
  let { catid, merchid } = useParams();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const openMap = (latlong,) => {
     window.open("https://www.google.com/maps/search/?api=1&z=18&query="+latlong);
  }
  React.useEffect(() => {
    axios.get(Constants.API_URL+"/api/merchant/details/" + merchid).then((response) => {
        console.log(response.data.items);
        setMerchantInfo(response.data.items[0]);
        setLoading(false);
    });

    axios.get(Constants.API_URL+"/api/merchant/deals/" + merchid).then((response) => {
        console.log(response.data.DealData);
        setDeals(response.data.DealData);
    });

    axios.get(Constants.API_URL+"/api/tickets/" + merchid).then((response) => {
        console.log(response.data.ticketData);
        if(response.data.ticketData != undefined) {
          setTickets(response.data.ticketData);
        }
    });
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container spacing={5}  maxWidth="md" component="main">
        {loading ? (
          <Box class="loader loader-quart"></Box>
        ) : (
          <Box>
            <Box pt={4}>
              <div  className={classes.headlineDiv}>
                <Typography className={classes.headline} variant="h4" component="h2" >
                  {merchantInfo.merchant_name}
                </Typography>
                <img src={MapIcon} className={classes.mapIcon} onClick={ () => openMap(merchantInfo.merchant_lat_long)}  />
              </div>
              {/*<Typography className={classes.headline} variant="h4" component="h2" >*/}
              {/*  {merchantInfo.merchant_name}*/}
              {/*</Typography>*/}
              <img className={classes.merchantImage} src={merchantInfo.merchant_image} />
              { (Object.keys(tickets).length === 0 && tickets.constructor === Object) ? (
                <React.Fragment>
                </React.Fragment>
              ) : (
                tickets.map((ticketInfo, index) => (
                  <React.Fragment>
                    {/*<Card className={classes.card}>*/}
                    {/*  <CardHeader*/}
                    {/*    action={*/}
                    {/*      <Chip*/}
                    {/*        color="primary"*/}
                    {/*        label={*/}
                    {/*          <Typography component="span">*/}
                    {/*            <span class='icon-Icons_Rupee'></span>*/}
                    {/*            {ticketInfo.price}*/}
                    {/*          </Typography>*/}
                    {/*        }*/}
                    {/*        className={classes.chip}*/}
                    {/*      />*/}
                    {/*    }*/}
                    {/*    title={ticketInfo.name}*/}
                    {/*  />*/}
                    {/*  <CardMedia*/}
                    {/*    className={classes.media}*/}
                    {/*    image={ticketInfo.image}*/}
                    {/*    title="Ticket"*/}
                    {/*  />*/}
                    {/*  <CardContent>*/}
                    {/*    <Typography variant="body2" component="p">*/}
                    {/*      <b>Event Start Date <br/></b> {moment(ticketInfo.event_start_date).utc().format("dddd, MMMM Do YYYY, h:mm:ss A")} <br/>*/}
                    {/*      <b><br/>Event End Date <br/></b> {moment(ticketInfo.event_end_date).utc().format("dddd, MMMM Do YYYY, h:mm:ss A")}*/}
                    {/*    </Typography>*/}
                    {/*  </CardContent>*/}
                    {/*  <CardActions disableSpacing>*/}
                    {/*    <Link to={"/tickets/checkout?ticket_id="+ticketInfo.id}>*/}
                    {/*      <Button variant="contained" color="secondary">*/}
                    {/*        <b>BUY Tickets</b>*/}
                    {/*      </Button>*/}
                    {/*    </Link>*/}
                    {/*    <IconButton*/}
                    {/*      className={clsx(classes.expand, {*/}
                    {/*        [classes.expandOpen]: expanded,*/}
                    {/*      })}*/}
                    {/*      onClick={handleExpandClick}*/}
                    {/*      aria-expanded={expanded}*/}
                    {/*      aria-label="show more"*/}
                    {/*    >*/}
                    {/*      <ExpandMoreIcon />*/}
                    {/*    </IconButton>*/}
                    {/*  </CardActions>*/}
                    {/*  <Collapse in={expanded} timeout="auto" unmountOnExit>*/}
                    {/*    <CardContent>*/}
                    {/*      <Typography paragraph>*/}
                    {/*        {ticketInfo.desc}*/}
                    {/*      </Typography>*/}
                    {/*    </CardContent>*/}
                    {/*  </Collapse>*/}
                    {/*</Card>*/}
                    {/*<Link to={"/tickets/checkout?ticket_id="+ticketInfo.id}>*/}
                      <Paper
                          key={index}
                          data-shopid={ticketInfo.id}
                          data-shopprice={parseInt(ticketInfo.price)}
                          // style={{"background-image":"url("+ticketInfo.image+") "}}
                          className={classes.paper}
                          elevation={0}>
                        <img className={classes.eventImage} src={ticketInfo.image} />
                        <div style={{position:'absolute',width:'100%',background: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5))',height:'100%'}}></div>
                        <div style={{position:'absolute',width:'100%',paddingLeft: '20px', paddingBottom: '15px'}}>
                          <Box className={classes.paperBkgnd} ></Box>
                          <Box display="flex" justifyContent="flex-start" width="90%" alignItems="flex-start" flexWrap="wrap" flexDirection="column" className={classes.catName}>
                            <Typography className={classes.shopName} variant="p" component="h3">
                              {ticketInfo.name}
                            </Typography>
                            <Typography className={classes.shopType} variant="p" component="p">
                              {moment(ticketInfo.event_start_date).utc().format("DD, MMM YYYY")}
                            </Typography>

                          </Box>
                          <Box display="flex" justifyContent="space-between" width="90%" alignItems="center">
                            <Typography className={classes.shopPrice} variant="p" component="p">
                              <span class="icon-Icons_Rupee" style={{fontSize:'4vw'}}><label>{parseInt(ticketInfo.price)}</label></span>
                            </Typography>
                            <Typography component="div">
                              <Link to={"/tickets/checkout?ticket_id="+ticketInfo.id} >
                                <Button variant="outlined"  className={classes.buyButton}>
                                  BOOK
                                </Button>
                              </Link>
                              {/*<Link to={"/shop/checkout?item_id="+items.id}>*/}
                              {/*  <Button variant="contained" color="secondary">*/}
                              {/*    <b>BUY</b>*/}
                              {/*  </Button>*/}
                              {/*</Link>*/}
                            </Typography>
                          </Box>
                        </div>
                      </Paper>
                    {/*</Link>*/}
                  </React.Fragment>
                ))
              )}

              {merchantInfo.merchant_rating ? (
                <React.Fragment>
                  <Typography className={classes.shortdesc} component="p">
                    Ratings({merchantInfo.merchant_rating})
                  </Typography>
                  <StyledRating
                    name="customized-color"
                    defaultValue={merchantInfo.merchant_rating}
                    getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                    precision={0.1}
                    size="small"
                    readOnly
                    classes={{
                      iconEmpty : classes.emptyIcon
                    }}
                    icon={<FavoriteIcon fontSize="inherit" />}
                  />
                </React.Fragment>

              ) : ""}
              <Typography className={classes.tagline} variant="h5" component="h4">
                {merchantInfo.merchant_area} <br/>
                {merchantInfo.merchant_city}
              </Typography>
              <Typography className={classes.shortdesc} component="p" >
                <i>{merchantInfo.merchant_desc}</i>
              </Typography>
            </Box>
            <Box mt={2}>
              <Grid container spacing={4}>
                {(allDeals.length > 0) ? (
                  <Grid item xs={12}>
                    <Box pb={4}>
                      <Typography className={classes.sectionTitle} component="p">
                        TOP DEALS
                      </Typography>
                      <GridList className={classes.gridList} cols={3.5}>
                        {allDeals.map((tile, index) => (
                          <GridListTile key={index} className={classes.gridListTile} style={{backgroundColor:"#323031"}}>

                            <Box className={classes.dealsImg}>
                              <img src={tile.deal_image} />
                            </Box>
                            <GridListTileBar
                              title={tile.deal_name}
                              subtitle={<Link to="/deals"><Button className={classes.redeem}>REDEEM</Button></Link>}
                              titlePosition="bottom"
                              classes={{
                                root: classes.titleBar,
                                title: classes.title,
                              }}
                            />
                          </GridListTile>
                        ))}
                      </GridList>
                    </Box>
                  </Grid>

                ) : "" }

                {/* <Grid item xs={12}>
                  <Box pb={4}>
                    <Typography className={classes.sectionTitle} component="p">
                      LATEST TRANSACTIONS
                    </Typography>
                    <List className={classes.list}>
                      {allDeals.map((tile, index) => (
                        <ListItem className="failed">
                            <ListItemAvatar>
                                <Avatar variant="rounded" className={classes.rounded}>
                                    28 Sep 2020
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={<span class='icon-Icons_Rupee'>
                                <label>2000</label>
                                <label class="txnno"><br/>TXN. No. 435678</label>
                                </span>}
                              secondary={merchantInfo.merchant_name}
                              classes= {{
                                primary : classes.listPrimary,
                                secondary: classes.listSecondary
                              }}
                            />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        )}
      </Container>
    </React.Fragment>
  );
}

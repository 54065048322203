import axios from "axios";
import * as Constants from "utils/constants";

const API_URL = Constants.API_URL+"/api/auth/";

const tlClient = axios.create({
  baseURL: "https://api.textlocal.in/",
});

const sendPartnerWelcomeMessage = (user) => {
    if (user && user.phone && user.name) {
      const params = new URLSearchParams();
      params.append("numbers", [parseInt("91" + user.phone)]);
      params.append(
        "message",
        `Hi ${user.name},
Welcome to iWheels, Download our app to get bookings from our customers with better pricing. 
https://iwheels.co`
      );
      tlClient.post("/send", params);
    }
  };

const sendVerificationMessage = (mobile) => {
  return axios
    .post(API_URL + "sendmessage", {
      mobile,
    })
    .then((response) => {
      if(response.data.otp) {
        const params = new URLSearchParams();
        //params.append("apiKey", "f/jsMLTeucM-tVj1lmNObecFeumggjSOgD9XoRUAJr");
        //params.append("Payment of Rs {#var#}/- on your tag ( {#var#} ) is successful, with reference number: {#var#}. TapX is powered by AtomX.")
        params.append("apiKey", "mmOwp7RHEEc-y2oiKybxEXB3xWzYJxaDXQzzRZ57al");
        params.append("sender", "ATMXPS");
        params.append("numbers", [parseInt("91" + mobile)]);
        params.append(
          "message",
          encodeURI(`${response.data.otp} is your OTP for the TapX app powered by AtomX`)
        );
        tlClient.post("/send", params);
      }
      return response.data;
    });
};

const validateOTP = (mobile, otp) => {
  return axios
    .post(API_URL + "otp", {
      mobile,
      otp,
    })
    .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        
        return response.data;
    });
};

export default {
    sendPartnerWelcomeMessage,
    sendVerificationMessage,
    validateOTP,
};
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';


import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';

import axios from "axios";
import * as Constants from "utils/constants";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    list: {
        backgroundColor : grey['A100']
    },
    updatemessage : {
        backgroundColor : grey['A100'],
        padding: "8px 0",
        textAlign: "center",
        color: "rgb(224, 68, 30)"
    }
  }));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Status() {
    let query = useQuery();
    const classes = useStyles();
    const [statusInfo, setStatusInfo] = React.useState(JSON.parse(query.get("response")));

    return (
        <React.Fragment>
            <List className={classes.list} >
                <ListItem role={undefined} dense button>
                    <ListItemText>
                        PAYMENT <br/>
                        {statusInfo.txStatus == 'SUCCESS' ? (
                            "SUCCESSFUL"
                        ) : (
                            "FAILED"
                        )}
                    </ListItemText>
                    <ListItemSecondaryAction>
                        {statusInfo.txStatus == 'SUCCESS' ? (
                            <CheckCircleOutlineIcon style={{ fontSize: 40, color: green[500] }}/>
                        ) : (
                            <CancelIcon style={{ fontSize: 40, color: red[500] }}/>
                        )}
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem role={undefined} dense button>
                    <ListItemText>
                        Amount<br/>
                        <b>{statusInfo.orderAmount}</b>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        Date-Time<br/>
                        <b>{moment(statusInfo.txTime).format("DD MMM YYYY, h:mm:ss A")}</b>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem role={undefined} dense button>
                    <ListItemText>
                        Tag No.<br/>
                        <b>{statusInfo.cardId}</b>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        Reference ID<br/>
                        <b>{statusInfo.referenceId}</b>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
            {statusInfo.txStatus == 'SUCCESS' ? (
            <Box className={classes.updatemessage}>
                <Typography component="p">
                    Update tag balance at any merchant
                </Typography>
            </Box>
            ) : "" }
        </React.Fragment>
    )
}

import React from "react";
import { useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import * as Constants from "utils/constants";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
// import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Contactless } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
	paymentTotal: {
		fontFamily: "gilroy-regular",
		color: "#ffffff",
	},
	payli: {
		display: "flex",
		height: "120px",
	},
	paymode: {
		left: "16px",
		"& legend": {
			color: "white !important",
		},
		"& .MuiRadio-colorPrimary.Mui-checked": {
			color: "red",
		},
		"& .MuiRadio-colorPrimary": {
			color: "white",
		},
		"& .MuiFormControlLabel-labelPlacementBottom": {
			margin: "0 10px 0 0",
		},
	},
	couponAlert: {
		"& .MuiAlert-icon": {
			fontSize: 45,
		},
		"& .MuiAlert-message": {
			padding: 0,
		},
	},
	couponMessage: {
		"& .MuiAlert-action": {
			display: "block",
		},
	},
	amlTable: {
		fontSize: "1.5rem",
	},
	returnMessage: {
		textAlign: "center !important",
		color: "black",
		backgroundColor: "white",
		borderRadius: "15px",
	},
	activationMessage: {
		textAlign: "center !important",
		color: "red",
		// backgroundColor: 'white',
		// borderRadius: '15px'
	},
}));

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function PaymentTotal(props) {
	const classes = useStyles();
	const [fessInfo, setfessInfo] = React.useState("");
	const [payMode, setPayMode] = React.useState("upi");
	const [openCoupon, setOpenCoupon] = React.useState(false);
	const [couponMessage, setCouponMessage] = React.useState("");

	const [eventId, setEventId] = React.useState("");

	const [activeStep, setActiveStep] = React.useState(0);

	const [isProcessing, setProcessing] = React.useState(false);
	const [openSncakbar, setOpenSncakbar] = React.useState(false);
	const [coupon, setCoupon] = React.useState("");
	const [couponData, setCouponData] = React.useState("");
	const [isInitialLoading, setInitialLoading] = React.useState(true);
	let query = useQuery();

	const numFrm = (x) => {
		if (!x) return "";
		try {
			let x_ = x.toString().split(".");
			x = parseInt(x_[0]);
			let isFloat = x_.length > 1;
			let isNegative = x < 0;
			if (isNegative) x = x * -1;
			x = x.toString();
			let lastThree = x.substring(x.length - 3);
			let otherNumbers = x.substring(0, x.length - 3);
			if (otherNumbers !== "") lastThree = "," + lastThree;
			let nm =
				(isNegative ? "-" : "") +
				otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
				lastThree +
				(isFloat ? "." + x_[1].substring(0, 2) : "");
			// console.log('nm', nm);
			return nm;
		} catch (e) {
			console.log("numerr", e);
			return "NA";
		}
	};

	const handlePaymentChange = (event) => {
		setPayMode(event.target.value);
		getFees(event.target.value);
	};

	React.useEffect(() => {
		if (query.get("eventId")) {
			setEventId(query.get("eventId"));
			// props.handler(true);
		} else {
			// if(parseInt(query.get("step")) != 3) {
			// 	document.getElementById("step"+(activeStep + 1)).scrollIntoView(true);
			// }
			// else {
			// 	document.getElementById("step3").scrollIntoView(true);
			// }
		}
	}, [activeStep]);

	const handleClose = (event, reason) => {
		setOpenSncakbar(false);
	};

	const applyCoupon = () => {
		setProcessing(true);
		axios
			.post(Constants.API_URL + "/api/topup/applyCoupon", {
				type: "topup",
				amount: query.get("amount"),
				user_id:
					props.currUser.id != undefined ? props.currUser.id : "",
				tagnum: query.get("tag"),
				coupon: coupon,
			})
			.then((response) => {
				if (response.data.dealData.success) {
					setOpenCoupon(false);
					setCouponData(response.data.dealData);
				} else {
					setOpenSncakbar(true);
					setCouponMessage(response.data.dealData.message);
					setOpenCoupon(true);
				}
				setProcessing(false);
				return;
			});
	};
	const getFees = (payModeSet) => {
		setProcessing(true);
		axios
			.post(Constants.API_URL + "/api/topup/viewReceipt", {
				type: "topup",
				mode: payModeSet,
				amount: query.get("amount"),
				user_id:
					props.currUser.id != undefined ? props.currUser.id : "",
				tagnum: query.get("tag"),
				code: query.get("eventId")
			})
			.then((response) => {
				setfessInfo(response.data.feesInfo);
				if (isInitialLoading) {
					// console.log("In View Receipt");
					// console.log(response.data.feesInfo.coupon);
					if (response.data.feesInfo.coupon.firstdeal) {
						setOpenCoupon(false);
						setCouponData(response.data.feesInfo.coupon);
					} else {
						setOpenCoupon(true);
					}
				}
				setProcessing(false);
				setInitialLoading(false);
				return;
			});
	};

	React.useEffect(() => {
		if (query.get("tag") && query.get("amount")) {
			getFees(payMode);
		}
	}, []);
	return (
		<React.Fragment>
			<List className={classes.paymentTotal}>
				{!isInitialLoading ? (
					<div>
						<Collapse in={!openCoupon}>
							<Alert
								className={classes.couponMessage}
								icon={false}
								action={
									<IconButton
										aria-label="close"
										color="inherit"
										size="small"
										style={{ display: "block" }}
									>
										<CloseIcon
											fontSize="inherit"
											onClick={() => {
												setOpenCoupon(true);
											}}
										/>
									</IconButton>
								}
							>
								{couponData.firstdeal ? (
									<AlertTitle>Welcome to TapX</AlertTitle>
								) : (
									""
								)}
								{couponData.success
									? couponData.deal.deal_name
									: ""}
							</Alert>
						</Collapse>
						{/*<Collapse in={openCoupon}>*/}
						{/*	<Alert  iconMapping={{ success: <Contactless fontSize="inherit" /> }} className={classes.couponAlert} action={<Button color="inherit" size="small" onClick={() => {applyCoupon();}}>APPLY</Button>}>*/}
						{/*		<TextField id="standard-basic" label="Coupon Code" style={{padding:0}} value={coupon} onChange={e => setCoupon(e.target.value)}/>*/}
						{/*	</Alert>*/}
						{/*</Collapse>*/}
						<Snackbar
							open={openSncakbar}
							anchorOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
							autoHideDuration={4000}
							onClose={handleClose}
						>
							<Alert severity="error" onClose={handleClose}>
								{couponMessage}
							</Alert>
						</Snackbar>
					</div>
				) : (
					""
				)}
				<Dialog
					open={isProcessing}
					PaperProps={{
						style: {
							backgroundColor: "transparent",
							boxShadow: "none",
							width: "44px",
						},
					}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<div
						style={{
							width: "46px",
							backgroundColor: "transparent",
						}}
					>
						<CircularProgress
							style={{
								backgroundColor: "transparent",
								color: "white",
							}}
						/>
					</div>
				</Dialog>

				<ListItem>
					<ListItemText className={classes.paymode}>
						<FormControl component="fieldset">
							<FormLabel component="legend">
								Select Payment Mode
							</FormLabel>
							<RadioGroup
								row
								aria-label="paymode"
								name="paymode"
								style={{ textAlign: "center" }}
								onChange={handlePaymentChange}
								value={payMode}
							>
								{eventId == 282 ? (
									""
								) : (
									<FormControlLabel
										value="upi"
										control={<Radio color="primary" />}
										label={<span>UPI</span>}
										labelPlacement="bottom"
									/>
								)}

								<FormControlLabel
									value="cc"
									control={<Radio color="primary" />}
									label={
										<span>
											Credit Card
											<br />
											{eventId == 282 ? "" : ""}
										</span>
									}
									labelPlacement="bottom"
								/>
								<FormControlLabel
									value="dc"
									control={<Radio color="primary" />}
									label={
										<span>
											Debit Card
											<br />
											{eventId == 282 ? "" : ""}
										</span>
									}
									labelPlacement="bottom"
								/>
							</RadioGroup>
						</FormControl>
					</ListItemText>
				</ListItem>
				<ListItem role={undefined} dense button>
					<ListItemText>Tag Number</ListItemText>
					<ListItemSecondaryAction className={classes.amlTable}>
						{query.get("tag")}
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem role={undefined} dense button>
					<ListItemText>Order Amount</ListItemText>
					<ListItemSecondaryAction className={classes.amlTable}>
						{numFrm(fessInfo.orderAmount)}
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem role={undefined} dense button>
					<ListItemText>Convenience Fee</ListItemText>
					<ListItemSecondaryAction className={classes.amlTable}>
						{numFrm(fessInfo.feeAmount)}
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem role={undefined} dense button>
					<ListItemText>
						{eventId == 282 ? "TAX" : "GST"}
					</ListItemText>
					<ListItemSecondaryAction className={classes.amlTable}>
						{numFrm(fessInfo.gstAmount)}
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem role={undefined} dense button>
					<ListItemText>Total Amount</ListItemText>
					<ListItemSecondaryAction className={classes.amlTable}>
						<b>{numFrm(fessInfo.finalAmount)}</b>
					</ListItemSecondaryAction>
				</ListItem>
				<Typography
					className={classes.returnMessage}
					variant="h6"
					component="h2"
				>
					Please return to this page once the payment is done.
				</Typography>
				<Typography
					className={classes.activationMessage}
					variant="h6"
					component="h2"
				>
					Activation Amount will be applicable on the 1st recharge
					ONLY.
				</Typography>
				<input
					type="hidden"
					name="isCoupon"
					id="isCoupon"
					value={!openCoupon}
				/>
				<input
					type="hidden"
					name="eventId"
					id="eventId"
					value={eventId}
				/>
				<input
					type="hidden"
					name="coupon"
					id="coupon"
					value={couponData}
				/>
				<input
					type="hidden"
					name="couponCode"
					id="couponCode"
					value={couponData.dealCode}
				/>
				<input
					type="hidden"
					name="dealId"
					id="dealId"
					value={couponData.deal ? couponData.deal.id : ""}
				/>
				<input
					type="hidden"
					name="deal_merchant_id"
					id="deal_merchant_id"
					value={couponData.deal ? couponData.deal.merchant_id : ""}
				/>
				<input
					type="hidden"
					name="paymentMode"
					id="paymentMode"
					value={payMode}
				/>
				<input
					type="hidden"
					name="orderAmount"
					id="orderAmount"
					value={fessInfo.orderAmount}
				/>
				<input
					type="hidden"
					name="feeAmount"
					id="feeAmount"
					value={fessInfo.feeAmount}
				/>
				<input
					type="hidden"
					name="gstAmount"
					id="gstAmount"
					value={fessInfo.gstAmount}
				/>
				<input
					type="hidden"
					name="finalAmount"
					id="finalAmount"
					value={fessInfo.finalAmount}
				/>
			</List>
		</React.Fragment>
	);
}

import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Container} from "@material-ui/core";  
import AuthService from "../../../../services/auth.service"; 

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Authenticate(props)  {

  let query = useQuery(),
  history = useHistory();

    if(query.get("access_token")) {
      AuthService.loginTP(query.get("access_token")).then(
        () => {
          console.log(props);
          props.onChange();
          window.location.replace("/");
        },
        (error) => {
          const resError =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }

  return (
    <React.Fragment>
      <Container>

      </Container>
    </React.Fragment>
  );
}

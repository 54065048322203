import React from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from "@material-ui/core/styles";
import {Box, Paper, Grid, Typography, Button} from '@material-ui/core/';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import LinkMat from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import {Link} from "react-router-dom";
import QrReader from 'react-qr-reader';
import {Controller, useForm} from 'react-hook-form';
import cardsService from "../../../../services/cards.service";
import axios from "axios";
import * as Constants from "utils/constants";

function scanQuery(url) {
  return new URLSearchParams(url);
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flex: '1',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  appBar: {
    position: 'relative',
    background: 'rgb(224, 68, 30)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  DialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems : 'center',
    justifyContent : 'center',
    width : '100vw',
  },
  deals: {
    // background: 'linear-gradient(106deg, #fc4a1a 63%, #f7b733 60%) fixed'
  },
  headline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.5rem'
  },
  shortdesc: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    marginTop: '10px',
    fontSize: '0.75rem'
  },
  tagline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.25rem',
    marginTop: '30px',
    "& p": {
      transform: 'rotate(90deg)',
      width: '0',
      margin: '0 0 0 10px'
    }
  },
  paperContainer:{
    display:'flex',
    flexWrap: 'wrap',
    fontFamily: 'gilroy-bold'
  },
  paper: {
    width: '100vw',
    margin: '15px 0 0 0',
    padding: '0 0 15px 0',
    display: 'flex',
    justifyContent: 'space-between',
    background: 'transparent',
    borderBottom: '1px solid rgba(255,255,255,0.5)'
  },
  paperHeadline: {
    fontFamily: 'gilroy-bold',
    letterSpacing: '0.1em',
    width: '90vw'
  },
  others: {
    maxWidth: '100%',
  },
  inactive : {
    maxWidth: '100%',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    margin: '10px -2px !important'
  },
  gridListTileDefault: {
    width: '90vw !important',
    height: '60vw !important',
    borderRadius: '4vw',
    marginRight: theme.spacing(2),
    padding: '2vw !important',
    backgroundSize: 'cover !important',
    fontFamily: 'gilroy-bold',
    background: 'rgba(0,0,0,0.5)',
  },
  gridListTile: {
    borderRadius: '4vw',
    marginRight: theme.spacing(2),
    padding: '2vw !important',
    backgroundSize: 'cover !important',
    width: '37vw !important',
    height: '37vw !important',
    fontFamily: 'gilroy-bold',
    background: 'rgba(0,0,0,0.5)'
  },
  gridListTileInactive: {
    opacity : '0.5'
  },
  tagListId : {
    fontSize: '0.5rem',
    letterSpacing: '0.1rem',
    marginTop: '8vw',
  },
  tagListTitle : {
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tagListBottom : {
    display: 'flex',
    position: 'absolute',
    bottom: '5px',
    width: '100%'
  },
  tagListBalance : {
    fontSize: '0.5rem',
    "& span" : {
      fontSize: '0.6rem',
    },
    "& label" : {
      fontSize: '0.8rem',
      fontFamily: 'gilroy-regular',
    }
  },
  tagListActions : {
    fontSize:'1em',
    position: 'absolute',
    right: '0',
    bottom: '0',
    "& a": {
      color: 'white'
    },
    "& span" : {
      padding: '0 2px'
    }
  },
  addNewTag : {
    border: '1px solid #CFCFCD',
    borderRadius: '4vw',
    width: '37vw',
    height: '37vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    "& label" : {
      fontFamily: 'gilroy-regular',
      fontSize:'0.6rem',
      letterSpacing: '0.1em',
      margin: '10px 0 0 0'
    }
  },
  formControl: {
    width: '80vw',
  },
  TagDialog: {
    minWidth: '90vw'
  }
}));


function DialogTransition (props) {
  return <Slide direction='up' {...props} />;
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UserTags(props) {
  const classes = useStyles();
  const {register, errors, control, handleSubmit} = useForm({ mode: 'onChange'});
  const [loading, setLoading] = React.useState(true);
  const [isHaveActiveTag, setActiveTag] = React.useState(false);
  const [isHaveInActiveTag, setInActiveTag] = React.useState(false);
  const [tagsList, setTagList] = React.useState([]);
  const [tag_type, setTagType] = React.useState('');
  const [tagNum, setTagNum] = React.useState('');
  const [tagTypeList, setTagTypeList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState('error');
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [resMessage, setResponseMessage] = React.useState();
  const [hasError, setHasError] = React.useState(false);
  const [result, setResult] = React.useState("No result");
  let queryParam = useQuery();

  React.useEffect(() => {
    if(props.currUser.default_tag != null) {
      axios.get(Constants.API_URL+"/api/cards/info", {
        params : {
            user_id : props.currUser.id
        }
      }).then((response) => {

        console.log(response.data.TagData);
        (response.data.TagData).forEach(function(tag) {
          if(tag.is_blocked)
          {
            setInActiveTag(true);
          }else{
            setActiveTag(true);
          }
        });
        setTagList(response.data.TagData);
        setLoading(false);
      });

      axios.get(Constants.API_URL+"/api/tags/categories").then((response) => {
        setTagTypeList(response.data.shopCategory);
      });
    }else {

      axios.get(Constants.API_URL+"/api/tags/categories").then((response) => {
        setTagTypeList(response.data.shopCategory);
      });

      if(queryParam.get("open")) setOpen(true);
      setLoading(false);
    }

  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setHasError(false);
  };
  const handleScan = (data) => {
    if (data) {
      const query = scanQuery(data);
      const tag_type = query.get('f'),
            product = query.get('p'),
            tag_number = query.get('tag');
      if(product == "2") {
        addCard(tag_type,tag_number)
      }
      setOpen(false);
    }
  }
  const handleError = (err) => {
    console.error(err)
  }

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const addCard = (tagtype,tagnumber) => {
    setLoading(true);
    cardsService.addCard(
      props.currUser.id ,
      tagnumber,
      tagtype,
      "0",
      0).then((response) => {
        console.log(response);
        setSeverity(response.alert);
        setOpenSnackBar(true);
        setResponseMessage(response.message);
        if(props.currUser.default_tag == null && response.alert == "success") {
          let user = JSON.parse(localStorage.user);
          user.default_tag = tagnumber;
          localStorage.setItem("user", JSON.stringify(user));
          window.location.reload();
        }
        else {
          if(response.alert == "success") {
            window.location.reload();
          }
        }
      },
      (error) => {
        const resError =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          setSeverity('error');
          setOpenSnackBar(true);
          setResponseMessage(resError);
        //console.log(resError);
      });
  }

  const handleCards = () => {
    const tagTypeVal = document.getElementsByName("tag_type")[0].value.toString();
    const tagNumberVal = document.getElementsByName("tag_number")[0].value.toString();
    if(tagTypeVal == "") {
      return setHasError(true);
    }
    else {
      addCard(tagTypeVal,tagNumberVal);
      setOpen(false);
      setHasError(false);
    }
  };

  const handleChange = (event) => {
    setTagType(event.target.value);
  };

  //Card Block
  const handleClickAlert = (tagNum) => {
    setOpenAlert(true);
    setTagNum(tagNum);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const handleTagBlock = (tag_number) => {
    setLoading(true);
    //Tag Block API
    axios.post(Constants.API_URL+"/api/tag/block", {
      userid : props.currUser.id,
      tagnumber : tag_number,
      default_tag : props.currUser.default_tag,
      accessToken : props.currUser.accessToken
    }).then((response) => {
      setTagNum('');
      setOpenAlert(false);
      if(response.data.is_default_blocked) {
        let user = JSON.parse(localStorage.user);
        user.default_tag = response.data.new_default_tag;
        localStorage.setItem("user", JSON.stringify(user));
        window.location.reload();
      }
      window.location.reload();
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container spacing={5} className={classes.deals} maxWidth="md" component="main">
        {(props.currUser.length != 0) ? (
          loading ? (
            <Box class="loader loader-quart"></Box>
          ) : (
          <Box>
            <Box pt={4}>
              <Typography className={classes.headline} variant="h4" component="h2" >
                Tags
              </Typography>
              <Typography className={classes.shortdesc} component="p" >
                {tagsList.length} Form Factors
              </Typography>
            </Box>
            <Box className={classes.paperContainer} mt={2}>
              {isHaveActiveTag ? (
              <Typography component="p" className={classes.paperHeadline}>
                ACTIVE TAGS
              </Typography>
              ) : ""}
              {tagsList.map((tags, index) => (
                  (tags.tag_number==props.currUser.default_tag && !tags.is_blocked) ?
                  (
                    <Grid item className={classes.default}>
                      <GridList className={classes.gridList} cols={2.5}>
                        <GridListTile
                        className={`${classes.gridListTileDefault}`}
                        style={{background:"url("+tags.tag_img+") center center no-repeat"}}>
                          <Box className={classes.tagListId}>
                            TAG ID {tags.tag_number} (DEFAULT TAG)
                          </Box>
                          <Box className={classes.tagListTitle}>
                            {tags.tag_name}
                          </Box>
                          <Box className={classes.tagListBottom}>
                            <Typography component="div" className={classes.tagListBalance}>
                                Balance<br />
                                <span class="icon-Icons_Rupee"><label>{tags.tag_balance}</label></span>
                            </Typography>
                            <Typography component="div" className={classes.tagListActions}>
                              <span onClick={() => handleClickAlert(tags.tag_number)} class="icon-Icons_Block"></span>
                              <Link to={"/topup?tag="+tags.tag_number}><span class="icon-Icons_Top-up"></span></Link>
                              <Link to={"/transactions"}><span class="icon-Icons_Transactions"></span></Link>
                            </Typography>
                          </Box>
                        </GridListTile>
                      </GridList>
                    </Grid>
                  ) : "" ))}

              <Grid item className={classes.others}>
                <GridList className={classes.gridList} cols={2.5}>
                  {tagsList.map((tags, index) => (
                      (tags.tag_number!=props.currUser.default_tag && !tags.is_blocked) ?
                      (
                        <GridListTile key={tags.id}
                        className={classes.gridListTile}
                        style={{background:"linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("+tags.tag_img+") center center no-repeat"}}>
                          <Box className={classes.tagListId}>
                            TAG ID {tags.tag_number}
                          </Box>
                          <Box className={classes.tagListTitle}>
                            {tags.tag_name}
                          </Box>
                          <Box className={classes.tagListBottom}>
                            <Typography component="div" className={classes.tagListBalance}>
                                Balance<br />
                                <span class="icon-Icons_Rupee"><label>{tags.tag_balance}</label></span>
                            </Typography>
                            <Typography component="div" className={classes.tagListActions}>
                              <span onClick={() => handleClickAlert(tags.tag_number)} class="icon-Icons_Block"></span>
                              <Link to={"/topup?tag="+tags.tag_number}><span class="icon-Icons_Top-up"></span></Link>
                              <Link to={"/transactions"}><span class="icon-Icons_Transactions"></span></Link>
                            </Typography>
                          </Box>
                        </GridListTile>
                      ) : ""
                  ))}
                </GridList>
              </Grid>

            </Box>
            <Box className={classes.paperContainer} mt={2}>
              <Grid item className={classes.inactive}>
                <Typography component="div" onClick={handleClickOpen} className={classes.addNewTag}>
                  <span class="icon-Icons_Add"></span>
                  <label>ADD NEW TAG</label>
                </Typography>
              </Grid>

              <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={severity}>
                  {resMessage}
                </Alert>
              </Snackbar>
            </Box>
            <Box className={classes.paperContainer} mt={2}>
              {isHaveInActiveTag ? (
              <Typography component="p" className={classes.paperHeadline}>
                INACTIVE TAGS
              </Typography>
              ) : ""}
              <Grid item className={classes.inactive}>
                <GridList className={classes.gridList} cols={2.5}>
                  {tagsList.map((tags, index) => (
                    (tags.is_blocked) ?
                    (
                      <GridListTile key={tags.id}
                      className={`${classes.gridListTile} ${classes.gridListTileInactive}`}
                      style={{background:"linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/tag1.jpg') center center no-repeat"}}>
                        <Box className={classes.tagListId}>
                          TAG ID {tags.tag_number}
                        </Box>
                        <Box className={classes.tagListTitle}>
                          {tags.tag_name}
                        </Box>
                        <Box className={classes.tagListBottom}>
                          <Typography component="div" className={classes.tagListBalance}>
                              Balance<br />
                              <span class="icon-Icons_Rupee"><label>{tags.tag_balance}</label></span>
                          </Typography>
                          <Typography component="div" className={classes.tagListActions}>
                              <Link to={"/transactions"}><span class="icon-Icons_Transactions"></span></Link>
                          </Typography>
                        </Box>
                      </GridListTile>
                    ): ""
                  ))}
                </GridList>
              </Grid>
            </Box>

            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
              classes={{
                paper: classes.TagDialog
              }}>
              <form onSubmit={handleSubmit(handleCards)} noValidate>
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                      <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                      SCAN AND ADD TAG
                    </Typography>
                    <Button type="submit" autoFocus color="inherit">
                      ADD
                    </Button>
                  </Toolbar>
                </AppBar>

                <Box className={classes.DialogContent}>
                  {/* <p>{result}</p> */}
                  <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '100%' }}
                  />
                  <br/>
                  <center>OR</center>
                  <br/>
                  <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="card-type-label">Tag Type</InputLabel>
                        <Select
                          labelId="card-type-label"
                          id="tag_type"
                          name="tag_type"
                          value={tag_type}
                          onChange={handleChange}
                        >
                          {tagTypeList.map((item) => (
                            <MenuItem value={item.id}>{item.tag_name}</MenuItem>
                          ))}
                        </Select>
                        {hasError && <FormHelperText style={{color: "red"}}>Select a Tag Type!</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          margin="dense"
                          id="tag_number"
                          name="tag_number"
                          label="Tag Number"
                          type="number"
                          inputMode="numeric"
                          fullWidth
                          error={!!errors.tag_number}
                          inputRef={register({
                              required: "Tag Number is required.",
                              pattern: {
                                  value: /^[0-9\b]+$/,
                                  message: "Please enter numbers only."
                              },
                              maxLength: {
                                  value: 10,
                                  message: "Tag Number should be 10 Digits"
                              }
                          })}
                          inputProps={{
                              maxLength: 10,
                          }}
                          helperText={errors.tag_number && errors.tag_number.message}
                        />
                        </FormControl>
                    </Grid>
                </Box>
                </form>
              </Dialog>

            <Dialog
                open={openAlert}
                onClose={handleCloseAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Do you really want to block this tag?"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This tag allows you transact at different merchants of your choice. Blocking the card will
                    disable to work anywhere on TapX merchants. You can unblock the tag when you want to reuse.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseAlert} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => handleTagBlock(tagNum)} color="primary" autoFocus>
                    Block
                </Button>
                </DialogActions>
            </Dialog>

          </Box>
          )
        ) : (
          <Box className="login_message">
              You need to <Link to="/signin">LOGIN</Link> to Add/View Tags
          </Box>
        )}
      </Container>
    </React.Fragment>
  );
}

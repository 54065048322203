import React from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Button, Paper, Typography, Container, CssBaseline } from '@material-ui/core';
import { Link } from 'react-router-dom';
import axios from "axios";
import * as Constants from "utils/constants";
import moment from 'moment';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { red, blue, green, yellow, purple, lime, deepPurple, amber } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    card: {
      margin: '60px 0',
      color:'white',
      background: 'transparent',
      position: 'relative',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    cancelIcon: {
        color: red[400],
        fontSize: 50,
    },
    ticketInfoRow: {
        padding: "10px 0",
        borderBottom: "1px solid #666666"
    },
    ticketInfoRow2: {
        padding: "10px 0",
    }
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function ShopResponse(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [orderInfo, setOrderInfo] = React.useState({});

  let query = useQuery();

  React.useEffect(() => {
      axios.get(Constants.API_URL+'/api/shop/tag/response/'+query.get('order')).then((response) => {
          console.log("response.data.shop_data");
          console.log(response.data.shop_data);
          setOrderInfo(response.data.shop_data);
          setLoading(false);
      })
  }, []);

  return (
    <React.Fragment>
        <CssBaseline />
        <Container spacing={5} maxWidth="md" component="main">
        {props.currUser != "" ? (
            loading ? (
                <Box class="loader loader-quart"></Box>
            ) : (
            <Box mt={2}>
                { (Object.keys(orderInfo.itemInfo).length === 0 && orderInfo.itemInfo.constructor === Object) ? (
                <React.Fragment>
                </React.Fragment>
                ) : (
                <React.Fragment>
                    <Card className={classes.card}>
                        {orderInfo.status == 'FAILED' ? (
                            <Box>
                                <Box display="flex" justifyContent="center">
                                    <CancelIcon className={classes.cancelIcon} />
                                </Box>
                                <Box display="flex" justifyContent="center">
                                    <Typography component="h3" variant="h5">
                                        PAYMENT FAILED
                                    </Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Box>
                                <Box display="flex" justifyContent="center">
                                    <CheckCircleIcon style={{ fontSize: 60, color: green[600]}} />
                                </Box>
                                <Box display="flex" justifyContent="center">
                                    <Typography component="h3" variant="h5">
                                        PAYMENT SUCCESSFUL
                                    </Typography>
                                </Box>
                            </Box>
                        )}

                        <Box mt={1} display="flex" justifyContent="center">
                            <Typography component="p">
                                {/*TXN Date: <b>{moment(tickets.event_start_date).utc().format("MMMM DD YYYY, h:mm:ss A")} </b>*/}
                            </Typography>
                        </Box>
                        <Box mt={1} display="flex" justifyContent="center">
                            <Typography component="p">
                                Order No: <b>{query.get('order')}</b>
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Typography component="p">
                                Receipt No: <b>{orderInfo.receipt}</b>
                            </Typography>
                        </Box>

                        <Box mt={2} mb={2} display="flex" justifyContent="center">
                            <Typography component="h3" variant="h3">
                                <b>{orderInfo.name}</b>
                            </Typography>
                        </Box>

                        <CardContent>
                            {orderInfo.amount != '' ? (
                                <Box display="flex" className={classes.ticketInfoRow}>
                                    <Box flexGrow={1} >
                                        Order Amount
                                    </Box>
                                    <Box >
                                        {orderInfo.amount}
                                    </Box>
                                </Box>
                            ) : ""}

                            {orderInfo.fee != '' ? (
                                <Box display="flex" className={classes.ticketInfoRow}>
                                    <Box flexGrow={1} >
                                        TapX Fee
                                    </Box>
                                    <Box >
                                        {orderInfo.fee}
                                    </Box>
                                </Box>
                            ) : ""}

                            {orderInfo.gst != '' ? (
                                <Box display="flex" className={classes.ticketInfoRow}>
                                    <Box flexGrow={1} >
                                        GST
                                    </Box>
                                    <Box >
                                        {orderInfo.gst}
                                    </Box>
                                </Box>
                            ) : ""}


                            {orderInfo.totalAmount != '' ? (
                                <Box display="flex" className={classes.ticketInfoRow}>
                                    <Box flexGrow={1} >
                                        <b>Total Amount</b>
                                    </Box>
                                    <Box >
                                        <b>{orderInfo.totalAmount}</b>
                                    </Box>
                                </Box>
                            ) : ""}

                            {orderInfo.payMode != '' ? (
                                <Box mt={2} display="flex" className={classes.ticketInfoRow2}>
                                    <Box flexGrow={1} >
                                        <b>Payment Mode</b>
                                    </Box>
                                    <Box >
                                        <b>{
                                        orderInfo.payMode == "cc" ? "Credit Card" :
                                        orderInfo.payMode == "dc" ? "Debit Card" :
                                        orderInfo.payMode == "upi" ? "UPI" : "Net Banking"
                                        }</b>
                                    </Box>
                                </Box>
                            ) : ""}

                            {orderInfo.status == 'FAILED' ? (
                                <Box>
                                    <Box mt={2} display="flex" justifyContent="center">
                                        <Typography component="p">
                                            <i><b>Note:</b> Any amount debited from your bank account will be refunded back in 4-7 days.</i>
                                        </Typography>
                                    </Box>

                                    <Box mt={2} display="flex" justifyContent="center">
                                        <Link to={"/shop/category/all"}>
                                        <Button variant="contained" color="secondary">
                                            <b>Buy More Tag</b>
                                        </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            ) : (
                                <Box mt={2} display="flex" justifyContent="center">
                                    <Link to={"/"+props.currUser.id+"/orders"}>
                                    <Button variant="contained" color="secondary">
                                        <b>View Order Details</b>
                                    </Button>
                                    </Link>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </React.Fragment>
                )}

            </Box>
            )
        ) : (
            <Box className="login_message">
                You need to <Link to="/signin">LOGIN</Link> to view Buy Tickets
            </Box>
        )}
      </Container>
    </React.Fragment>
  );
}

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import LinkMat from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AuthService from "../../services/auth.service";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import logo from "../../assets/images/tapx-logo-white.png";
import iconUser from "../../assets/images/icons/PNG/Icons_Login-Profile.png";
import iconShop from "../../assets/images/icons/PNG/Icons_Shop.png";
import iconMenu from "../../assets/images/icons/PNG/Icons_Menu.png";


import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import { fontFamily } from '@material-ui/system';


import axios from "axios";
import * as Constants from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '50px'
  },
  main: {
    boxShadow: 'none',
    color: 'white',
    backgroundColor: 'rgba(0,0,0,1)',
    paddingTop: '8px',
  },
  title: {
    flexGrow: 1,
  },
  link: {
    padding: theme.spacing(2),
    fontSize: '2em',
    color: 'white',
    textDecoration: 'none'
  },
  signinbutton: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 40,
    padding: '0 10px',
    textDecoration: 'none'
  },
  list: {
    color: 'white',
    "& a": {
      padding: theme.spacing(2)
    }
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    backgroundColor: 'black',
    minWidth: '240px',
    width: '70vw',
    borderRight: '1px solid #323031',
    "& i": {
      fontSize: '1.25rem'
    }
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    marginLeft: theme.spacing(9)
  },
  drawerFooter: {
    color: '#ffffff',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    fontSize: '0.9rem'
  },
  linkFooter: {
    color: '#ffffff',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  balance: {
    "& p":{
      color: '#e04420'
    }
  },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '15px',
    zIndex:'9',
  }
}));

function ListItemLink(props) {
  return <ListItem button component={Link} {...props} />;
}

export default function Header(props) {

  const classes = useStyles();

  const [state, setState] = React.useState({
    left: false,
  });
  const [tagBalance, setTagBalance] = React.useState("0");

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const logOut = () => {
    AuthService.logout();
  };

  if(props.currUser != undefined) {
    axios.get(Constants.API_URL+"/api/tag/balance/"+ props.currUser.default_tag).then((response) => {
      if(response.data.tag_balance) {
        setTagBalance(parseInt(response.data.tag_balance));
      }
    });
  }

  return (
    <header className={classes.root}>
    <Box component="span" m={0}>
      <AppBar className={classes.main} position="fixed" color="transparent" >
          <Toolbar>
            <Grid container alignItems="center" justify="space-between" direction="row">
              { props.currUser ?(
                  <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer("left", true)}
              >
                <span class="icon-Icons_Menu"></span>
              </IconButton>
              ):''}
            </Grid>

            <Grid container alignItems="center" justify="center" direction="row">
              <Link to="/" style={{padding:'0px'}} className={classes.link}>
                <img src={logo} width="80" />
              </Link>
            </Grid>
             <Grid container alignItems="center" justify="flex-end" direction="row">
               { props.currUser ? (
                   <div>
                    <Link to="/shop"  style={{padding:'0 0 0 16px'}} className={classes.link}>
                    <span class="icon-Icons_Shop"></span>
                    </Link>
                    {props.currUser ? (
                    <Link to={"/profile/" + props.currUser.id} style={{padding:'0 0 0 16px'}} className={classes.link}>
                        <span class="icon-Icons_Login-Profile"></span>
                      </Link>
                    ):(
                      <Link to="/signin" style={{padding:'0 0 0 16px'}} className={classes.link}>
                        <span class="icon-Icons_Login-Profile"></span>
                      </Link>
                    )}
              </div>
               ): ('')}
            </Grid>


          </Toolbar>
        </AppBar>

        <Drawer classes={{ paper: classes.paper }}
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer("left", false)}>

          <IconButton className={classes.closeBtn} onClick={toggleDrawer("left", false)}>
            <span class="icon-Icons_Close"></span>
          </IconButton>
          <List className={classes.list}>
              {props.currUser ? (
                <React.Fragment>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <ImageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText className={classes.balance} primary={props.currUser.name} secondary={
                      <React.Fragment>
                        (Balance: <span class='icon-Icons_Rupee'></span> {tagBalance})
                      </React.Fragment>
                    } />
                  </ListItem>
                  <ListItemLink to={"/tags/"+props.currUser.id} onClick={toggleDrawer("left", false)}>
                    <ListItemAvatar>
                      <i class="icon-Icons_Tags-Form-Factors"></i>
                    </ListItemAvatar>
                    <ListItemText primary="TAGS" />
                  </ListItemLink>
                  <Divider className={classes.divider} />
                </React.Fragment>
              ):(
              <ListItemLink to="/signin" onClick={toggleDrawer("left", false)}>
                <ListItemAvatar>
                  <i class="icon-Icons_Login-Profile"></i>
                </ListItemAvatar>
                <ListItemText primary={
                  <b>
                    LOGIN / REGISTER
                  </b>
                } />
              </ListItemLink>
              )}

            <ListItemLink to="/topup" onClick={toggleDrawer("left", false)}>
              <ListItemAvatar>
                <i class="icon-Icons_Top-up"></i>
              </ListItemAvatar>
              <ListItemText primary="TOP-UP" />
            </ListItemLink>
            <Divider className={classes.divider} />
            <ListItemLink to="/transactions" onClick={toggleDrawer("left", false)}>
              <ListItemAvatar>
                <i class="icon-Icons_Transactions"></i>
              </ListItemAvatar>
              <ListItemText primary="TRANSACTIONS" />
            </ListItemLink>
            <Divider className={classes.divider} />
            <ListItemLink to="/merchants" onClick={toggleDrawer("left", false)}>
              <ListItemAvatar>
                <i class="icon-Icons_Merchants"></i>
              </ListItemAvatar>
              <ListItemText primary="MERCHANTS" />
            </ListItemLink>
            <Divider className={classes.divider} />
            <ListItemLink to="/deals" onClick={toggleDrawer("left", false)}>
              <ListItemAvatar>
                <i class="icon-Icons_Deals"></i>
              </ListItemAvatar>
              <ListItemText primary="DEALS" />
            </ListItemLink>
            <Divider className={classes.divider} />
            <ListItemLink to="/about" onClick={toggleDrawer("left", false)}>
              <ListItemAvatar>
                <i class="icon-Icons_About"></i>
              </ListItemAvatar>
              <ListItemText primary="ABOUT US" />
            </ListItemLink>
            <Divider className={classes.divider} />
            <ListItemLink to="/faq" onClick={toggleDrawer("left", false)}>
              <ListItemAvatar>
                <i class="icon-Icons_Help"></i>
              </ListItemAvatar>
              <ListItemText primary="FAQ" />
            </ListItemLink>
            <Divider className={classes.divider} />
            <ListItemLink to="/contact" onClick={toggleDrawer("left", false)}>
              <ListItemAvatar>
                <i class="icon-Icons_Contact"></i>
              </ListItemAvatar>
              <ListItemText primary="CONTACT US" />
            </ListItemLink>
            <Divider className={classes.divider} />
          </List>
          {props.currUser ? (
            <LinkMat href="/" onClick={logOut} className={classes.link}>
              <Button color="secondary" variant="contained">Logout</Button>
            </LinkMat>
          ):""}

          <Typography className={classes.drawerFooter} component="p">
            <Link className={classes.linkFooter} onClick={toggleDrawer("left", false)} to="/TermsAndConditions">Terms and Conditions</Link>
            <br></br><Link className={classes.linkFooter} onClick={toggleDrawer("left", false)} to="/PrivacyPolicy">Privacy Policy</Link>
            <br></br><Link className={classes.linkFooter} onClick={toggleDrawer("left", false)} to="/RefundsCancellationsPolicy">Refunds/Cancellations Policy</Link>
            <br></br>&copy; 2022 Copyright TapX. All rights reserved.{"\n"}

          </Typography>
        </Drawer>



    </Box>
    </header>
  );
}

import axios from "axios";
import * as Constants from "utils/constants";

const API_URL = Constants.API_URL+"/api/cards/";

const addCard = (
    user_id,
    tag_number,
    tag_id,
    tag_balance,
    is_blocked
    ) => {
    return axios
    .post(API_URL + "add", {
        user_id,
        tag_number,
        tag_id,
        tag_balance,
        is_blocked
    })
    .then((response) => {
        return response.data;
    });
};

const cardsInfo = (userid) => {
  return axios
    .get(API_URL + "info", {
      params : {
          user_id : userid
      }
    })
    .then((response) => {
        return response.data;
    });
};

export default {
    addCard,
    cardsInfo,
};
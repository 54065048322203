import axios from "axios";
import * as Constants from "utils/constants";

const API_URL = Constants.API_URL+"/api/auth/";

const register = (
  firstname, 
  lastname, 
  email, 
  mobile, 
  password,
  user_id,
  gender,
  dob,
  city,
  state,
  country,
  verified,
  verifyOTP,
  inviteCode,
  last_login) => {

  return axios.post(API_URL + "signup", {
    user_id,
    firstname,
    lastname,
    email,
    mobile,
    password,
    gender,
    dob,
    city,
    state,
    country,
    verified,
    verifyOTP,
    inviteCode,
    last_login
  }).then((response) => {
    if (response.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
  });
};

const login = (phone, password) => {
  return axios
    .post(API_URL + "signin", {
      phone,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const loginTP = (accessToken) => {
  return axios
    .post(API_URL + "authenticate", {
      accessToken
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("tapx_cart");
};

const getCurrentUser = () => {
  //console.log(JSON.parse(localStorage.getItem("user")));
  return JSON.parse(localStorage.getItem("user"));
};


export default {
  register,
  login,
  loginTP,
  logout,
  getCurrentUser,
};
import React from "react";
import {
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  faq: {
    margin: '10px auto',
    width: '90vw',
    textAlign: "center"
  },
  title: {
    padding: '16px 0'
  },
  addressText: {
    marginTop: '30px'
},
}));

export default function Contact()  {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <React.Fragment>
      <Box className={classes.faq}>
        <Typography  className={classes.title} variant="h3" component="h4">
          Contact Us
        </Typography>
        <Typography className={classes.addressText}>
          +91 96657 16791

        </Typography>
        <Typography >
          Austin Francis Lima<br/>
          248/A, Fernando Vaddo,<br/>
          Siolim Bardez, Goa, 403517
        </Typography>
      </Box>
    </React.Fragment>
  );
}

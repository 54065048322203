import React from "react";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Box, Avatar, Paper, Grid, Typography, Button, Popover} from '@material-ui/core/';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { red, blue, green, yellow, purple, lime, deepPurple, amber } from "@material-ui/core/colors";
import {Link} from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import axios from "axios";
import * as Constants from "utils/constants";
import moment from 'moment';
import QRCode from 'qrcode.react';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
  stepper: {
      backgroundColor: 'transparent',
      justifyContent: 'center',
      "& .MuiMobileStepper-dot" : {
          border: '1px solid #ffffff',
          background: 'transparent'
      },
      "& .MuiMobileStepper-dotActive" : {
          background: '#ffffff'
      }
  },
  ticketList: {
    width:'90%', 
    position: 'relative',
    backgroundColor: '#724457',
    background: 'radial-gradient(circle 10vw, #000 calc(100% - 6px), #000 calc(100% - 5px), #000 99%,transparent 100%) -45vw 20vw, radial-gradient(circle 10vw, #000 calc(100% - 6px), #000 calc(100% - 5px), #000 99%,transparent 100%) 45vw 20vw',
    backgroundRepeat: 'no-repeat'
  },
  ticketDate: {
    width: '80px',
    height: '80px',
    background: 'crimson',
    fontSize: '1.5rem',
    position: 'absolute',
    left: '50%',
    padding: '15px',
    marginLeft: '-40px',
    borderRadius: '50%',
    top: '-40px',
    lineHeight: '1',
  },
  cardTicket: {
    display:'flex',
    width:'100%'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    backgroundPosition: 'center',
    minWidth: '50%',
    minHeight: '40vw'
  }
}));

export default function UserTickets(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [ticketList, setTicketList] = React.useState([]);
  const [result, setResult] = React.useState("No result");


  React.useEffect(() => {
    if(props.currUser != "") {
      axios.post(Constants.API_URL+"/api/user/tickets/",{userid : props.currUser.id }).then((response) => {
        console.log(response.data.userTickets);
        setTicketList(response.data.userTickets);
        setLoading(false);
      });
    }
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container spacing={5} maxWidth="md" component="main">
        {(props.currUser.length != 0) ? (
          loading ? (
            <Box class="loader loader-quart"></Box>
          ) : (
          <Box>
            <Box mt={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                  <ConfirmationNumberIcon style={{ fontSize: 60, color: amber[600]}} />
                  <Typography component="h3" variant="h5">
                    &nbsp;&nbsp;YOUR TICKETS
                  </Typography>
              </Box>
            </Box>
            {ticketList.length > 0 ? (
              <Box mt={1} display="flex" alignItems="center" flexDirection="column">
                {ticketList.map((ticket, index) => (
                  <Box mt={7} className={classes.ticketList}>
                      {/* Ticket Date */}
                      <Box 
                      display="flex" 
                      textAlign="center" 
                      alignItems="center" 
                      flexDirection="column" 
                      className={classes.ticketDate}>
                        {moment(ticket.event_start_date).utc().format("DD MMM")}
                      </Box>
                      {/* Ticket QTY and Ticket Amount Paid */}
                      <Box display="flex">
                        <Box p={1} display="flex" alignItems="center" flexWrap="wrap" flexGrow={1}>
                          <Avatar variant="square" style={{backgroundColor:'black'}}>{ticket.qty}</Avatar>&nbsp;Tickets
                        </Box>
                        <Box p={1}>
                          <Tooltip
                          enterTouchDelay={0}
                          leaveTouchDelay={5000}
                          placement="top-end"
                          TransitionComponent={Zoom}
                          title={
                            <Typography style={{padding:'2px'}}>
                              <Box display="flex">
                                <Box p={1} flexGrow={1}>
                                  TKT Amt
                                </Box>
                                <Box p={1}>
                                  {ticket.price}
                                </Box>
                              </Box>
                              <Box display="flex">
                                <Box p={1} flexGrow={1}>
                                  Order Amt
                                </Box>
                                <Box p={1}>
                                  {ticket.amount}
                                </Box>
                              </Box>
                              <Box display="flex">
                                <Box p={1} flexGrow={1}>
                                  Fee
                                </Box>
                                <Box p={1}>
                                  {ticket.tapx_fee}
                                </Box>
                              </Box>
                              <Box display="flex">
                                <Box p={1} flexGrow={1}>
                                  GST
                                </Box>
                                <Box p={1}>
                                  {ticket.tapx_gst}
                                </Box>
                              </Box>
                            </Typography>
                          }>
                            <Button variant="contained" color="primary">
                              {ticket.amountPaid}
                            </Button>
                          </Tooltip>
                        </Box>
                      </Box>
                      {/* Ticket Name, Image */}
                      <Box p={1} display="flex" alignItems="center" flexWrap="wrap">
                        <Card className={classes.cardTicket}>
                          <CardMedia
                            className={classes.cover}
                            image={ticket.image}
                          />
                          <div className={classes.details}>
                            <CardContent className={classes.content}>
                              <Typography component="h5" variant="h5">
                                {ticket.name}
                              </Typography>
                              <Typography variant="subtitle1" color="textSecondary">
                                <b>Booking at</b><br/>
                                {moment(ticket.booking_time).utc().format("DD MMM YYYY h:mm A")}
                              </Typography>
                            </CardContent>
                          </div>
                        </Card>
                      </Box>

                      {/* Ticket QR */}
                      <Box style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <QRCode 
                        style={{
                          width:'50vw',
                          height:'50vw',
                          display:'flex',
                          margin:'30px auto'
                        }}
                        value={
                          btoa('{"user_id":'+props.currUser.id+',"ticket_id":'+ticket.ticket_id+',"order_id":"'+ticket.orderId+'"}')
                        }
                        />
                        {ticket.isRedeemed ? (
                          <Box style={{
                            position: 'absolute',
                            zIndex: '2'
                          }}>
                            <span class="stamp is-nope">Redeemed</span>
                          </Box>
                        ) : ""}
                      </Box>

                      {/* Ticket Order, Receip and Txn Date */}
                      <Box display="flex">
                        <Box p={1} flexGrow={1}>
                          <b>Order Id:</b> {ticket.orderId}
                        </Box>
                        <Box p={1}>
                          <b>Receipt:</b> {ticket.receipt}
                        </Box>
                      </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box mt={3} display="flex" alignItems="center" justifyContent="center">
                  <Typography component="h3" variant="p">
                    No Tickets Found
                  </Typography>
              </Box>
            )}
          </Box>
          )
        ) : (
          <Box className="login_message">
              You need to <Link to="/signin">LOGIN</Link> to view your Tickets
          </Box>
        )}
      </Container>
    </React.Fragment>
  );
}

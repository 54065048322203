import React from "react";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinkMat from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import { flexbox, borderRadius } from "@material-ui/system";
import axios from "axios";
import * as Constants from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flex: '1',
  },
  deals: {
    // background: 'linear-gradient(106deg, #fc4a1a 63%, #f7b733 60%) fixed'
  },
  headline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.5rem'
  },
  shortdesc: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    marginTop: '10px',
    fontSize: '0.75rem'
  },
  tagline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.25rem',
    marginTop: '30px',
    "& p": {
      transform: 'rotate(90deg)',
      width: '0',
      margin: '0 0 0 10px'
    }
  },
  paperContainer:{
    display:'flex',
    flexWrap: 'wrap',
  },
  paperLink:{
    width: '100vw',
  },
  paper: {
    width: '100%',
    margin: '15px 0 0 0',
    padding: '0 0 15px 0',
    display: 'flex',
    justifyContent: 'space-between',
    background: 'transparent',
    borderBottom: '1px solid rgba(255,255,255,0.5)'
  },
  dealImage: {
    maxWidth: '20vw',
    "& img": {
      width: '20vw',
      height: '20vw',
      borderRadius: '10vw'
    }
  },
  dealContent : {
    flex: 1,
    padding: '0 20px',
    color: '#ffffff',
    fontFamily: 'gilroy-regular',
    fontSize: '1rem',
    "& p": {
      fontSize: '0.75rem',
      margin: 0
    }
  },
  redeem: {
    background: '#E04420',
    padding: '2px 3px',
    margin: '5px 0',
    color: 'white'
  },
}));


export default function Deals() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [filter, setFilter] = React.useState(false);
  const [dealsList, setDealsList] = React.useState();

  const handleFilter = () => {
    setFilter(!filter);
  }

  React.useEffect(() => {
    axios.get(Constants.API_URL+"/api/deals").then((response) => {
      setDealsList(response.data.DealData);
      console.log(dealsList);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container spacing={5} className={classes.deals} maxWidth="md" component="main">
        {loading ? (
          <Box class="loader loader-quart"></Box>
        ) : (
          (dealsList.length <= 0) ? (
          <Box>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              style={{ minHeight: '80vh' }}
            >
              <Grid item>
                Please re-visit to explore new deals. 
              </Grid>   
            
            </Grid> 
          </Box>
        ) : (
          <Box>
            <Box pt={4} style={{position: 'relative'}}>
              <Typography className={classes.headline} variant="h4" component="h2" >
                  Deals
              </Typography>
              <Typography className={classes.shortdesc} component="p" >
                {dealsList.length} results found
              </Typography>

              <Typography className={"filterIcon"} component="label" >
                <span class="icon-Icons_Filter" onClick={handleFilter}></span>
              </Typography>
              <Typography className={classes.shortdesc} component="p" >
                {!filter ? "" : (
                  <Box>
                    No
                  </Box>
                )}
              </Typography>

            </Box>
            <Box className={classes.paperContainer} mt={2}>
              {dealsList.map((items, index) => (
                <Link to={"/deals/"+items.id} className={classes.paperLink}>
                  <Paper className={classes.paper} elevation={0}>
                    <Typography className={classes.dealImage} component="div">
                      <img src={items.deal_image}/> 
                    </Typography>
                    <Typography className={classes.dealContent} component="div">
                      <b>{items.deal_name}</b> <br/>
                      <p>
                        Offer valid <br/> from : {items.deal_start_date}<br/> to : {items.deal_end_date}
                      </p>
                    </Typography>
                    <Typography className={classes.dealRedeem} component="div">
                      <Link to={"/deals/"+items.id}><Button className={classes.redeem}>GET DEAL</Button></Link>
                    </Typography>
                  </Paper>
                </Link>
              ))}
            </Box>
          </Box>
          )
        )}  
      </Container>
    </React.Fragment>
  );
}

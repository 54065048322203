import React from "react";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";
import $ from 'jquery';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import axios from "axios";
import * as Constants from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flex: '1',
  },
  shops: {
    backgroundSize: 'contain !important',
    maxHeight: '300px'
  },
  container: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  headline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.5rem'
  },
  shortdesc: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    marginTop: '10px',
    fontSize: '0.75rem'
  },
  tagline: {
    marginTop: '100px',
    "& span": {
      fontSize: '1rem',
    },
    "& label": {
      fontSize: '1.5rem',
      fontFamily: 'gilroy-extrabold',
    },
  },
  paper: {
    background: 'transparent',
    minWidth: '20vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    border: '1px solid #aaa',
    borderRadius: '20px'
  },
  input: {
    color: 'white',
    fontSize: '1.25rem',
    maxWidth: '26px',
    fontFamily: 'gilroy-extrabold',
    "& input": {
      textAlign: 'center',
      padding: '0',
      display: 'inline-block',
      height: 'auto',
    }
  },
  iconButton: {
    padding: '10px',
    color: 'white',
    "& span": {
      fontFamily: 'gilroy-regular',
      fontSize: '2rem',
      lineHeight: '0.4'
    }
  },
  checkout: {
    textTransform: 'uppercase',
    letterSpacing: '2px',
    padding: '5px 10px',
    borderRadius: '50px',
    color: 'white',
    minWidth: '20vw',
    backgroundColor: '#E04420',
    marginTop: '10px',
    "& span": {
      fontSize: '1rem',
    }
  }
}));

export default function ShopDetails(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [counter, setCounter] = React.useState(1);
  const [shopdetail, setShopDetails] = React.useState({});
  const [tapxcart, setTapxCart] = React.useState(localStorage.tapx_cart == undefined ? [] : JSON.parse(localStorage.tapx_cart));
  let { shopid } = useParams();
  let history = useHistory();

  const handleCheckout = (event) => {

    history.push('/shop/checkout?item_id='+shopid+"&qty="+counter);
  }

  React.useEffect(() => {
    axios.get(Constants.API_URL+"/api/shopdetail/"+shopid).then((response) => {
        setShopDetails(response.data.items);
        setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container spacing={5} style={{background: 'linear-gradient(to bottom, rgba(0,0,0,1) 0% , rgba(0,0,0,0.7) 20%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0.5) 80%, rgba(0,0,0,1) 100%), url('+shopdetail.shop_image+') center 60px fixed no-repeat'}} className={classes.shops}  maxWidth="md" component="main">

        {!loading ? (
          <Box>
            <Box pt={4}>
              <React.Fragment>
                <Typography className={classes.headline} variant="h4" component="h2" >
                  {shopdetail.shop_name}
                </Typography>
                <Typography className={classes.shortdesc} component="p" >
                  {shopdetail.shop_type}
                </Typography>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start" className={classes.tagline}>
                  <Box display="flex">
                    <span class="icon-Icons_Rupee"><label>{parseInt(shopdetail.shop_price)}</label></span>
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems='flex-end'>
                    <Paper component="form" className={classes.paper}>
                      <IconButton onClick={() => {
                        (counter > 1) ? setCounter(counter-1): setCounter(counter);
                        }} className={classes.iconButton} aria-label="menu">
                        -
                      </IconButton>
                      <InputBase
                        className={classes.input}
                        type="number"
                        value={counter}
                        inputProps={{ 'aria-label': 'quantity'}}
                      />
                      <IconButton onClick={() => {
                        (counter < 1 || counter == shopdetail.max_qty_limit) ? setCounter(counter) : setCounter(counter+1);
                        }} className={classes.iconButton} aria-label="menu">
                        +
                      </IconButton>
                    </Paper>
                    <Button onClick={handleCheckout} className={classes.checkout}>
                      Checkout
                    </Button>
                  </Box>
                </Box>
              </React.Fragment>
            </Box>

            <Box className={classes.paperContainer} mt={2} mb={12}>
              {shopdetail.shop_desc}
            </Box>
          </Box>
        ) : (
            <Box class="loader loader-quart"></Box>
        )}
      </Container>
    </React.Fragment>
  );
}


import React from "react";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";
import {Controller, useForm} from 'react-hook-form';
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid} from '@material-ui/core';
import { FormGroup, FormControl, FormControlLabel, FormHelperText, FormLabel, TextField, Switch } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    textfield: {
        color: "white",
        width: "80%",
        display: "block",
        maxWidth: "480px",
        margin: "20px auto",
        "& label, fieldset, legend":{
            color: "white !important",
            borderColor: "white !important"
        },
        "& div, input": {
            width: "100%",
            color: "white !important",
        }
    },
    SwitchChecked: {
        color: "white !important",
    },
    SwitchTrack: {
        backgroundColor: "white !important",
    }
}))

export default function AddressForm(props) {
    const classes = useStyles();
    const [pickUpDelivery, setPickUpDelivery] = React.useState(true);
    const {register, errors, control, handleSubmit} = useForm({ mode: 'onBlur'});

    const handleChange = (event) => {
        setPickUpDelivery(!pickUpDelivery);

    };

    const submitTopUp = (data) => {
        data.isDelivery = pickUpDelivery;
        props.onSubmit(data);
    }

    return (
        <React.Fragment>
            <form id="addressform" onSubmit={handleSubmit(submitTopUp)} noValidate>
                <Typography style={{width:"max-content", margin: "0 auto"}} component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>Pick Up</Grid>
                        <Grid item>
                            <Switch
                            checked={pickUpDelivery}
                            onChange={handleChange}
                            classes= {{
                                checked : classes.SwitchChecked,
                                track : classes.SwitchTrack,
                            }}
                            color="secondary"/>
                        </Grid>
                        <Grid item>Delivery</Grid>
                    </Grid>
                </Typography>
                {pickUpDelivery ? (
                    <Grid spacing={1}>
                        <Grid item>
                            <TextField
                                id="name"
                                label="Full Name"
                                name="name"
                                variant="outlined"
                                error={!!errors.name}
                                className={classes.textfield}
                                inputRef={register({
                                    required: "Name is required",
                                    pattern: {
                                        value: /^[a-zA-Z \s]+$/,
                                        message: "Text  only."
                                    }
                                })}
                                helperText={errors.name && errors.name.message}
                            />
                            <TextField
                                id="phone"
                                label="Mobile Number"
                                name="phone"
                                type="number"
                                inputMode="numeric"
                                variant="outlined"
                                error={!!errors.phone}
                                className={classes.textfield}
                                inputRef={register({
                                    required: "Mobile Number is required",
                                    pattern: {
                                        value: /^[0-9\b]+$/,
                                        message: "Numbers only."
                                    }
                                })}
                                inputProps={{
                                    maxLength: 13,
                                }}
                                helperText={errors.phone && errors.phone.message}
                            />
                            <TextField
                              id="address"
                              label="Full Address"
                              name="address"
                              multiline
                              rows={4}
                              variant="outlined"
                              error={!!errors.address}
                              className={classes.textfield}
                              inputRef={register({
                                required: "Full Address is required.",
                                pattern: {
                                    value: /^[a-zA-Z0-9_., \s]+$/,
                                    message: "Text, Numbers, space, dash and comma only."
                                }
                              })}
                              helperText={errors.address && errors.address.message}
                            />
                            {/*<TextField*/}
                            {/*  id="area"*/}
                            {/*  label="Area"*/}
                            {/*  name="area"*/}
                            {/*  variant="outlined"*/}
                            {/*  error={!!errors.area}*/}
                            {/*  className={classes.textfield}*/}
                            {/*  inputRef={register({*/}
                            {/*    required: "Area is required",*/}
                            {/*    pattern: {*/}
                            {/*        value: /^[a-zA-Z0-9\s]+$/,*/}
                            {/*        message: "Text and Numbers only."*/}
                            {/*    }*/}
                            {/*  })}*/}
                            {/*  helperText={errors.area && errors.area.message}*/}
                            {/*/>*/}
                            <TextField
                              id="landmark"
                              label="Nearest Landmark"
                              name="landmark"
                              variant="outlined"
                              error={!!errors.landmark}
                              className={classes.textfield}
                              inputRef={register({
                                required: "Nearest Landmark is required",
                                pattern: {
                                    value: /^[a-zA-Z0-9\s]+$/,
                                    message: "Text and Numbers only."
                                }
                              })}
                              helperText={errors.landmark && errors.landmark.message}
                            />
                            <TextField
                              id="pincode"
                              label="Pincode"
                              name="pincode"
                              type="number"
                              inputMode="numeric"
                              variant="outlined"
                              error={!!errors.pincode}
                              className={classes.textfield}
                              inputRef={register({
                                required: "Pincode is required",
                                pattern: {
                                    value: /^[0-9\b]+$/,
                                    message: "Numbers only."
                                }
                              })}
                              inputProps={{
                                  maxLength: 10,
                              }}
                              helperText={errors.pincode && errors.pincode.message}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid spacing={1}>
                        <Grid item>
                            <TextField
                              id="pickupcode"
                              label="Agent Pickup Code"
                              name="pickupcode"
                              variant="outlined"
                              error={!!errors.pickupcode}
                              className={classes.textfield}
                              inputRef={register({
                                required: "Agent Code is required.",
                                pattern: {
                                    value: /^[a-zA-Z0-9\s]+$/,
                                    message: "Text and Numbers only."
                                },
                                minLength: {
                                    value: 10,
                                    message: "Please Enter correct Agent Code"
                                },
                                maxLength: {
                                    value: 10,
                                    message: "Please Enter correct Agent Code"
                                }
                              })}
                              inputProps={{
                                  maxLength: 10,
                              }}
                              helperText={errors.pickupcode && errors.pickupcode.message}
                            />
                        </Grid>
                    </Grid>
                )}
            </form>
        </React.Fragment>
    )
}

import React from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { flexbox } from "@material-ui/system";
import axios from "axios";
import * as Constants from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flex: '1',
  },
  merchants: {
    // background: 'linear-gradient(106deg, #c31432 63%, #240b36 60%) fixed'
  },
  media: {
    height: 140
  },
  container: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  headline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.5rem'
  },
  shortdesc: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    marginTop: '10px',
    fontSize: '0.75rem'
  },
  tagline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.25rem',
    marginTop: '30px',
    "& p": {
      transform: 'rotate(90deg)',
      width: '0',
      margin: '0 0 0 10px'
    }
  },
  paperContainer:{
    display:'flex',
    flexWrap: 'wrap'
  },
  paper: {
    width: '40vw',
    height: '40vw',
    margin: '30px 5vw 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '1.2rem',
    "& i": {
      fontSize: '3rem'
    }
  },
  merchantName: {
    fontFamily: "gilroy-bold",
    fontSize: "1rem",
    padding: "0 8px",
    textTransform: "capitalize",
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function MerchantCategories(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [merchantList, setMerchantList] = React.useState([]);
  const [merchantCatName, setMerchantCategoryName] = React.useState("");
  let query = useQuery();
  let history = useHistory();
  let { catid } = useParams();

  React.useEffect(() => {
    axios.get(Constants.API_URL+"/api/merchant/list/category/" + catid).then((response) => {
        console.log(response.data.MerchantList);
        //Compare with previous state
        setMerchantList(response.data.MerchantList);
    });

    axios.get(Constants.API_URL+"/api/merchant/categories/" + catid).then((response) => {
        console.log(response.data.items);
        setMerchantCategoryName(response.data.items[0].cat_name);
        setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container spacing={5} className={classes.merchants} maxWidth="md" component="main">
        {loading ? (
          <Box class="loader loader-quart"></Box>
        ) : (
          <Box>
            <Box pt={4}>
              <Typography className={classes.headline} variant="h4" component="h2" >
                Merchants
              </Typography>
              <Typography className={classes.shortdesc} component="p" >
                {merchantList.length} results found
              </Typography>
              <Typography className={classes.tagline} variant="h5" component="h4">
                {merchantCatName}
              </Typography>
            </Box>
            <Box className={classes.paperContainer} mt={2}>
              {merchantList.map((items, index) => (
                <Link to={"/merchants/"+items.id}>
                  <Paper key={index} className={classes.paper} style={{"background":"linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("+items.merchant_thumb+") no-repeat center center","background-size":"cover"}} elevation={0}>
                    <Box className={classes.merchantName}>
                      {items.merchant_name}
                    </Box>
                  </Paper>
                </Link>
              ))}
            </Box>

          </Box>
                    
        )}
      </Container>
    </React.Fragment>
  );
}

import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Link} from "react-router-dom";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Paper, Grid, Typography, Button} from '@material-ui/core/';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import * as Constants from "utils/constants";
import axios from "axios";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MuiAlert from "@material-ui/lab/Alert";

import ShopIcon from '@material-ui/icons/Shop';
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import {amber} from "@material-ui/core/colors";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ButtonBase from '@material-ui/core/ButtonBase';

import $ from "jquery";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flex: '1',
  },
  appBar: {
    position: 'relative',
    background: 'rgb(224, 68, 30)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  step: {
    paddingBottom: '10px',
    borderBottom: '1px solid rgba(255,255,255,0.15)'
  },
  chips: {
    color: '#ffffff',
    border: '1px solid #ffffff',
    marginRight: '10px',
    padding: '0 5px',
    borderRadius: '15px',
    "& span.icon-Icons_Rupee": {
      fontSize: '0.65rem'
    },
    "&.selected" : {
      border: '1px solid #E04420',
      backgroundColor: '#E04420'
    }
  },
  filterList: {
    display: 'flex',
    marginTop: '20px'
  },

  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  itemMargin: {
    marginTop:'5px'
  },
  itemSubText: { fontSize: "10px",fontFamily: "gilroy-Regular"},
  itemTitleText : {fontSize: 16,fontFamily: "gilroy-bold",letterSpacing:"1px"},
}));


function getOrderText(order) {
  var titleText = order.items.length+ " items (";
  order.items.forEach((item)=>{
      titleText = titleText+item.item_name;
      });
return titleText+")";
}

export default function Orders(props)  {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [tagList, setTagList] = React.useState("");
  const [result, setResult] = React.useState("No result");
  const [filter, setFilter] = React.useState("ALL");

  React.useEffect(() => {
    if(props.currUser.default_tag != null) {
      axios.post(Constants.API_URL + "/api/shop/orders", {userid : props.currUser.id }
      ).then((response) => {
        console.log("responce");
        setLoading(false);
        setResult(response.data.userOrders);
        if(filter=="ALL")
        {
          setTagList(response.data.userOrders);
        }

      }).catch((error) => {
        console.log("error");
        console.log(error);
      });
    }else{
      setLoading(false);
    }
  }, []);

  const handleFilterClick = (event) => {
    $('.filterChip').removeClass('selected');
    event.currentTarget.classList.add('selected');
    setFilter(event.currentTarget.getAttribute('data-amount'));
    applyFilter(event.currentTarget.getAttribute('data-amount'));

  }
  const applyFilter = (selectedFilter)=>
  {
    if(selectedFilter == "ALL")
    {
      setTagList(result);
    }else if(selectedFilter == "CONFIRMED"){
      var filterArray = Array();
      result.map((item) =>
      {
        if(item.delivery_status=='Confirmed')
        {
          filterArray.push(item);
        }
      });
      setTagList(filterArray);
    }else if(selectedFilter == "DISPATCHED"){
      var filterArray = Array();
      result.map((item) =>
      {
        if(item.delivery_status=='Dispatched')
        {
          filterArray.push(item);
        }
      });
      setTagList(filterArray);
    }else if(selectedFilter == "DELIVERED"){
      var filterArray = Array();
      result.map((item) =>
      {
        if(item.delivery_status=='Delivered')
        {
          filterArray.push(item);
        }
      });
      setTagList(filterArray);
    }
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Container spacing={5} className={classes.deals} maxWidth="md" component="main">
      {(props.currUser.length != 0) ? (
              loading ? (
                  <Box class="loader loader-quart"></Box>
              ) : (
                  <Box>
                    <Box mt={4} className={classes.step}>
                      <Box display="flex" alignItems="left" justifyContent="left">
                        <Typography component="h4" variant="h4" style={{ fontSize: 30, fontFamily: "sourcesanspro-bold",letterSpacing:"1px"}}>
                          My Orders
                        </Typography>
                      </Box>
                      {(tagList.length > 0) ? (
                      <Box display="flex" alignItems="left" justifyContent="left">
                        <Typography style={{ fontSize: 12, fontFamily: "gilroy-regular",letterSpacing:"1px"}}>
                          {result.length} &nbsp;&nbsp;PRODUCTS
                        </Typography>
                      </Box>
                          ):('')}
                    </Box>
                    {(tagList.length > 0) ? (
                  <Box>
                    <Box  className={classes.step}>
                      <Box alignItems="left" justifyContent="left" justify="space-between" pt={2}>
                        <Typography style={{ fontSize: 13,fontFamily: "gilroy-bold",letterSpacing:"1.5px"}}  >
                          APPLY FILTERS
                            <span class="icon-Icons_Filter" style={{ fontSize: 20,float:"right"}} alignItems="right" />
                        </Typography>
                        </Box>
                      <Box className={classes.filterList} >
                        <Typography
                            component="div"
                            key={1}
                            onClick={handleFilterClick}
                            className={`
                              ${classes.chips} 
                              filterChip
                              ${("ALL" == filter) ? 'selected' : ''}
                            `}
                            data-amount="ALL"
                        >
                          <Typography component="span">
                            <label>ALL</label>
                          </Typography>
                        </Typography>

                        <Typography
                                component="div"
                                key={2}
                                onClick={handleFilterClick}
                                className={`
                              ${classes.chips} 
                              filterChip
                              ${("CONFIRMED" == filter) ? 'selected' : ''}
                            `}
                                data-amount="CONFIRMED"
                            >
                              <Typography component="span">
                                <label>CONFIRMED</label>
                              </Typography>
                            </Typography>

                        <Typography
                            component="div"
                            key={2}
                            onClick={handleFilterClick}
                            className={`
                              ${classes.chips} 
                              filterChip
                              ${("DISPATCHED" == filter) ? 'selected' : ''}
                            `}
                            data-amount="DISPATCHED"
                        >
                          <Typography component="span">
                            <label>DISPATCHED</label>
                          </Typography>
                        </Typography>

                        <Typography
                            component="div"
                            key={2}
                            onClick={handleFilterClick}
                            className={`
                              ${classes.chips} 
                              filterChip
                              ${("DELIVERED" == filter) ? 'selected' : ''}
                            `}
                            data-amount="DELIVERED"
                        >
                          <Typography component="span">
                            <label>DELIVERED</label>
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <List >
                      {tagList.map((items, index) => (
                      <ListItem className={classes.step} style={{ paddingLeft: "0px",paddingRight: "0px"}}>
                        <Grid container spacing={1}>
                          <Grid item justify = "center" >
                              <Avatar alt={items.items.shop_name} src={items.items.shop_image} className={classes.large} />
                          </Grid>
                          <Grid item xs sm container >
                            <Grid item xs container direction="column">
                              <Grid item xs  className={classes.itemMargin}>
                                <Typography gutterBottom style={{ fontSize: 16,fontFamily: "gilroy-bold",letterSpacing:"1px"}}>
                                  {items.items.shop_name}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="body2" className={classes.itemSubText}>
                                  {moment(items.orderAt).utc().format("DD/MM/YY")}
                                </Typography>
                                <Typography variant="body2" className={classes.itemSubText}>
                                  Order No.: {items.receipt}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs sm container>
                              <Grid item xs container direction="column" >
                                <Grid item xs className={classes.itemMargin}>
                                  <Typography gutterBottom >
                                    <span className='icon-Icons_Rupee' style={{fontSize:"13px",letterSpacing:"2px"}}></span >
                                    <span className={classes.itemTitleText}>{items.amountPaid} </span>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body2" className={classes.itemSubText}>
                                    Quantity : {items.qty}
                                  </Typography>
                                  <Typography variant="body2" className={classes.itemSubText}>
                                   Status : {items.status}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item className={classes.itemMargin}>
                              <span className="icon-Icons_Go-To"></span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                      ))}
                    </List>
                  </Box>
                    ):(
                        <Box mt={5} display="flex" alignItems="center" justifyContent="center">
                          <Typography component="h3" variant="p">
                            No Order Found
                          </Typography>
                        </Box>
                    )}
                  </Box>
         )) : (
        <Box className="login_message">
          You need to <Link to="/signin">LOGIN</Link> to Add/View Tags
        </Box>
      )}
    </Container>
    </React.Fragment>
  );
}

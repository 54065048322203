import React from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Button, Paper, Typography, Container, CssBaseline } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import axios from "axios";
import * as Constants from "utils/constants";
import { fontSize } from "@material-ui/system";
import moment from "moment";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flex: '1',
  },
  infoBox:{
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      "& img": {
          width: '100%',
          border: '1px solid #cccccc'
      },
      "& p": {
          fontSize: '2rem',
          fontWeight: 'bold',
          marginBottom: '20px'
      }
  },
  formControl: {
    minWidth: 80,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  eventDesc: {
      display:'flex',
      height: '90px',
      paddingTop:'16px'
  },
  paymode: {
      left: '16px',
      "& legend": {
          color: 'white !important'
      },
      "& .MuiRadio-colorPrimary.Mui-checked": {
          color: 'red'
      },
      "& .MuiRadio-colorPrimary": {
          color: 'white'
      },
      "& .MuiFormControlLabel-labelPlacementBottom": {
          margin: '0 10px 0 0'
      }
  },
  paynowBox: {
      display: 'flex',
      justifyContent: 'center'
  },
  paynowbutton: {
      width: '80vw',
      fontSize: '1.5rem'
  },
    paper: {
        backgroundColor: 'transparent',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: 'white',
        position: 'relative',
        zIndex: '1',
        borderRadius: '20px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        fontSize: '1.2rem',
        "&.selected": {
            border: '1px solid #E04420'
        },
        "& i": {
            fontSize: '2rem'
        },
        "& span": {
            fontSize: '10px'
        },
        "& label": {
            fontSize: '14px',
            fontFamily: 'gilroy-bold'
        },
        "&::after": {
            content: "",
            position: 'absolute',
            opacity: '0.7',
            filter: 'alpha(opacity=70)',
        }
    },
    eventImage: {
        width: '100%',
        height: '50vw',
        borderRadius: '20px'
    },buyButton:{
        color:"white",
        borderColor:"white",
        letterSpacing: "1.5px",
        fontFamily: 'gilroy-bold'
    },shopName: {
        letterSpacing: '1.5px',
        fontSize: '6vw',
        fontFamily: 'gilroy-bold',
        "& a": {
            color: 'white',
        }
    },
    shopPrice: {
        fontSize: '5vw',
        marginTop: '10px',
        letterSpacing:'2px',
        "& label": {
            fontSize: '6vw',
            fontFamily: 'gilroy-bold',
        }
    },
    textWhite: {
        color: 'white',
    },
    select: {
        "& label, fieldset, legend":{
            color: "white !important",
            borderColor: "white !important"
        },
        "& div, input": {
            width: "100%",
            color: "white !important",
        },
        borderColor: 'white',
        color: 'white',
        '&:before': {
            borderColor: 'white',
        },
        '&:after': {
            borderColor: 'white',
        }
    },
    icon: {
        fill: 'white',
    },
    shopQuantity: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        flex: '1',
        "& h3": {
            fontSize: '6vw',
            lineHeight: '1'
        }
    },
    step: {
        paddingTop: '20px',
        borderTop: '1px solid rgba(255,255,255,0.15)',
        fontFamily: 'gilroy-regular',
        letterSpacing:"1px",
        fontSize:".7rem",
        width: '100%'
    },
    checkout: {
        padding: '15px 25px',
        borderRadius: '20px',
        width: '85vw',
        marginBottom: '80px',
        backgroundColor: '#E04420',
        fontFamily: 'gilroy-regular !important',
        textTransform: 'uppercase',
        "& div.MuiAlert-message" : {
            width: '100%',
        },
        "& p": {
            fontSize: '0.6rem',
            letterSpacing: '2px',
        },
        "& button": {
            letterSpacing: '2px',
            lineHeight: '20px',
            padding: '4px 10px',
            borderRadius: '30px',
            color: 'black',
            backgroundColor: 'white !important',
            "& span": {
                fontSize: '0.85rem',
                margin: 0
            }
        },
        "& span": {
            fontSize: '1rem',
            display: 'block'
        },
        "& label": {
            fontSize: '1.5rem',
            fontFamily: 'gilroy-bold',
        },
    },
    pricebreakup: {

        padding: '20px',
        borderTop: '1px solid rgba(255,255,255,0.15)',
        letterSpacing:"1px",
        width: '100%',
        fontFamily: 'gilroy-bold',
        fontSize: '4vw',
        "& span": {
            fontSize: '3.5vw',
        },
        "& label": {
            fontSize: '5vw',
            fontFamily: 'gilroy-bold',
        }
    },
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function TicketCheckout(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [qty, setQty] = React.useState(1);
  const [payMode, setPayMode] = React.useState('upi');
  const {register, errors, control, handleSubmit} = useForm({ mode: 'onChange'});
  const [finalAmount, setFinalAmount] = React.useState(0);
  const [fessInfo, setfessInfo] = React.useState("");
  const [tickets, setTickets] = React.useState({});
  const [cashfreeData, setCashfreeData] = React.useState("");
  const [razorpayData, setRazorPayData] = React.useState("");
  const [razorpayCBUrl, setRazorpayCBUrl] = React.useState("");
  const [orderId, setOrderId] = React.useState("");
  const [isPayNow, setPayNow] = React.useState(true);
  const [paymentOptionFee, setPaymentOptionFee] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  let query = useQuery(),
  ticketid = query.get("ticket_id");

  var qtyList = [];

  for (var i=0; i<tickets.max_qty_limit; i++) {
      qtyList.push(<MenuItem value={i+1}>{i+1}</MenuItem>);
  }

  const handleChange = (event) => {
    setQty(event.target.value);
    getFees(payMode,event.target.value*tickets.price);

  };

  const handlePaymentChange = (event) => {
    setPayMode(event.currentTarget.dataset.mode);
    getFees(event.currentTarget.dataset.mode,fessInfo.orderAmount);
  };

  const submitTopUp = () => {
      setLoading(true);
    document.getElementsByName("pay_button")[0].disabled = true;
    let upiOption = {
        upi : {
        name: "Pay using UPI",
        instruments: [
            {
                method: "upi",
                flows: ["qr", "collect", "intent"],
                apps: ["google_pay", "phonepe"]
            },
        ]
        },
        sequence: ["upi"],
        preferences: {
        show_default_blocks: false // Should Checkout show its default blocks?
        }
    };
    let cardOption = {
        card: {
            name: "Pay Via Cards",
            instruments: [
            {
                method: "card",
                types: payMode == "cc" ? ["credit"] : ["debit"]
            }
            ]
        },
        sequence: ["card"],
        preferences: {
            show_default_blocks: false // Should Checkout show its default blocks?
        }
    };

      const postTicketData = {
        id : props.currUser.id,
        name : props.currUser.name,
        email : props.currUser.email,
        mobile : props.currUser.mobile,
        ticket_id : tickets.id,
        qty : qty,
        amount : fessInfo.orderAmount,
        paymentMode : payMode,
        orderAmount : fessInfo.orderAmount,
        feeAmount: fessInfo.feeAmount,
        gstAmount: fessInfo.gstAmount,
        finalAmount: fessInfo.finalAmount
      }


      if(tickets.id == null ||
        props.currUser.id == null ||
        props.currUser.name == null ||
        props.currUser.email == null ||
        props.currUser.mobile == null ||
        qty == null) {
        document.getElementsByName("pay_button")[0].disabled = false;
        setLoading(false);
        return;
      }


      axios.post(Constants.API_URL+"/api/book/tickets", postTicketData).then((response) => {
        const mydata = response.data.message;
        // document.getElementsByName("pay_button")[0].disabled = false;
        //setCashfreeData(mydata);
        setRazorPayData(mydata);
        setOrderId(mydata.razorpayOrderId);
        setRazorpayCBUrl(mydata.callbackUrl);
        console.log("Response : " + JSON.stringify(mydata));
        let options = {
            // "key": "rzp_live_2odtcasOHwM9VH", // Enter the Key ID generated from the Dashboard
            "key": mydata.appKey,
            "amount": Math.floor(fessInfo.finalAmount * 100),
            "currency": "INR",
            "order_id": mydata.razorpayOrderId,
            "callback_url": mydata.callbackUrl,
            "description": "TapX - Powered by AtomX",
            "image": "https://atomx.s3.ap-south-1.amazonaws.com/X+logo+tilt+Orange.png",
            "prefill":
              {
                  "email": props.currUser.email,
                  "contact": props.currUser.mobile,
              },
            config: {
              display: payMode == "upi" ? upiOption : cardOption
            },
            "redirect": true,
            "modal": {
              "ondismiss": function () {
                axios.post(Constants.API_URL+"/api/ticket/doTxnFailed", {orderId : mydata.razorpayOrderId});
              }
            }
        };
        setLoading(false);
        let rzp1 = new window.Razorpay(options);
        rzp1.open();
      });
      return;
  };

  React.useEffect(() => {
      console.log("in use effect");
    if(props.currUser != "") {
        axios.get(Constants.API_URL+"/api/tickets/info/" + ticketid).then((response) => {
            console.log(response.data.ticketData);
            if(response.data.ticketData != undefined) {
              setTickets(response.data.ticketData);
              getFees(payMode,response.data.ticketData.price);
              // getFees(document.getElementsByName("amount")[0].value);
            }
        });
    }

  }, []);

  const getFees = (payModeSet,totalAmountSet) => {
      if(paymentOptionFee == "") {
          axios.post(Constants.API_URL+"/api/shop/getFees", {type:'ticket'})
              .then((response) => {
                  setPaymentOptionFee(response.data.feesInfo);

            axios.post(Constants.API_URL+"/api/tickets/viewReceipt",
            {
                type:'ticket',
                mode: (payModeSet != undefined) ? payModeSet : payMode,
                amount : totalAmountSet
            })
            .then((response) => {
                console.log("response.data");
                console.log(response.data);
                setFinalAmount(parseFloat(response.data.feesInfo.finalAmount).toFixed(2));
                setfessInfo(response.data.feesInfo);
                setPayNow(false);
                setLoading(false);
                return;
            });
      });
      }else{
          //Calculation and Logic
          const mypaymode = (payModeSet != undefined) ? payModeSet : payMode;
          const modeData = paymentOptionFee.findIndex(x => x.mode == mypaymode);
          console.log("modeData");
          if(paymentOptionFee[modeData].method == 'fixed') {
              var itemAmount = (totalAmountSet != undefined) ? totalAmountSet : fessInfo.orderAmount,
                  atomxFee = parseFloat(paymentOptionFee[modeData].fee),
                  gstAmount = parseFloat(atomxFee) * (parseFloat(paymentOptionFee[modeData].gst) / 100),
                  finalAmountNew = parseFloat(itemAmount)+ parseFloat(atomxFee)+ parseFloat(gstAmount);

          }else {
              var itemAmount = (totalAmountSet != undefined) ? totalAmountSet : fessInfo.orderAmount,
                  atomxFee = parseFloat(itemAmount) * (parseFloat(paymentOptionFee[modeData].fee) / 100),
                  gstAmount = parseFloat(atomxFee) * (parseFloat(paymentOptionFee[modeData].gst) / 100),
                  finalAmountNew =  parseFloat(itemAmount)  + parseFloat(atomxFee) + parseFloat(gstAmount);
          }

          fessInfo.mode = (payModeSet != undefined) ? payModeSet : payMode;
          fessInfo.fee = parseFloat(paymentOptionFee[modeData].fee).toFixed(2);
          fessInfo.gst = parseFloat(paymentOptionFee[modeData].gst).toFixed(2);
          fessInfo.orderAmount = parseFloat(itemAmount).toFixed(2);
          fessInfo.feeAmount = parseFloat(atomxFee).toFixed(2);
          fessInfo.gstAmount = parseFloat(gstAmount).toFixed(2);
          fessInfo.finalAmount = parseFloat(finalAmountNew).toFixed(2);
          setfessInfo(fessInfo);
          setFinalAmount(parseFloat(finalAmountNew).toFixed(2));
          setLoading(false);
      }
  }

  return (
    <React.Fragment>
        <CssBaseline />
        <Container spacing={5} maxWidth="md" component="main">
        {props.currUser != "" ? (
            <Box pt={4} pb={12}>
                {!loading ? (
                    <React.Fragment>
                    <Paper
                        className={classes.paper}
                        elevation={0}>
                        <img className={classes.eventImage} src={tickets.image} />
                        <div style={{position:'absolute',width:'100%',background: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5))',height:'100%'}}></div>
                        <div style={{position:'absolute',width:'100%',paddingLeft: '20px', paddingBottom: '15px'}}>
                            <Box className={classes.paperBkgnd}></Box>
                            <Box display="flex" justifyContent="flex-start" width="90%" alignItems="flex-start" flexWrap="wrap" flexDirection="column" className={classes.catName}>
                                <Typography className={classes.shopName} variant="p" component="h3">
                                    {tickets.name}
                                </Typography>
                                <Typography className={classes.shopType} variant="p" component="p">
                                    {moment(tickets.event_start_date).utc().format("DD, MMM YYYY")}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" width="90%" alignItems="center">
                                <Typography className={classes.shopPrice} variant="p" component="p">
                                    <span class="icon-Icons_Rupee" style={{fontSize:'4vw'}}><label>{parseInt(tickets.price)}</label></span>
                                </Typography>
                                <Typography component="div">
                                    <Box className={classes.shopQuantity}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel className={classes.textWhite} id="demo-simple-select-helper-label">Quantity</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={qty}
                                                className={classes.select}
                                                inputProps={{classes: {
                                                        icon: classes.icon,
                                                    },
                                                }}
                                                onChange={handleChange}
                                                label="Quantity">
                                                {qtyList}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Typography>
                            </Box>
                        </div>
                    </Paper>
                    <form onSubmit={handleSubmit(submitTopUp)} noValidate>
                        <Box mt={0}>
                                <Box className={classes.eventDesc}>
                                    <Typography paragraph>
                                        {tickets.desc}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.step}  >
                                        <span style={{marginTop:"20px",justifyContent:'center'}}>SELECT PAYMENT MODE</span>
                                    </Typography>
                                </Box>
                                <Box style={{justifyContent:'center'}}>
                                    <Box pt={2} pb={2} display="flex" justifyContent="center">
                                        <ButtonGroup color="secondary" aria-label="outlined primary button group">
                                            <Button variant={payMode=="upi"?"contained":""} onClick={handlePaymentChange} data-mode="upi">UPI <br/> {paymentOptionFee[0].fee == 0.00 ? "Free" : paymentOptionFee[0].fee+"%"}</Button>
                                            <Button variant={payMode=="cc"?"contained":""} onClick={handlePaymentChange} data-mode="cc">Credit Card <br/> {paymentOptionFee[1].fee}%</Button>
                                            <Button variant={payMode=="dc"?"contained":""} onClick={handlePaymentChange} data-mode="dc">Debit Card <br/> {paymentOptionFee[2].fee}%</Button>
                                        </ButtonGroup>
                                    </Box>
                                </Box>

                                <Box className={classes.pricebreakup}>
                                <Box pt={1} display="flex" justifyContent="space-between">
                                    <Typography variant="p" component="p">
                                        TICKET PRICE
                                    </Typography>
                                    <Typography variant="p" component="p">
                                <span class="icon-Icons_Rupee"><label> {fessInfo.orderAmount}</label></span>
                                    </Typography>
                                </Box>
                                <Box pt={1} display="flex" justifyContent="space-between">
                                    <Typography variant="p" component="p">
                                        FEE ({fessInfo.fee == 0.00 ? "Free" : fessInfo.fee+"%"})
                                    </Typography>
                                    <Typography variant="p" component="p">
                                        <span class="icon-Icons_Rupee"><label>{fessInfo.feeAmount}</label></span>
                                    </Typography>
                                </Box>
                                <Box pt={1} display="flex" justifyContent="space-between">
                                    <Typography variant="p" component="p">
                                        GST (<i>{fessInfo.gst}%</i>)
                                    </Typography>
                                    <Typography variant="p" component="p">
                                        <span class="icon-Icons_Rupee"><label>{fessInfo.gstAmount}</label></span>
                                    </Typography>
                                </Box>
                                </Box>
                        </Box>
                        <Snackbar open={open} >
                            <Alert icon={false} className={classes.checkout} severity="warning">
                                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                    <Typography>
                                        Final Amount
                                    </Typography>
                                    <Typography>
                                        {qty} TICKET
                                    </Typography>
                                </Box>

                                <Box pt={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                    <Typography>
                                        <span class="icon-Icons_Rupee"><label>{fessInfo.finalAmount}</label></span>
                                    </Typography>
                                    <Button
                                        name="pay_button"
                                        id="pay_button"
                                        type="submit">
                                        Buy Now
                                    </Button>
                                </Box>
                            </Alert>
                        </Snackbar>
                    </form>
                </React.Fragment>
                ) : (
                    <Box class="loader loader-quart"></Box>
                )}
            </Box>
        ) : (
            <Box className="login_message">
                You need to <Link to="/signin">LOGIN</Link> to view Buy Tickets
            </Box>
        )}
      </Container>
    </React.Fragment>
  );
}

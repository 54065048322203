import React, { useState } from "react";
import {useHistory, useLocation} from "react-router-dom";
import ReactDOM from "react-dom";
import Cookies from 'js-cookie';
import clsx from  'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import Slide from "react-swipeable-views";
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { red, blue, green, yellow, purple, lime, deepPurple, amber } from "@material-ui/core/colors";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";

const useStyles = makeStyles((theme) => ({
  closeButton : {
    minWidth: '25px',
    position: 'absolute',
    right: '0',
    margin: '10px 10px 0 0',
    padding: '10px',
    background: 'rgba(0,0,0,0.5)',
    zIndex: '99999',
    "& i": {
      fontSize: 20
    }
  },
  buttonPopup:{
    background:'none !important',
    boxShadow:'0px',
  "&$ .button .MuiButton-contained ": {
    background:'none !important',
    boxShadow:'0px'
  }
  }
}));
const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen, isMobile ,classes}) => {



  return (
    <div className={classes.buttonPopup} style={{background: 'transparent'}}>

    </div>
  );
}

export default function LandingPage()  {
  const classes = useStyles();
  const sliderVisible = Boolean(Cookies.get('sliderVisible'));
  const isFirstRender = React.useRef(true);
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const matches = useMediaQuery("(max-width:600px)");

  React.useEffect(() => {
    if (!isFirstRender.current) {
        Cookies.set('sliderVisible', true , { expires: 365 });
    }
  }, [handleOpen.open])

  let history = useHistory();
  const handleClick = () => {
    Cookies.set('sliderVisible', true , { expires: 365 });
    setHandleOpen({ open: false })
    history.push("/signup");
  };

  React.useEffect(() => {
    isFirstRender.current = false
  }, [])

  return (
    <React.Fragment>
      {sliderVisible ? null : (
        <Box>
            {handleOpen.open ? (
              <Button className={classes.closeButton} onClick={() => setHandleOpen({ open: false })}>
                <i class="icon-Icons_Close"></i>
              </Button>
            ): ""}
          <AutoRotatingCarousel
              label={<React.Fragment><Button variant="contained" onClick={handleClick}>Register</Button>&nbsp;&nbsp;<Button color='secondary' variant="contained" onClick={() => setHandleOpen({ open: false })} > Skip</Button></React.Fragment>}
              //  label="Register Now"
              open={handleOpen.open}
              onClose={() => setHandleOpen({ open: false })}
              // onStart={handleClick}
              autoplay={true}
              mobile={matches}
              interval={3000}
              style={{ position: "absolute",background: 'transparent' }}
              ButtonProps= {{
                style: {
                  background: 'none',
                  boxShadow: 'none'
                }
              }}
          >
            <Slide
                media={
                  <img src="/assets/images/about/1.jpg" style={{ maxWidth: '100%' }} />
                }
                mediaBackgroundStyle={{ backgroundColor: red[400] }}
                style={{ backgroundColor: red[600] }}
                title="Leave your wallet at home"
                subtitle="THIS IS 2020"
            />
            <Slide
                media={
                  <img src="/assets/images/about/2.jpg" style={{ maxWidth: '100%' }} />
                }
                mediaBackgroundStyle={{ backgroundColor: blue[400] }}
                style={{ backgroundColor: blue[600] }}
                title="Get TapX : Get Funky Fast"
                subtitle="No Internet required to pay"
            />
            <Slide
                media={
                  <img src="/assets/images/about/3.jpg" style={{ maxWidth: '100%' }} />
                }
                mediaBackgroundStyle={{ backgroundColor: amber[300] }}
                style={{ backgroundColor: amber[600] }}
                title="Enjoy Goa Truly"
                subtitle="Contactless &amp; Cashless"
            />
            <Slide
                media={
                  <img src="/assets/images/about/4.jpg" style={{ maxWidth: '100%' }} />
                }
                mediaBackgroundStyle={{ backgroundColor: lime[400] }}
                style={{ backgroundColor: lime[600] }}
                title="30+ Hand picked, "
                subtitle="Highly Curated Merchant Community starting in north Goa!"
            />
            <Slide
                media={
                  <img src="/assets/images/about/5.jpg" style={{ maxWidth: '100%' }} />
                }
                mediaBackgroundStyle={{ backgroundColor: deepPurple[400] }}
                style={{ backgroundColor: deepPurple[600] }}
                title="Takes a second to pay."
                subtitle={"Always!"}
            />
          </AutoRotatingCarousel>

            {/*<AutoRotatingCarouselModal*/}
            {/*  isMobile={matches}*/}
            {/*  handleOpen={handleOpen}*/}
            {/*  setHandleOpen={setHandleOpen}*/}
            {/*  classes = {classes}*/}
            {/*/>*/}
        </Box>

      )}
    </React.Fragment>
  );
}

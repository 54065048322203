import axios from "axios";
import * as Constants from "utils/constants";

export const exploreData = [
    {
        icon: 'icon-Icons_Merchants',
        title: 'Merchants',
        desc: '',
        bkColor: '#2c45ac',
        link: '/merchants'
    },
    {
        icon: 'icon-Icons_Shop',
        title: 'Shop',
        desc: '',
        bkColor: '#724357',
        link: '/shop'
    },
    {
        icon: 'icon-Icons_Deals',
        title: 'Deals',
        desc: '',
        bkColor: '#f69312',
        link: '/deals'
    },
    {
        icon: 'icon-Icons_Top-up',
        title: 'Top-Up',
        desc: '',
        bkColor: '#e0441e',
        link: '/topup'
    },
    {
        icon: 'icon-Icons_Transactions',
        title: 'Transactions',
        desc: '',
        bkColor: '#4f4580',
        link: '/transactions'
    }
]

export let featuredData = []

axios.get(Constants.API_URL+"/api/shops").then((response) => {
    // console.log(response.data.items);
    featuredData = response.data.items;
});

export let dealsData = [];

axios.get(Constants.API_URL+"/api/deals").then((response) => {
    // console.log(response.data.DealData);
    dealsData = response.data.DealData;
});

export let galleryItems= [];

axios.get(Constants.API_URL+"/api/homegallery").then((response) => {
    // console.log(response.data.items);
    galleryItems = response.data.items;
});
export default {
    galleryItems, exploreData, featuredData, dealsData
}

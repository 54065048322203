import React from "react";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";
import {Controller, useForm} from 'react-hook-form';
import $ from 'jquery';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import axios from "axios";
import * as Constants from "utils/constants";
import AddressForm from "./addressform";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import List from "@material-ui/core/List";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flex: '1',
  },
  headline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.5rem'
  },
  shortdesc: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    marginTop: '10px',
    fontSize: '0.75rem'
  },
  shopImage: {
    width: '20vw',
    height: '20vw',
    "& img": {
      maxWidth: '100%',
      borderRadius: '50%',
    }
  },
  shopInfo: {
    margin: '0 10px',
  },
  shopName: {
    fontSize: '6vw',
    fontFamily: 'gilroy-bold',
    "& a": {
      color: 'white',
    }
  },
  shopPrice: {
    fontSize: '4vw',
    marginTop: '10px',
    "& label": {
      fontSize: '6vw',
      fontFamily: 'gilroy-regular',
    }
  },
  shopQuantity: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    flex: '1',
    "& h3": {
      fontSize: '6vw',
      lineHeight: '1'
    }
  },
  shoplist: {
    borderTop: '1px solid #333',
    borderBottom: '1px solid #333',
    padding: '20px 0'
  },
  paper: {
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    border: 'none',
  },
  input: {
    color: 'white',
    fontSize: '6vw',
    maxWidth: '6vw',
    fontFamily: 'gilroy-extrabold',
    "& input": {
      textAlign: 'center',
      padding: '0',
      display: 'inline-block',
      height: 'auto',
    }
  },
  iconButton: {
    padding: '0',
    color: 'white',
    marginTop:'1vw',
    "& span": {
      fontFamily: 'gilroy-regular',
      fontSize: '10vw',
      lineHeight: '0.6',
      padding: '2vw 0',
    }
  },
  checkout: {
    padding: '15px 25px',
    borderRadius: '20px',
    width: '85vw',
    marginBottom: '80px',
    backgroundColor: '#E04420',
    fontFamily: 'gilroy-regular !important',
    textTransform: 'uppercase',
    "& div.MuiAlert-message" : {
      width: '100%',
    },
    "& p": {
      fontSize: '0.6rem',
      letterSpacing: '2px',
    },
    "& button": {
      letterSpacing: '2px',
      lineHeight: '20px',
      padding: '4px 10px',
      borderRadius: '30px',
      color: 'black',
      backgroundColor: 'white !important',
      "& span": {
        fontSize: '0.85rem',
        margin: 0
      }
    },
    "& span": {
      fontSize: '1rem',
      display: 'block'
    },
    "& label": {
      fontSize: '1.5rem',
      fontFamily: 'gilroy-bold',
    },
  },
  pricebreakup: {
    fontFamily: 'gilroy-bold',
    fontSize: '4vw',
    "& span": {
      fontSize: '3.5vw',
    },
    "& label": {
      fontSize: '5vw',
      fontFamily: 'gilroy-bold',
    }
  },
  qtySelect: {
    display:'flex',
    height: '100px',

  },
  formControl: {
    minWidth: 80,

  },
  textWhite: {
    color: 'white',
  },
  select: {
    "& label, fieldset, legend":{
      color: "white !important",
      borderColor: "white !important"
    },
    "& div, input": {
      width: "100%",
      color: "white !important",
    },
    borderColor: 'white',
    color: 'white',
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    }
  },
  icon: {
    fill: 'white',
  },

}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function ShopCheckout(props)  {
  const classes = useStyles();
  const [amount, setAmount] = React.useState(0.00);
  const [qty, setQty] = React.useState('1');
  const [payMode, setPayMode] = React.useState('upi');
  const {register, errors, control, handleSubmit} = useForm({ mode: 'onChange'});
  const [fessInfo, setfessInfo] = React.useState("");
  const [shopItem, setShopItem] = React.useState({});
  const [razorpayData, setRazorPayData] = React.useState("");
  const [razorpayCBUrl, setRazorpayCBUrl] = React.useState("");
  const [orderId, setOrderId] = React.useState("");
  const [isPayNow, setPayNow] = React.useState(true);
  const [feesInfo, setfeesInfo] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(true);
  const [finalAmount, setFinalAmount] = React.useState(0);

  let query = useQuery(),
      itemId = query.get("item_id"),quantity =query.get("qty");

  var qtyList = [];

  if(shopItem!=null)
  {
    for (var i=0; i<shopItem.max_qty_limit; i++) {
      qtyList.push(<MenuItem value={i+1}>{i+1}</MenuItem>);
    }
  }

  const handleChange = (event) => {
    setQty(event.target.value);
    setAmount(event.target.value*parseFloat(shopItem.shop_price));
    getFees();

  };

  const handlePayment= (data) => {
     document.getElementsByName("pay_button")[0].disabled = true;
    setLoading(true);
    let upiOption = {
      upi : {
        name: "Pay using UPI",
        instruments: [
          {
            method: "upi",
            flows: ["qr", "collect", "intent"],
            apps: ["google_pay", "phonepe"]
          },
        ]
      },
      sequence: ["upi"],
      preferences: {
        show_default_blocks: false // Should Checkout show its default blocks?
      }
    };
    let cardOption = {
      card: {
        name: "Pay Via Cards",
        instruments: [
          {
            method: "card",
            types: payMode == "cc" ? ["credit"] : ["debit"]
          }
        ]
      },
      sequence: ["card"],
      preferences: {
        show_default_blocks: false // Should Checkout show its default blocks?
      }
    };


    const postShopData = {
      id : props.currUser.id,
      name : props.currUser.name,
      email : props.currUser.email,
      mobile : props.currUser.mobile,
      shop_item_id : shopItem.id,
      order_mode : data,
      qty : qty,
      shop_item : shopItem,
      amount : amount,
      paymentMode : payMode,
      orderAmount : fessInfo.orderAmount,
      feeAmount: fessInfo.feeAmount,
      gstAmount: fessInfo.gstAmount,
      finalAmount: fessInfo.finalAmount
    };

    if( shopItem.id == null || props.currUser.id == null || props.currUser.name == null ||
        props.currUser.email == null || props.currUser.mobile == null )
    {
      setLoading(false);
      document.getElementsByName("pay_button")[0].disabled = false;
      return;
    }

    axios.post(Constants.API_URL+"/api/shop/tag", postShopData).then((response) => {
      const mydata = response.data.message;
      setLoading(false);
      document.getElementsByName("pay_button")[0].disabled = false;
      setRazorPayData(mydata);
      setOrderId(mydata.razorpayOrderId);
      setRazorpayCBUrl(mydata.callbackUrl);
      console.log("Response : " + JSON.stringify(mydata));
      let options = {
        // "key": "rzp_live_2odtcasOHwM9VH", // Enter the Key ID generated from the Dashboard
        "key": mydata.appKey, // Enter the Key ID generated from the Dashboard
        "amount": Math.floor(finalAmount * 100),
        "currency": "INR",
        "order_id": mydata.razorpayOrderId,
        "callback_url": mydata.callbackUrl,
        "description": "TapX - Powered by AtomX",
        "image": "https://atomx.s3.ap-south-1.amazonaws.com/X+logo+tilt+Orange.png",
        "prefill":
            {
              "email": props.currUser.email,
              "contact": props.currUser.mobile,
            },
        config: {
          display: payMode == "upi" ? upiOption : cardOption
        },
        "redirect": true,
        "modal": {
          "ondismiss": function () {
            axios.post(Constants.API_URL+"/api/shop/doTxnFailed", {orderId : mydata.razorpayOrderId});
          }
        }
      };
      console.log(options);
      let rzp1 = new window.Razorpay(options);
      rzp1.open();
    });
    return;
  };



  const handlePayModeChange = (event) => {
    setPayMode(event.currentTarget.dataset.mode);
    getFees(event.currentTarget.dataset.mode);
  };
  const getFees = (payModeSet,totalAmountSet) => {
    console.log("feesInfo");
    console.log(feesInfo);

    if(feesInfo == "") {
      console.log("in feesinfo");
      setLoading(true);
      axios.post(Constants.API_URL+"/api/shop/getFees", {type:'shop'})
      .then((response) => {
          setfeesInfo(response.data.feesInfo);
          axios.post(Constants.API_URL+"/api/shop/viewReceipt",
          {
              type:'shop',
              mode: (payModeSet != undefined) ? payModeSet : payMode,
              amount : amount
          })
          .then((response) => {

            setfessInfo(response.data.feesInfo);
            setFinalAmount(parseFloat(response.data.feesInfo.finalAmount).toFixed(2));
            setPayNow(false);
            setLoading(false);
              return;
          });
      });
    } else {
      console.log("not in feesinfo");
      console.log(payMode);
      //Calculation and Logic
      const mypaymode = (payModeSet != undefined) ? payModeSet : payMode;
      const modeData = feesInfo.findIndex(x => x.mode == mypaymode);
      console.log("modeData");
      if(feesInfo[modeData].method == 'fixed') {
        var itemAmount = (totalAmountSet != undefined) ? totalAmountSet : amount,
          atomxFee = parseFloat(feesInfo[modeData].fee),
          gstAmount = parseFloat(atomxFee) * (parseFloat(feesInfo[modeData].gst) / 100),
          finalAmountNew = parseFloat(itemAmount)+ parseFloat(atomxFee)+ parseFloat(gstAmount);

      }else {
        var itemAmount = (totalAmountSet != undefined) ? totalAmountSet : amount,
          atomxFee = parseFloat(itemAmount) * (parseFloat(feesInfo[modeData].fee) / 100),
          gstAmount = parseFloat(atomxFee) * (parseFloat(feesInfo[modeData].gst) / 100),
          finalAmountNew =  parseFloat(itemAmount)  + parseFloat(atomxFee) + parseFloat(gstAmount);
      }

      fessInfo.mode = (payModeSet != undefined) ? payModeSet : payMode;
      fessInfo.fee = parseFloat(feesInfo[modeData].fee).toFixed(2);
      fessInfo.gst = parseFloat(feesInfo[modeData].gst).toFixed(2);
      fessInfo.orderAmount = parseFloat(itemAmount).toFixed(2);
      fessInfo.feeAmount = parseFloat(atomxFee).toFixed(2);
      fessInfo.gstAmount = parseFloat(gstAmount).toFixed(2);
      fessInfo.finalAmount = parseFloat(finalAmountNew).toFixed(2);
      setfessInfo(fessInfo);
      setFinalAmount(parseFloat(finalAmountNew).toFixed(2));
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if(props.currUser != "") {
      axios.get(Constants.API_URL+"/api/shopdetail/" + itemId).then((response) => {
        if(response.data != undefined)
        {
          console.log("quantity");
          console.log(quantity);
          if(qty==1)
          {
            setQty(quantity!=undefined ?quantity:'1');
          }
          setShopItem(response.data.items);
          setAmount(response.data.items.shop_price*qty);
          getFees();
        }
      });
    }
  }, [amount, qty]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container spacing={5} maxWidth="sm" component="main">
        {props.currUser != "" ? (
          <React.Fragment>
            <Box pt={4}>
                <React.Fragment>
                  <Typography className={classes.headline} variant="h4" component="h2" >
                    My Cart
                  </Typography>
                </React.Fragment>
            </Box>
            <Box pt={4} pb={12}>
              {!loading ? (
                <React.Fragment>
                  <Box className={classes.shoplist}>
                    {/*{shopList.map((shop, index) => (*/}
                    <Box mb={3} display="flex" justifyContent="center" alignItems="center">
                      <Box className={classes.shopImage}>
                        <img src={shopItem.shop_image} />
                      </Box>
                      <Box className={classes.shopInfo}>
                        <Typography className={classes.shopName} variant="h5" component="h5">
                          <Link>{shopItem.shop_name}</Link>
                        </Typography>
                        <Typography className={classes.shopPrice} variant="p" component="p">
                          <span class="icon-Icons_Rupee"><label>{parseInt(shopItem.shop_price)}</label></span>
                        </Typography>
                      </Box>
                      <Box className={classes.shopQuantity}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel className={classes.textWhite} id="demo-simple-select-helper-label">Quantity</InputLabel>
                          <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={qty}
                              className={classes.select}
                              inputProps={{classes: {
                                  icon: classes.icon,
                                },
                              }}
                              onChange={handleChange}
                              // onChange={() => this.countUp()} {{handleChange}}
                              label="Quantity">
                            {qtyList}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                      <AddressForm onSubmit={handlePayment}/>
                  </Box>
                  <Box pb={2} className={classes.pricebreakup} display="flex" flexDirection="column">
                    <Box pt={2} pb={2} display="flex" justifyContent="center">
                      <ButtonGroup color="secondary" aria-label="outlined primary button group">
                        <Button variant={payMode=="upi"?"contained":""} onClick={handlePayModeChange} data-mode="upi">UPI <br/> {feesInfo[0].fee == 0.00 ? "Free" : feesInfo[0].fee+"%"}</Button>
                        <Button variant={payMode=="cc"?"contained":""} onClick={handlePayModeChange} data-mode="cc">Credit Card <br/> {feesInfo[1].fee}%</Button>
                        <Button variant={payMode=="dc"?"contained":""} onClick={handlePayModeChange} data-mode="dc">Debit Card <br/> {feesInfo[2].fee}%</Button>
                      </ButtonGroup>
                    </Box>
                    <Box pt={1} display="flex" justifyContent="space-between">
                      <Typography variant="p" component="p">
                        TAG PRICE
                      </Typography>
                      <Typography variant="p" component="p">
                        <span class="icon-Icons_Rupee"><label>{amount
                        }</label></span>
                      </Typography>
                    </Box>
                    <Box pt={1} display="flex" justifyContent="space-between">
                      <Typography variant="p" component="p">
                        FEE ({fessInfo.fee == 0.00 ? "Free" : fessInfo.fee+"%"})
                      </Typography>
                      <Typography variant="p" component="p">
                        <span class="icon-Icons_Rupee"><label>{fessInfo.feeAmount}</label></span>
                      </Typography>
                    </Box>
                    <Box pt={1} display="flex" justifyContent="space-between">
                      <Typography variant="p" component="p">
                        GST (<i>{fessInfo.gst}%</i>)
                      </Typography>
                      <Typography variant="p" component="p">
                        <span class="icon-Icons_Rupee"><label>{fessInfo.gstAmount}</label></span>
                      </Typography>
                    </Box>
                  </Box>

                  <Snackbar open={open} >
                    <Alert icon={false} className={classes.checkout} severity="warning">
                      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Typography>
                          Final Amount
                        </Typography>
                        <Typography>
                          1 items added
                        </Typography>
                      </Box>

                      <Box pt={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Typography>
                          <span class="icon-Icons_Rupee"><label>{finalAmount}</label></span>
                        </Typography>
                        <Button
                            name="pay_button"
                            form="addressform"
                            type="submit">
                          Buy Now
                        </Button>
                      </Box>
                    </Alert>
                  </Snackbar>

                </React.Fragment>
              ) : (
                <Box class="loader loader-quart"></Box>
              )}
            </Box>
          </React.Fragment>
        ) : (
            <Box className="login_message">
                You need to <Link to="/signin">LOGIN</Link> to proceed to Your Cart.
            </Box>
        )}
        </Container>
    </React.Fragment>
  );
}

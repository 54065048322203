import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
	Box,
	Grid,
	Button,
	Paper,
	Typography,
	Container,
	CssBaseline,
	colors,
} from "@material-ui/core";
import { Step, Stepper, StepLabel, StepContent } from "@material-ui/core";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import LocalDiningOutlinedIcon from "@material-ui/icons/LocalDiningOutlined";
import { Controller, useForm } from "react-hook-form";
import SelectCard from "./selectcard";
import SelectAmount from "./selectamount";
import PaymentTotal from "./paymenttotal";
import Status from "./paymentstatus";
import axios from "axios";
import * as Constants from "utils/constants";
import { red } from "@material-ui/core/colors";
import { act } from "react-dom/test-utils";
// import moment from "moment";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
	button: {
		marginRight: theme.spacing(1),
	},
	footerSection: {
		margin: "50px 0px",
		borderTop: "0.02em solid gray",
	},
	footerChildMargin: {
		marginTop: "50px",
	},
	footerItem: {
		color: "white",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	topup: {
		// background: 'url(/assets/images/Links_Top-up.jpg) center top fixed',
		backgroundSize: "cover",
		// background: 'linear-gradient(106deg, #f5af19 63%, #ffed4b 60%) fixed'
	},
	button: {
		backgroundColor: "#ffffff",
		marginRight: "10px",
		"&:hover": {
			backgroundColor: "gray", // Change the background color on hover
		},
	},
	nextbutton: {
		"&[disabled]": {
			backgroundColor: "#3f51b5",
		},
	},
	topupagain: {
		color: "white",
		border: "1px solid #ffffff",
		background: "rgb(224, 68, 30)",
	},
	step: {
		marginBottom: "15px",
		borderBottom: "1px solid rgba(255,255,255,0.15)",
	},
	stepper: {
		background: "transparent",
		padding: "20px 0",
	},
	stepContent: {
		margin: "0",
		border: "none",
	},
	headline: {
		fontFamily: "gilroy-extrabold",
		fontSize: "1.5rem",
	},
	shortdesc: {
		textTransform: "uppercase",
		letterSpacing: "1px",
		marginTop: "10px",
		fontSize: "0.75rem",
		marginBottom: "20px",
	},
	tagnum: {
		textTransform: "uppercase",
		letterSpacing: "1px",
		marginTop: "10px",
		fontSize: "1.25rem",
	},
	tagline: {
		fontFamily: "gilroy-extrabold",
		fontSize: "1.25rem",
		marginTop: "20px",
		"& p": {
			transform: "rotate(90deg)",
			width: "0",
			margin: "0 0 0 10px",
		},
	},
	stepsBar: {
		color: "#ffffff",
		padding: "0 0 10px 0",
	},
	stepNumber: {
		fontFamily: "gilroy-regular",
		fontSize: "0.6rem",
		letterSpacing: "0.2rem",
	},
	stepLabel: {
		fontFamily: "gilroy-bold",
		fontSize: "1.15rem",
		margin: "10px 0 0 0",
		"& label": {
			margin: "0 0 0 10px",
			fontSize: "1.15rem",
		},
	},
	selectedStep: {
		paddingLeft: "38px",
		fontFamily: "gilroy-regular",
		fontSize: "0.6rem",
		letterSpacing: "0.2rem",
	},
	eventImage: {
		// width: "100%",
		maxWidth: "100%",
		borderRadius: "20px",
	},
}));

function getSteps() {
	return [
		{
			icon: "Icons_Tags-Form-Factors",
			title: "Enter Details",
		},
		{
			icon: "Icons_Tags-Form-Factors",
			title: "Select Amount",
		},
		{
			icon: "Icons_Tags-Form-Factors",
			title: "Payment",
		},
		{
			icon: "Icons_Tags-Form-Factors",
			title: "Status",
		},
	];
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function TopUp(props) {
	const classes = useStyles();
	let query = useQuery();
	const [isImage, setIsImage] = useState(true);
	const [statusloading, setStatusLoading] = React.useState(false);
	const { register, errors, control, handleSubmit } = useForm({
		mode: "onChange",
	});
	const [showTapx, setShowTapx] = useState(1);
	const [showMenu, setShowMenu] = useState(null);
	const [isNameInvalid, setIsNameInvalid] = useState(false);
	const [isEmailInvalid, setIsEmailInvalid] = useState(false);
	const [isMobileInvalid, setIsMobileInvalid] = useState(false);
	const [isTagIdInvalid, setIsTagIdInvalid] = useState(false);
	const [activeStep, setActiveStep] = React.useState(0);
	const [cashfreeData, setCashfreeData] = React.useState("");
	const [razorpayData, setRazorPayData] = React.useState("");
	const [razorpayCBUrl, setRazorpayCBUrl] = React.useState("");
	const [orderId, setOrderId] = React.useState("");
	const [isBusy, setBusy] = React.useState(false);
	const [eventId, setEventId] = React.useState("");
	const [statusInfo, setStatusInfo] = React.useState(
		JSON.parse(query.get("response"))
	);

	const steps = getSteps();

	let history = useHistory();

	const handleNext = (event) => {
		event.preventDefault();
	};
	const handleBack = (e) => {
		// console.log("backbutton");
		setActiveStep((prevActiveStep) => {
			// console.log(prevActiveStep);
			return prevActiveStep - 1;
		});
		// console.log(activeStep);
		// document
		// 	.getElementById("step" + `${activeStep - 1}`)
		// 	.scrollIntoView({ behavior: "smooth" });
		// document.getElementById("step" + activeStep).scrollIntoView(true);
	};

	const handleStep = (step) => () => {
		history.push(
			`/topup?fullname=${statusInfo.customerName}&email=${statusInfo.customerEmail}&mobile=${statusInfo.customerMobile}&tag=${statusInfo.cardId}&eventId=${eventId}`
		);
		setActiveStep(step);
		// console.log('aml here');
		// document.getElementById("step"+(activeStep)).scrollIntoView(true);
	};

	function showStep(param) {
		switch (param) {
			case 0:
				return (
					<SelectCard
						currUser={props.currUser}
						handleNext={handleNext}
						isNameInvalid={isNameInvalid}
						setIsNameInvalid={setIsNameInvalid}
						isEmailInvalid={isEmailInvalid}
						setIsEmailInvalid={setIsEmailInvalid}
						isMobileInvalid={isMobileInvalid}
						setIsMobileInvalid={setIsMobileInvalid}
						isTagIdInvalid={isTagIdInvalid}
						setIsTagIdInvalid={setIsTagIdInvalid}
					/>
				);
			case 1:
				return <SelectAmount />;
			case 2:
				return <PaymentTotal currUser={props.currUser} />;
			case 3:
				return <Status disabled />;
			default:
				return;
		}
	}

	React.useEffect(() => {
		// console.log({ isImage });
		if (!isImage) {
			console.log("image not exist", activeStep);
			if (activeStep == 0) window.scroll(0, 0);
			if (activeStep == 1) window.scroll(0, 250);
			if (activeStep == 2) window.scroll(0, 300);
			if (activeStep == 3) window.scroll(0, 370);
		} else {
			console.log("image exist");
			if (activeStep == 0) window.scroll(0, 0);
			if (activeStep == 1) window.scroll(0, 500);
			if (activeStep == 2) window.scroll(0, 550);
			if (activeStep == 3) window.scroll(0, 600);
		}

		// fieldRef.current.scrollIntoView();
		// if (activeStep > 0) {
		// 	console.log({ activeStep });
		// 	document
		// 		.getElementById(`step + ${activeStep}`)
		// 		.scrollIntoView(true);
		// 	// window.scroll(0, 100);
		// }

		// console.log(activeStep);

		if (query.get("eventId")) {
			setEventId(query.get("eventId"));
			props.handler(true);
		} else {
			// if(parseInt(query.get("step")) != 3) {
			// 	document.getElementById("step"+(activeStep + 1)).scrollIntoView(true);
			// }
			// else {
			// 	document.getElementById("step3").scrollIntoView(true);
			// }
		}
	}, [activeStep]);

	React.useEffect(() => {
		if (query.get("eventId")) {
			setEventId(query.get("eventId"));
			const response = axios.get(
				`https://dashboard-atomx.s3-us-west-2.amazonaws.com/events/${query.get(
					"eventId"
				)}/banner/logo.jpg`
			);
			console.log(response);
			axios
				.get(
					`https://dashapi.atomx.in/dashboardAPI/v1/Event/EventTapXDetails?code=${query.get(
						"eventId"
					)}`
				)
				.then((response) => {
					if (response.data.success) {
						console.log(response.data);
						setShowTapx(response.data.useTapX);
						setShowMenu(response.data.showMenu);

						// setTagBalance(parseInt(response.data.tag_balance));
					}
				})
				.catch((error) => {
					console.error("Error fetching event details:", error);
					// Handle the error here (e.g., show a user-friendly message)
				});
		}
		if (query.get("tag")) {
			if (props.currUser.length != 0) {
				setActiveStep(1);
			} else {
				setActiveStep(0);
			}
		}
		if (query.get("tag") && query.get("amount")) {
			setActiveStep(2);
		}
		if (query.get("step")) {
			setActiveStep(parseInt(query.get("step")));
		}
	}, []);

	const submitTopUp = () => {
		if (activeStep == 2) {
			document.getElementsByName("pay_button")[0].disabled = true;
			setStatusLoading(true);
			let upiOption = {
				upi: {
					name: "Pay using UPI",
					instruments: [
						{
							method: "upi",
							flows: ["qr", "collect", "intent"],
							apps: ["google_pay"],
						},
					],
				},
				sequence: ["upi"],
				preferences: {
					show_default_blocks: false, // Should Checkout show its default blocks?
				},
			};
			let cardOption = {
				card: {
					name: "Pay Via Cards",
					instruments: [
						{
							method: "card",
							types:
								document.getElementsByName("paymentMode")[0]
									.value == "cc"
									? ["credit"]
									: ["debit"],
						},
					],
				},
				sequence: ["card"],
				preferences: {
					show_default_blocks: false, // Should Checkout show its default blocks?
				},
			};

			const postTopupData = {
				id: props.currUser.id,
				name:
					props.currUser.name != undefined
						? props.currUser.name
						: query.get("fullname"),
				email:
					props.currUser.email != undefined
						? props.currUser.email
						: query.get("email"),
				mobile:
					props.currUser.mobile != undefined
						? props.currUser.mobile
						: query.get("mobile"),
				card_id: query.get("tag"),
				amount: query.get("amount"),
				paymentMode: document.getElementsByName("paymentMode")[0].value,
				orderAmount: document.getElementsByName("orderAmount")[0].value,
				feeAmount: document.getElementsByName("feeAmount")[0].value,
				gstAmount: document.getElementsByName("gstAmount")[0].value,
				finalAmount: document.getElementsByName("finalAmount")[0].value,
				isCoupon: document.getElementsByName("isCoupon")[0].value,
				eventId: document.getElementsByName("eventId")[0].value,
				couponCode: document.getElementsByName("couponCode")[0].value,
				dealId: document.getElementsByName("dealId")[0].value,
				dealMerchantId:
					document.getElementsByName("deal_merchant_id")[0].value,
				// code: query.get("eventId"),
			};

			if (postTopupData.card_id == null) {
				document.getElementsByName("pay_button")[0].disabled = false;
				return;
			}
			axios
				.post(Constants.API_URL + "/api/topup/doTopup", postTopupData)
				.then((response) => {
					const mydata = response.data.message;
					document.getElementsByName(
						"pay_button"
					)[0].disabled = false;
					setStatusLoading(false);
					//setCashfreeData(mydata);
					setRazorPayData(mydata);
					setOrderId(mydata.razorpayOrderId);
					// console.log(mydata.callbackUrl);
					// mydata.callbackurl.replace('https://api.tapx.online', 'http://localhost:3000');
					// mydata.callbackUrl = "localhost:2000/api/topup/paymentResponseRazorpay/TOPUP_TX1543";
					// console.log('new', mydata.callbackUrl);
					setRazorpayCBUrl(mydata.callbackUrl);
					// console.log("Response : " + JSON.stringify(mydata));
					let options = {
						// "key": "rzp_live_K7Dd0VYzusRXUd", // Enter the Key ID generated from the Dashboard
						key: mydata.appKey, // Enter the Key ID generated from the Dashboard
						amount: Math.floor(
							document.getElementsByName("finalAmount")[0].value *
								100
						),
						currency: "INR",
						order_id: mydata.razorpayOrderId,
						callback_url: mydata.callbackUrl,
						description: "TapX - Powered by AtomX",
						image: "https://atomx.s3.ap-south-1.amazonaws.com/X+logo+tilt+Orange.png",
						timeout: 345,
						prefill: {
							email:
								props.currUser.email != undefined
									? props.currUser.email
									: query.get("email"),
							contact:
								props.currUser.mobile != undefined
									? props.currUser.mobile
									: query.get("mobile"),
						},
						config: {
							display:
								document.getElementsByName("paymentMode")[0]
									.value == "upi"
									? upiOption
									: cardOption,
						},
						redirect: true,
						modal: {
							ondismiss: function () {
								axios.post(
									Constants.API_URL +
										"/api/topup/doTxnFailed",
									{ orderId: mydata.razorpayOrderId }
								);
							},
						},
					};
					// console.log(options);
					let rzp1 = new window.Razorpay(options);
					rzp1.open();
				})
				.catch((err) => {
					document.getElementsByName(
						"pay_button"
					)[0].disabled = false;
				});

			return;
		} else {
			let tags = document.getElementsByName("tags")[0],
				fullname = document.getElementsByName("fullname")[0],
				email = document.getElementsByName("email")[0],
				mobile = document.getElementsByName("mobile")[0],
				pay_amount = document.getElementsByName("pay_amount")[0];
			if (activeStep == 0) {
				const isFullNameValid = /[a-zA-Z\s]+$/.test(fullname.value);
				const isEmailValid =
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
						email.value
					);
				const isMobileValid = /^[0-9]{10}$/.test(mobile.value);
				const isTagIdValid = /^[0-9]{8,10}$/.test(tags.value);
				if (fullname.value == "" || !isFullNameValid) {
					setIsNameInvalid(true);
				} else if (email.value == "" || !isEmailValid) {
					setIsEmailInvalid(true);
				} else if (
					mobile.value == "" ||
					!isMobileValid ||
					mobile.value < 1000000000
				) {
					setIsMobileInvalid(true);
				} else if (tags.value == "" || !isTagIdValid) {
					setIsTagIdInvalid(true);
				} else {
					query.set("fullname", fullname.value);
					query.set("email", email.value);
					query.set("mobile", mobile.value);
					query.set("tag", tags.value);
					history.push("?" + query.toString());
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				}
			}
			if (pay_amount != undefined) {
				// console.log("pay_amount");
				if (pay_amount.value != "") {
					query.set("amount", pay_amount.value);
					history.push("topup?" + query.toString());
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				}
			}
		}

		return false;
	};

	return (
		<React.Fragment>
			<CssBaseline />
			{eventId ? (
				<Container
					spacing={2}
					className={classes.topup}
					maxWidth="md"
					component="main"
				>
					{statusloading ? (
						<Backdrop
							className={classes.backdrop}
							open={statusloading}
						>
							<CircularProgress color="inherit" />
						</Backdrop>
					) : (
						""
					)}
					<Box pt={4}>
						{eventId ? (
							<img
								id={"step-1"}
								className={classes.eventImage}
								src={
									"https://dashboard-atomx.s3-us-west-2.amazonaws.com/events/" +
									eventId +
									"/banner/logo.jpg"
								}
								onError={({ currentTarget }) => {
									setIsImage(false);
									currentTarget.onerror = null; // prevents looping
									// currentTarget.src="image_path_here";
									currentTarget.src =
										"https://dashboard-atomx.s3.us-west-2.amazonaws.com/dummy-x.png";
								}}
							/>
						) : (
							<Paper
								id={"step-1"}
								style={{ marginTop: "10px" }}
							/>
						)}

						{showMenu && (
							<Box style={{ paddingTop: "10px" }}>
								<Link
									to={{
										pathname:
											showMenu == "true"
												? `https://screenpe.com/menu/event/${eventId}`
												: showMenu,
									}}
									target="_blank"
								>
									<Button
										className={classes.button}
										style={{
											backgroundColor: "#f96332",
											borderRadius: "10px",
											color: "white",
										}}
									>
										<LocalDiningOutlinedIcon />
										<Typography>MENU</Typography>
									</Button>
								</Link>
							</Box>
						)}
						{showTapx == 1 && (
							<Typography
								className={classes.headline}
								variant="h4"
								component="h2"
							>
								Top-up | Make a cashless payment with only a few
								taps!
							</Typography>
						)}
						{showTapx == 1 && (
							<Typography
								className={classes.shortdesc}
								component="p"
							>
								Follow 4 Simple Steps
							</Typography>
						)}
						{/*<Typography className={classes.tagline} >*/}
						{/*	Make a cashless payment with only a few taps!*/}
						{/*	/!*<p><i class="icon-Icons_Go-To"></i></p>*!/*/}
						{/*</Typography>*/}
					</Box>
					{showTapx == 1 && (
						<Box mt={2}>
							{isBusy ? (
								"Loading...."
							) : (
								<form
									onSubmit={handleSubmit(submitTopUp)}
									noValidate
								>
									<Stepper
										orientation="vertical"
										steps="4"
										className={classes.stepper}
										connector={false}
										activeStep={activeStep}
									>
										{steps.map((label, index) => (
											<Step
												className={classes.step}
												id={`step + ${index}`}
												key={label.title}
											>
												<Box
													className={classes.stepsBar}
												>
													<Typography
														className={
															classes.stepNumber
														}
														component="p"
													>
														STEP #0{index + 1}
													</Typography>
													<Typography
														className={
															classes.stepLabel
														}
														component="div"
													>
														<i
															class={
																"icon-" +
																label.icon
															}
														></i>
														<label
															style={
																activeStep ==
																index
																	? {
																			fontWeight:
																				"bold",
																			fontSize:
																				"1.5rem",
																	  }
																	: {}
															}
														>
															{index == 0 &&
															eventId == 282
																? "Enter Your Details"
																: label.title}
														</label>
														{query.get("tag") ==
															undefined ||
														activeStep == index ? (
															""
														) : index == 0 ? (
															<Typography
																className={
																	classes.selectedStep
																}
																component="p"
															>
																Tag:{" "}
																{query.get(
																	"tag"
																)}
															</Typography>
														) : (
															""
														)}
														{query.get("amount") ==
															undefined ||
														activeStep == index ? (
															""
														) : index == 1 ? (
															<Typography
																className={
																	classes.selectedStep
																}
																component="p"
															>
																Amount:{" "}
																{parseInt(
																	query.get(
																		"amount"
																	)
																)
																	.toString()
																	.replace(
																		/\B(?=(\d{3})+(?!\d))/g,
																		","
																	)}
															</Typography>
														) : (
															""
														)}
														{query.get("step") ==
														3 ? (
															index == 0 ? (
																<Typography
																	className={
																		classes.selectedStep
																	}
																	component="p"
																>
																	Tag:{" "}
																	{
																		statusInfo.cardId
																	}
																</Typography>
															) : index == 1 ? (
																<Typography
																	className={
																		classes.selectedStep
																	}
																	component="p"
																>
																	Amount:{" "}
																	{parseInt(
																		statusInfo.orderAmount
																	)
																		.toString()
																		.replace(
																			/\B(?=(\d{3})+(?!\d))/g,
																			","
																		)}
																</Typography>
															) : index == 2 ? (
																<Typography
																	className={
																		classes.selectedStep
																	}
																	component="p"
																>
																	Payment:{" "}
																	{
																		statusInfo.txStatus
																	}
																</Typography>
															) : (
																""
															)
														) : (
															""
														)}
													</Typography>
												</Box>

												<StepContent
													className={
														classes.stepContent
													}
												>
													<Paper
														style={{
															background:
																"transparent",
														}}
														square
														elevation={0}
													>
														{showStep(activeStep)}
													</Paper>
													<Box my={2}>
														{activeStep ===
														steps.length - 1 ? (
															""
														) : (
															<Grid
																container
																alignItems="flex-start"
																direction="row"
															>
																<Box component="span">
																	<Button
																		disabled={
																			activeStep ===
																				0 ||
																			activeStep ===
																				steps.length -
																					1
																		}
																		onClick={
																			handleBack
																		}
																		className={
																			classes.button
																		}
																	>
																		Back
																	</Button>
																	<Button
																		type="submit"
																		variant="contained"
																		color="primary"
																		name="pay_button"
																		className={
																			classes.nextbutton
																		}
																	>
																		{activeStep ===
																		2
																			? "PAY NOW"
																			: "Next"}
																	</Button>
																</Box>
															</Grid>
														)}
													</Box>
													{activeStep === 3 ? (
														<label>
															<Button
																className={
																	classes.topupagain
																}
																size="small"
																onClick={handleStep(
																	1
																)}
																variant="outlined"
																color="primary"
															>
																TopUp Again
															</Button>
														</label>
													) : (
														""
													)}
												</StepContent>
											</Step>
										))}
									</Stepper>
								</form>
							)}
						</Box>
					)}
					<form
						id="redirectForm"
						method="post"
						action="https://test.cashfree.com/billpay/checkout/post/submit"
					>
						<input
							type="hidden"
							name="appId"
							value={cashfreeData.appId ? cashfreeData.appId : ""}
						/>
						<input
							type="hidden"
							name="orderId"
							value={
								cashfreeData.orderId ? cashfreeData.orderId : ""
							}
						/>
						<input
							type="hidden"
							name="orderAmount"
							value={
								cashfreeData.amount
									? cashfreeData.amount
									: query.get("amount")
							}
						/>
						<input
							type="hidden"
							name="orderCurrency"
							value={
								cashfreeData.currency
									? cashfreeData.currency
									: "INR"
							}
						/>
						<input
							type="hidden"
							name="orderNote"
							value={
								cashfreeData.orderNote
									? cashfreeData.orderNote
									: ""
							}
						/>
						<input
							type="hidden"
							name="customerName"
							value={
								cashfreeData.customer_name
									? cashfreeData.customer_name
									: props.currUser.name
							}
						/>
						<input
							type="hidden"
							name="customerEmail"
							value={
								cashfreeData.customer_email
									? cashfreeData.customer_email
									: props.currUser.email
							}
						/>
						<input
							type="hidden"
							name="customerPhone"
							value={
								cashfreeData.customer_mobile
									? cashfreeData.customer_mobile
									: props.currUser.mobile
							}
						/>
						<input
							type="hidden"
							name="paymentModes"
							value={
								cashfreeData.payment_mode
									? cashfreeData.payment_mode
									: ""
							}
						/>
						<input
							type="hidden"
							name="returnUrl"
							value={
								cashfreeData.returnUrl
									? cashfreeData.returnUrl
									: ""
							}
						/>
						<input
							type="hidden"
							name="notifyUrl"
							value={
								cashfreeData.notifyUrl
									? cashfreeData.notifyUrl
									: ""
							}
						/>
						<input
							type="hidden"
							name="signature"
							value={
								cashfreeData.signature
									? cashfreeData.signature
									: ""
							}
						/>
					</form>

					<div className={classes.footerSection}>
						<div className={classes.footerChildMargin}></div>
						<p>
							<a href="/About" className={classes.footerItem}>
								ABOUT US
							</a>
						</p>
						<p>
							<a
								href="/PrivacyPolicy"
								className={classes.footerItem}
							>
								PRIVACY POLICY
							</a>
						</p>
						<p>
							<a
								href="/TermsAndConditions"
								className={classes.footerItem}
							>
								TERMS AND CONDITIONS
							</a>
						</p>
						<p>
							<a
								href="/RefundsCancellationsPolicy"
								className={classes.footerItem}
							>
								REFUND AND CANCELLATION POLICY
							</a>
						</p>
					</div>
				</Container>
			) : (
				<div></div>
			)}
		</React.Fragment>
	);
}

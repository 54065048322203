import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import * as Constants from "utils/constants";

import LinkMat from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Chip from '@material-ui/core/Chip';
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import {galleryItems, exploreData, featuredData, dealsData} from '../home/tiledata';

import CircularProgress from '@material-ui/core/CircularProgress';

// const getConfigurableProps = () => ({
//     showArrows: boolean('showArrows', true, tooglesGroupId),
//     showStatus: boolean('showStatus', true, tooglesGroupId),
//     showIndicators: boolean('showIndicators', true, tooglesGroupId),
//     infiniteLoop: boolean('infiniteLoop', true, tooglesGroupId),
//     showThumbs: boolean('showThumbs', true, tooglesGroupId),
//     useKeyboardArrows: boolean('useKeyboardArrows', true, tooglesGroupId),
//     autoPlay: boolean('autoPlay', true, tooglesGroupId),
//     stopOnHover: boolean('stopOnHover', true, tooglesGroupId),
//     swipeable: boolean('swipeable', true, tooglesGroupId),
//     dynamicHeight: boolean('dynamicHeight', true, tooglesGroupId),
//     emulateTouch: boolean('emulateTouch', true, tooglesGroupId),
//     thumbWidth: number('thumbWidth', 100, {}, valuesGroupId),
//     selectedItem: number('selectedItem', 0, {}, valuesGroupId),
//     interval: number('interval', 3000, {}, valuesGroupId),
//     transitionTime: number('transitionTime', 150, {}, valuesGroupId),
//     swipeScrollTolerance: number('swipeScrollTolerance', 5, {}, valuesGroupId),
// });


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
  },
  media: {
    height: 180,
  },
  carousel: {
    padding: '10px 20px',
    marginTop: theme.spacing(3),
    "& div.carousel-slider" : {
      borderRadius: '20px',
    },
    "& .control-dots" : {
      textAlign: 'right'
    },
    "& img" : {
      borderRadius: '20px',
    }
  },
  carouselContent:{
    marginTop: -theme.spacing(8),
    marginLeft: theme.spacing(2),
    textAlign: 'left',
    "& h3": {
      textTransform: 'uppercase',
      marginBottom: '16px'
    },
    "& p": {
      fontSize: '1 rem',
      fontFamily: 'gilroy-bold',
      maxWidth: '70%',
      marginBottom: '10px'
    }
  },
  goto: {
    fontSize: '2rem',
  },
  exploreLink: {
    fontSize: '1.6rem',
    position: "absolute",
    right: "15px",
    top: '15px',
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(10),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontFamily: 'sourcesanspro-Regular',
    textTransform: 'uppercase',
    fontSize: '0.85rem',
    letterSpacing: '0.1rem'
  },
  gridListTile: {
    borderRadius: '30px',
    marginRight: theme.spacing(3),
    height: '150px',
    minWidth: '130px',
    fontFamily: 'gilroy-extrabold',
  },
  gridListFeaturedTile: {
    borderRadius: '30px',
    marginRight: theme.spacing(3),
    height: '150px !important',
    minWidth: '150px',
    padding: '0 !important',
    "& img": {
      height: 'auto',
      borderRadius: '20px',
      maxWidth: '100%'
    }
  },
  featureBar: {
    position: 'absolute',
    bottom: '0',
    display: 'flex',
    width: '100%',
    background: 'rgba(0,0,0,0.4)',
    padding: '7px 10px',
    justifyContent: 'space-between',
  },
  featureInfo: {
    margin:'0',
    fontSize: '1rem',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'gilroy-extrabold',
    "& span": {
      fontSize: '0.6rem'
    },
    "& label": {
      fontSize: '0.8rem',
      fontFamily: 'gilroy-regular',
    }
  },
  addShop: {
    padding: '0',
    minWidth: 'auto',
    fontSize: '2rem'
  },
  gridListTileExplore: {
    borderRadius: '30px',
    marginRight: theme.spacing(3),
    height: '150px !important',
    marginTop: '0px',
    minWidth: '150px',
    transition: 'all ease 0.3s',
    '&:active': {
      marginTop: '15px',
    }
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
  },
  titleBar: {
    background:'none',
    textAlign: 'center'
  },
  exploretitle: {
    color: 'white',
    fontWeight: 'bold',
    margin: '0 0 5px 0'
  },
  exploretitleBar: {
    background:'none',
    position:'inherit',
    display:'block'
  },
  exploreSubtitle: {
    textOverflow: 'initial',
    whiteSpace: 'normal',
  },
  dealsImg: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    "& img": {
      width: '100px',
      height: '100px',
      borderRadius: '50%'
    }
  },
  exploreIcon: {
    fontSize: '1.6rem',
    margin: '30px 0 0 15px'
  },
  chips: {
    color: '#ffffff',
    border: '1px solid #ffffff',
    marginRight: '10px',
    "& span.icon-Icons_Rupee": {
      fontSize: '0.75rem'
    },
    "&.selected" : {
      border: '1px solid #E04420',
      backgroundColor: '#E04420'
    }
  },
  topupButton: {
    minWidth: '30px',
    padding: '10px',
    borderRadius: '25px',
    float:'right',
    marginTop: -theme.spacing(1),
    fontSize:'1.5rem'
  },
  progressbar: {
    margin: '40vh 0 0 45vw',
    width: '10vw'
  },
  redeem: {
    background: '#E04420',
    padding: '2px 3px',
    margin: '5px 0',
    color: 'white'
  },
  step: {
    paddingBottom: '10px',
    borderBottom: '1px solid rgba(255,255,255,0.15)'
  },
}));

export default function Homepage(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [topupAmounts, setTopupAmounts] = React.useState([]);

    React.useEffect(() => {
      axios.get(Constants.API_URL+"/api/topup/amounts").then((response) => {
        setTopupAmounts(response.data.allAmounts);
        setLoading(false);
      });
    }, []);

    const handleChipClick = (event) => {
      console.log(event.target.parentNode);
    }

      return (
        <React.Fragment>
          <div>
          {loading ? (
            <Box class="loader loader-quart"></Box>
          ) : (
            <Box>
              {(galleryItems.length > 0) ? (
                <Carousel
                  showArrows={false}
                  showStatus={false}
                  showThumbs={false}
                  interval={5000}
                  autoPlay={true}
                  infiniteLoop={true}
                  className={classes.carousel +" "+classes.step}
                >
                  {galleryItems.map((items, index) => (
                      <div key={index}>
                        <img
                          src={"./assets/images/"+items.image}
                        />
                        <div className={classes.carouselContent}>
                          <Typography className={classes.sectionTitle} component="h3">
                            {items.title}
                          </Typography>
                          <Typography component="p">
                            {items.short_desc}
                          </Typography>
                          <Link to={items.url} className={classes.goto}>
                            <span class="icon-Icons_View-More"></span>
                          </Link>
                        </div>
                      </div>
                  ))}
                </Carousel>
              ) : "" }
              <Container className={classes.cardGrid} maxWidth="md" c>
                  <Grid container spacing={4}  >

                  {(props.currUser && props.currUser.default_tag) ? (
                    <Grid item xs={12} className={classes.step}>
                      <Typography className={classes.sectionTitle} component="p">
                        QUICK TOP-UP ({props.currUser.default_tag})
                      </Typography>
                      <Box style={{"display":"flex","overflow-y":"auto"}}>
                        {topupAmounts.map((items, index) => (
                          <Link key={index} to={"/topup?tag="+props.currUser.default_tag+"&amount="+items}>
                            <Chip
                              variant="outlined"
                              size="medium"
                              key={index}
                              onClick={handleChipClick}
                              label={
                                <Typography component="span">
                                  <span class='icon-Icons_Rupee'></span>
                                  {items}
                                </Typography>
                              }
                              className={classes.chips}
                            />
                          </Link>
                        ))}
                      </Box>

                    </Grid>
                  ): "" }

                    <Grid item xs={12} className={classes.step}>
                      <Divider className={classes.divider} />
                      <Typography className={classes.sectionTitle} component="p">
                        TAP TO EXPLORE
                      </Typography>
                      <GridList className={classes.gridList} cols={2.7}>
                        {exploreData.map((tile) => (
                          <GridListTile key={tile.icon} className={classes.gridListTileExplore} style={{backgroundColor:tile.bkColor}}>

                            <Link to={tile.link} style={{color:'inherit'}}>
                              <Typography component="p" className={`${classes.goto} ${classes.exploreLink}`}>
                                <span class="icon-Icons_Go-To"></span>
                              </Typography>
                              <Box className={classes.exploreIcon}>
                                <i class={tile.icon}></i>
                              </Box>
                              <GridListTileBar
                                title={tile.title}
                                subtitle={<span>{tile.desc}</span>}
                                titlePosition="bottom"
                                classes={{
                                  root: classes.exploretitleBar,
                                  title: classes.exploretitle,
                                  subtitle: classes.exploreSubtitle,
                                }}
                              />
                            </Link>
                          </GridListTile>
                        ))}
                      </GridList>
                    </Grid>
                    {(featuredData.length > 0) ? (
                    <Grid item xs={12}>
                      <Divider className={classes.divider} />
                      <Typography className={classes.sectionTitle} component="p">
                        FEATURED PRODUCTS
                      </Typography>
                      <GridList className={classes.gridList} cols={2.7}>
                        {featuredData.map((tile) => (
                          <GridListTile key={tile.id} className={classes.gridListFeaturedTile}>
                            <Link to={"/shop/details/"+tile.id} style={{color:'inherit'}}>
                              <img src={tile.shop_image} alt={tile.shop_name} />
                              <Box className={classes.featureBar}>
                                <Box className={classes.featureInfo}>
                                  {tile.shop_name}
                                  <span class='icon-Icons_Rupee'><label>{tile.shop_price}</label></span>
                                </Box>
                                <Button className={classes.addShop}>
                                  <span class="icon-Icons_Go-To"></span>
                                </Button>
                              </Box>
                            </Link>
                          </GridListTile>
                        ))}
                      </GridList>
                    </Grid>
                    ) : ""}
                    {dealsData.length > 0 ? (
                      <Grid item xs={12}>
                        <Box pb={4}>
                          <Divider className={classes.divider} />
                          <Typography className={classes.sectionTitle} component="p">
                            LATEST DEALS
                          </Typography>
                          <GridList className={classes.gridList} cols={3.5}>
                          {dealsData.map((tile, index) => (
                            <GridListTile key={index} className={classes.gridListTile} style={{backgroundColor:"#323031"}}>

                              <Box className={classes.dealsImg}>
                                <img src={tile.deal_image} />
                              </Box>
                              <GridListTileBar
                                title={tile.deal_name}
                                subtitle={<Link to={"/deals/"+tile.id}><Button className={classes.redeem}>REDEEM</Button></Link>}
                                titlePosition="bottom"
                                classes={{
                                  root: classes.titleBar,
                                  title: classes.title,
                                }}
                              />
                            </GridListTile>
                          ))}
                        </GridList>
                        </Box>
                      </Grid>
                    ) : ""}

                  </Grid>
              </Container>
            </Box>
          )}
          </div>

        </React.Fragment>
      )
}

import React from 'react';
import { useHistory, useParams } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import { loadCSS } from  'fg-loadcss';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import axios from "axios";
import * as Constants from "utils/constants";

import AuthService from "../../../../services/auth.service"; 
import smsClient from "../../../../services/auth.otpservice"; 
import {useForm} from 'react-hook-form';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  otpinput: {
    width: '3em !important',
    height: '3em !important',
    margin: '0 5px',
    fontSize: '1rem',  
  }
}));

export default function SignUp(props) {
  const classes = useStyles();
  const [OTP, setOTP] = React.useState("");
  const { register, handleSubmit, errors, watch } = useForm();
  const [open, setOpen] = React.useState(false);
  const [resMessage, setResponseMessage] = React.useState();
  const [snackSeverity, setSnackSeverity] = React.useState("error");
  let history = useHistory();
  let { id } = useParams();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  
  const submitOTP = () => {
    console.log(OTP);
    smsClient.validateOTP(id, OTP).then(
      (response) => {
        if(response.mobile) {
          setOpen(true);
          setSnackSeverity("success");
          setResponseMessage("OTP Validated and User Verified");
          setTimeout(function() { 
            props.onChange();
            if(response.default_tag == null) history.push("/tags/"+response.id+"?open=true");
            else history.push("/");
          }.bind(this), 2000)
        }else {
          setOpen(true);
          setResponseMessage(response.message);
        }
      },
      (error) => {
        const resError =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          setOpen(true);
          setResponseMessage(resError);
         console.log(resError); 
    });
    return false;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          VERIFY OTP
        </Typography>

        <Box m={2}>
          <b>{id}</b>
        </Box>

        <form className={classes.form} onSubmit={handleSubmit(submitOTP)} noValidate>
          <hr/><br></br>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={snackSeverity}>
              {resMessage}
            </Alert>
          </Snackbar>
          <Grid container spacing={2} justify="center" alignItems="flex-start" direction="row">
            <Box mt={2}>
            <OTPInput
                value={OTP}
                onChange={setOTP}
                inputClassName={classes.otpinput}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
                />
                {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
            </Box>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={OTP.length == 4 ? false : true}
            className={classes.submit}
          >
            VERIFY
          </Button>
          {/* <Grid container justify="flex-end">
            <Grid item>
              <Link href="/modules/development/user/signin" variant="body2">
                Resend Code
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={2}>
        &nbsp;
      </Box>
    </Container>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import blueGrey from '@material-ui/core/colors/grey';

const accent = blueGrey[900];

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  bottomDiv: {
    position: 'fixed',
    width: '100vw',
    bottom: '0px',
    padding: '15px 5vw',
    zIndex: '999'
  },
  stickToBottom: {
    width: '100%',
    height: '70px',
    backgroundColor: 'white',
    borderRadius: '40px',
    border: '3px solid #000000',
    boxShadow: '0 8px 6px -6px black',
    padding: '0 16px',
    "& a": {
      color: "#000",
      fontSize: '1.25rem',
      minWidth: '60px',
      padding: '6px 0px 8px',
    },
    "& a.Mui-selected": {
      color: "red"
    },
  },
  selected: {}
}));


export default function Footer(props) {
  const classes = useStyles();
  const pathname = window.location.pathname;
  const [value, setValue] = React.useState(pathname);

  return (

    <React.Fragment>

      <CssBaseline />
      {/* Footer */}
        <div className={classes.bottomDiv}>
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            showLabels
            className={classes.stickToBottom}
          >
            <BottomNavigationAction classes={classes.footerIcons}  component={Link} to="/transactions" value="/transactions" label="Transactions" icon={<i class="icon-Icons_Transactions"></i>} />
            <BottomNavigationAction classes={classes.footerIcons} component={Link} to="/topup" value="/topup" label="Top-Up" icon={<i class="icon-Icons_Top-up"></i>} />
            <BottomNavigationAction classes={classes.footerIcons}  component={Link} to="/merchants" value="/merchants" label="Merchants" icon={<i class="icon-Icons_Merchants"></i>} />
            <BottomNavigationAction classes={classes.footerIcons}  component={Link} to="/deals" value="/deals" label="Deals" icon={<i class="icon-Icons_Deals"></i>} />
          </BottomNavigation>
        </div>
      {/* End footer */}
    </React.Fragment>
  );
}

import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  faq: {
    margin: '10px auto',
    width: '90vw',

  },
  title: {
    padding: '16px 0'
  },
  addressText: {
    marginTop: '30px',
    marginBottom:'100px'
  },
}));

export default function RefundsCancellationsPolicy()  {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <React.Fragment>
      <Box className={classes.faq}>
        <Typography  className={classes.title} variant="h3" component="h4">
          REFUND AND CANCELLATION POLICY
        </Typography>
        <Typography className={classes.addressText}>
         <b>1.	GENERAL</b>
         <p> 1.1	This refund and cancellation policy (<b>“Policy”</b>) explains our refund policy for any transactions made through the TapX website (the <b>“Website”</b>) operation by AtomX Corporation Private Limited (<b>“AtomX”</b>) as well as our cancellation policy for terminating your TapX account (<b>“Account”</b>)</p>

         <p> 1.2	AtomX may change or amend this Policy from time to time to incorporate necessary future changes. AtomX Customers (<b>“Customer(s)”</b>) shall be expected to check this policy from time to time for updates/modifications.</p>

          <p>1.3	AtomX is committed to providing services in a timely manner. Please read this Policy  to understand our cancellation and refund policies .</p>

          <b>2.	CANCELLATION POLICY</b>

          <p>2.1	An order placed on the Website may be cancelled or amended, provided that the order has not been shipped, and any amount already paid will be fully refunded within the refund window stated below.</p>

          <p>2.2	All cancellation requests may be submitted via email or through our online form. Upon cancellation, Customer(s) will receive an email stating that the cancellation request is being processed. Once the request has been processed, Customer(s) will get a follow up email, stating that the order has been cancelled.</p>

          <p>2.3	If you have any questions or concerns about our cancellation policy, please contact us at hello@atomx.in</p>

          <b>3.	RETURN POLICY</b>

          <p>3.1.	To return your product, please contact us at hello@atomx.in. To process your return, we require a receipt or proof of purchase.</p>

          <p>3.2.	Products ordered through the Website (<b>“Products”</b>)are returnable within 15 days if they have been received by you in a condition that is physically damaged, has missing parts or accessories, defective or different from their description on the product detail page on the Website. In order to process your return, we will need your order number, description (including photos if available), and any other information about your use of the product that may help us understand the potential defect.</p>

          <p>3.3.	Any return will be processed only when the following criteria has been established:</p>

          <p>3.3.1	that the Product was not damaged while in your possession;</p>
          <p>3.3.2	that the Product is the same that was delivered to you;</p>
          <p>3.3.3	that the Product is returned in the original condition and packaging along with the box, MRP tag intact, user manual, warranty card and all the accessories therein.</p>

          <p>3.4.	In the event you have begun using the Product and any personal information has been stored on it, the onus of removing all such personal information is on the user, and AtomX shall have no obligation or liability for any misuse or usage of such information.</p>

          <p>3.5.	Please note where Products have been delivered, they shall not be eligible for return in cases of a buyer having changed their mind or having ordered the incorrect product.</p>

          <b>4.	REFUND POLICY</b>

          <p>4.1	Upon request of a refund for a transaction made the Website,  Customer(s) will receive an email detailing the refund request.</p>

          <p>4.2	All refund amounts will be processed to the original form of payment.</p>

          <p>4.3	Please note that all refund requests will be processed subject to the processing fee. All refund requests shall be processed with seven (7) business days.</p>

          <p>4.4	AtomX  shall not be liable to process a refund if delay or service disruption occur owing to a third party’s actions or omissions.</p>

          <p>4.5	If you have any questions or concerns about our refund policy, please contact us at hello@atomx.in</p>

          <b>5	SHIPPING POLICY</b>

          <p>5.1	We ship in Goa within 3 - 7 working days</p>

        </Typography>
      </Box>
    </React.Fragment>
  );
}

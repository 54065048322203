import React from 'react';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import PropTypes from 'prop-types';
import { loadCSS } from  'fg-loadcss';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinkMat from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import { purple, green } from '@material-ui/core/colors';

import AuthService from "../../../../services/auth.service"; 
import smsClient from "../../../../services/auth.otpservice"; 
import {Controller, useForm} from 'react-hook-form';
import * as Constants from "utils/constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const themeX = createMuiTheme({
  overrides: {
    Mui: {
      focused: {
        borderBottom: '1px solid #ffffff !important',
        color: '#ffffff !important'
      },
    },
    MuiInput: {
      underline: {
        "&:before" : {
          borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
        },
        "&:after" : {
          borderBottom: '1px solid #ffffff'
        }
      },
    },
    MuiInputBase: {
      input: {
        color: "#fff",
      },
      focused: {
        color: "#fff"
      },
    },
    MuiInputLabel: {
      formControl: {
        color: "#fff",
        fontSize: "0.75rem"
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  main:{
    position: 'absolute',
    width: '100vw',
    minHeight: '100vh',
    marginTop: '-50px',
    zIndex: '1111',
    background: 'url("/assets/images/Links_Login.jpg") black no-repeat',
    backgroundSize: 'cover',
    padding: '0 30px',
  },
  input: {
    color: "white"
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'scroll'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
    color: 'white'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: '20px',
    padding: '5px 30px',
  },
  drawerFooter: {
    textAlign: 'center',
    fontSize: '0.9rem'
  },
  topNavigation: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: '9px',
    "& button": {
      padding: '0',
      position: 'absolute',
      right: '0', 
    }
  },
  headline: {
    fontFamily: 'gilroy-extrabold',
    alignSelf: 'flex-start',
    marginBottom: '20px',
  },
  tabDivider: {
    background: 'white',
    height: '14px',
    marginTop: '24px',
  },
  tabActive: {
    color: 'rgb(224, 68, 30)',
    borderTop: '2px solid rgb(224, 68, 30)',
  },
  login: {
    width: '49%',
    padding: '20px 0 0 0',
    letterSpacing: '0.1em',
    textDecoration: 'none',
  },
  register: {
    width: '49%',
    padding: '20px 0 0 0',
    textAlign: 'right',
    color: 'white',
    letterSpacing: '0.1em',
    textDecoration: 'none'
  },
  adornment: {
    "& p" : {
      color:'white',
      fontSize: '0.75rem',
      fontFamily: 'gilroy-bold'
    }
  }
}));

export default function SignIn(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [showAdorment, setShowAdorment] = React.useState(false);
  const {register, errors, control, handleSubmit} = useForm({ mode: 'onBlur'});
  const [open, setOpen] = React.useState(false);
  const [resMessage, setResponseMessage] = React.useState();
  let history = useHistory();

  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleOTP = () => {
    const mobile = document.getElementsByName("mobile")[0].value;
    smsClient.sendVerificationMessage(mobile).then(() => {
      if(mobile != undefined) {
        history.push("/verifyotp/"+mobile);
      }
    },
    (error) => {
      const resError =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        setOpen(true);
        setResponseMessage(resError);
        console.log(resMessage); 
    });
  }

  const handleLogin = (data) => {
    //e.preventDefault();
    const signinData = JSON.parse(JSON.stringify(data));
    const mobile = signinData.mobile,
    password = signinData.password;

    AuthService.login(mobile, password).then(
      (response) => {
        console.log(response);
        props.onChange();
        response.default_tag==null ? history.push("/tags/"+response.id+"?open=true") : history.push("/");
      },
      (error) => {
        const resError =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          setOpen(true);
          setResponseMessage(resError);
          console.log(resMessage); 
      }
    );

  };

  return (
    <MuiThemeProvider theme={themeX}>
    <CssBaseline />
    <Container component="main" className={classes.main} maxWidth="xs">
      <Box className={classes.topNavigation}>
        <Link to="/">
          <img src="/assets/images/tapx-logo-white.png" width="80" />
        </Link>
        <Button onClick={() => history.push("/")}>
          <h2><i class="icon-Icons_Close"></i></h2>
        </Button>
      </Box>
      <div className={classes.paper}>
        <Typography className={classes.headline} component="h2">
          Welcome to the new <br/>
          age of contactless<br/>
          payments.
        </Typography>
        <Grid container alignItems="center">
          <Link className={`${classes.tabActive} ${classes.login}`} to="/signin">
            LOGIN
          </Link>
          <Divider className={classes.tabDivider} orientation="vertical" flexItem />
          <Link className={`${classes.register}`} to="/signup">
            REGISTER
          </Link>
        </Grid>
        <form className={classes.form} onSubmit={handleSubmit(handleLogin)} noValidate>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {resMessage}
            </Alert>
          </Snackbar>
            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="EMAIL ADDRESS"
              name="email"
              autoComplete="email"
              error={!!errors.email}
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              })}
              helperText={errors.email && "Enter a valid Email Address"}
            /> */}
            <TextField
              margin="normal"
              required
              fullWidth
              id="mobile"
              label="PHONE NUMBER"
              type="number"
              name="mobile"
              inputMode="numeric"
              onInput={(e)=>{ 
                if(e.target.value.length == 10) {
                  setShowAdorment(true);
                }
                else {
                  setShowAdorment(false);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment 
                    className={classes.adornment} 
                    onClick={handleOTP}
                    position="end">
                    {showAdorment ? "Login with OTP" : ""}
                  </InputAdornment>
                ),
              }}
              error={!!errors.mobile}
              inputRef={register({ 
                required: "Mobile Number is required.",
                pattern: {
                  value: /^[0-9\b]+$/,
                  message: "Please enter numbers only."
                },
                maxLength: {
                  value: 10,
                  message: "Mobile Number should be 10 Digits"
                },
                minLength: {
                  value: 10,
                  message: "Mobile Number should be 10 Digits"
                }
              })}
              autoComplete="mobile"
              helperText={errors.mobile && errors.mobile.message}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="PASSWORD"
              type="password"
              id="password"
              autoComplete="password"
              error={!!errors.password}
              inputRef={register({ 
                required: "Password is required.",
                minLength: {
                  value: 8,
                  message: "Password should be atleast 8 Characters "
                }
              })}
              helperText={errors.password && errors.password.message}
            />
          <Grid container justify="center">
              <Button
                type="submit"
                variant="contained"
                color="default"
                className={classes.submit}
              >
                Sign In
              </Button>
          </Grid>
        </form>


        <Typography component="p">
          OR
        </Typography>
        <Typography component="p">
          <br/>
          LOGIN WITH SOCIAL MEDIA
        </Typography>

        <Box m="1rem">
          <LinkMat href={Constants.API_URL+"/auth/google"}>
            <Icon className="fab fa-google-plus-square" style={{ fontSize: 40 }} color="secondary" />
          </LinkMat>
          <LinkMat href={Constants.API_URL+"/auth/facebook"}>
            <Icon className="fab fa-facebook-square" style={{ fontSize: 40 }} color="primary" />
          </LinkMat>
        </Box>
      </div>
      <Box mt={4} mb={2}>
        <Typography className={classes.drawerFooter} component="p">
          &copy; 2020 Copyright TapX. All rights reserved.
        </Typography>
      </Box>
    </Container>
  
    </MuiThemeProvider>
    
  );
}

import axios from "axios";
import * as Constants from "utils/constants";

export let merchantHome = []

axios.get(Constants.API_URL+"/api/merchants").then((response) => {
    merchantHome = response.data.items;
    // console.log(merchantHome);
});


// export let merchantHomeCategories = []
//
// axios.get(Constants.API_URL+"/api/merchant/categories").then((response) => {
//     merchantHomeCategories = response.data.items;
//     console.log(merchantHomeCategories);
// });

export let merchantList = []

axios.get(Constants.API_URL+"/api/merchant/list").then((response) => {
    merchantList = response.data.merchants;
    // console.log(merchantList);
});


export default {
    // merchantHome, merchantHomeCategories,
    merchantHome, merchantList,

}

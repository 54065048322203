import React from "react";
import {
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  faq: {
    margin: '10px auto',
    width: '90vw',
  },
  title: {
    padding: '16px 0'
  },
  addressText: {
    marginTop: '30px',
    marginBottom: '100px'
  },
}));

export default function About()  {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <React.Fragment>
      <Box className={classes.faq}>
        <Typography  className={classes.title} variant="h3" component="h4">
          About Us
        </Typography>
        <Typography className={classes.addressText}>
          <b>About TapX</b>
          <p><b>TapX</b> is a brand new lifestyle discovery app combined with proprietary NFC stored value services, which offer Tap2Pay contactless payments, access management (venue ticketing), and reward gamification for a growing community of digital nomads and fixed venue merchants in a closed-loop environment.</p>
          <b>Why TapX ?</b>
          <p>All TapX transactions happen completely offline, which means both our patrons and our family of hand-picked merchants do not require an active internet connection to exchange digital money or goods.  This makes our transaction time less than 1 second with a 100% success rate every time. TapX is developed and managed by the wonderful team here at AtomX Corp.</p>
          <b>About AtomX Corporation</b>
          <p>AtomX is a fintech company specialising in NFC stored value payments, access control + ticketing, user management, contactless ordering, and point of sale integration for live events, concerts, music festivals, hospitals. co-living spaces, educational campuses, parking bays and hostels. AtomX is invested by BookMyShow.</p>
        </Typography>
      </Box>
    </React.Fragment>
  );
}

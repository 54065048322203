import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import { Box, Paper, Grid, Typography, Button } from "@material-ui/core/";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import cardsService from "../../../services/cards.service";
import AddCard from "./addcard";
import { Controller, useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
	formControl: {
		minWidth: "100%",
		color: "#ffffff",
	},
	tag_number: {
		fontFamily: "gilroy-bold",
		letterSpacing: "0.1rem",
		"& label": {
			color: "white",
		},
		"& .MuiFormLabel-root.Mui-focused": {
			color: "white",
		},
		"& .MuiInputBase-root": {
			fontSize: "1.5rem",
		},
		"& input": {
			color: "white",
		},
		"& .MuiInput-underline:before": {
			borderBottom: "1px solid #ffffff",
		},
		"& .MuiInput-underline:after": {
			borderBottom: "1px solid #ffffff",
		},
	},
	others: {
		maxWidth: "100%",
	},
	amlFormItem: {
		width: "75%",
		// margin: '0 auto',
	},
	gridList: {
		flexWrap: "nowrap",
		// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
		transform: "translateZ(0)",
		margin: "10px -2px !important",
		color: "#ffffff",
	},
	gridListTile: {
		borderRadius: "4vw",
		marginRight: theme.spacing(2),
		padding: "2vw !important",
		backgroundSize: "cover !important",
		width: "37vw !important",
		height: "37vw !important",
		fontFamily: "gilroy-bold",
		background: "rgba(0,0,0,0.5)",
		"&.selected": {
			border: "2px solid #E04420",
		},
	},
	tagListId: {
		fontSize: "0.5rem",
		letterSpacing: "0.1rem",
		marginTop: "8vw",
	},
	tagListTitle: {
		fontSize: "1rem",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	tagListBottom: {
		display: "flex",
		position: "absolute",
		bottom: "5px",
		width: "100%",
	},
	tagListBalance: {
		fontSize: "0.5rem",
		"& span": {
			fontSize: "0.6rem",
		},
		"& label": {
			fontSize: "0.8rem",
			fontFamily: "gilroy-regular",
		},
	},
}));

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function SelectCard(props) {
	const classes = useStyles();
	let query = useQuery();
	const { register, errors, control, handleSubmit } = useForm({
		mode: "onChange",
	});
	// const [tag_number, setTagNumber] = React.useState((query.get("tag")) != undefined ? query.get("tag") : props.currUser.default_tag);
	const [tag_number, setTagNumber] = React.useState(
		query.get("tag") != undefined ? query.get("tag") : ""
	);
	const tag_fullname =
		query.get("fullname") != undefined ? query.get("fullname") : "";
	const tag_email = query.get("email") != undefined ? query.get("email") : "";
	const tag_mobile =
		query.get("mobile") != undefined ? query.get("mobile") : "";

	const [allTags, setAllTags] = React.useState([]);

	const handleChange = (event) => {
		$(".MuiGridListTile-root").removeClass("selected");
		event.currentTarget.classList.add("selected");
		$("#tags").val(
			parseInt(event.currentTarget.getAttribute("data-tagId"))
		);
	};

	const getTagsInfo = () => {
		cardsService.cardsInfo(props.currUser.id).then((response) => {
			setAllTags(response.TagData);
		});
	};

	const handleTagChange = (value) => {
		setTagNumber(value);
	};

	React.useEffect(() => {
		if (allTags.length < 1 && props.currUser.default_tag != null) {
			getTagsInfo();
		}
	}, []);

	return (
		<React.Fragment>
			<Grid container alignItems="flex-start" direction="row">
				<Grid item className={classes.others}>
					<GridList className={classes.gridList} cols={2.5}>
						{allTags.map((tags, index) =>
							tags.is_blocked ? (
								""
							) : (
								<GridListTile
									key={tags.tag_id}
									className={`
                                ${classes.gridListTile}
                                ${
									tag_number == tags.tag_number
										? "selected"
										: ""
								}
                            `}
									style={{
										background:
											"linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('" +
											tags.tag_img +
											"') center center no-repeat",
										//border: (props.currUser.default_tag == tags.tag_number) ? "2px solid #E04420" : "",
									}}
									onClick={handleChange}
									data-tagId={tags.tag_number}
								>
									<Box className={classes.tagListId}>
										TAG ID {tags.tag_number}
									</Box>
									<Box className={classes.tagListTitle}>
										{tags.tag_name}
									</Box>
									<Box className={classes.tagListBottom}>
										<Typography
											component="div"
											className={classes.tagListBalance}
										>
											<span class="icon-Icons_Rupee">
												<label>
													{tags.tag_balance}
												</label>
											</span>
										</Typography>
									</Box>
								</GridListTile>
							)
						)}
					</GridList>
				</Grid>
				<Grid item className={classes.amlFormItem}>
					<FormControl className={classes.formControl}>
						{props.currUser.id != undefined ? (
							props.currUser.default_tag != null ? (
								<React.Fragment>
									OR <br />
								</React.Fragment>
							) : (
								""
							)
						) : (
							<React.Fragment>
								<TextField
									id="fullname"
									label={ query.get("eventId") == 973 ? "Student Full Name" : "Full Name" }
									name="fullname"
									required
									// error={!!errors.fullname}
									error={props.isNameInvalid}
									className={classes.tag_number}
									defaultValue={
										tag_fullname != "" ? tag_fullname : ""
									}
									inputRef={register({
										required: "Full Name is required.",
										pattern: {
											value: /^[a-zA-Z\s]+$/,
											message: "Text only.",
										},
										maxLength: {
											value: 50,
											message:
												"Full Name upto 50 characters only.",
										},
									})}
									onChange={() =>
										props.setIsNameInvalid(false)
									}
									helperText={
										(errors.fullname &&
											errors.fullname.message) ||
										(props.isNameInvalid &&
											"Full Name is required.")
									}
								/>
								<TextField
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
									defaultValue={
										tag_email != "" ? tag_email : ""
									}
									// error={!!errors.email}
									error={props.isEmailInvalid}
									className={classes.tag_number}
									inputRef={register({
										required: true,
										pattern:
											/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									})}
									onChange={() =>
										props.setIsEmailInvalid(false)
									}
									helperText={
										(errors.email &&
											"Enter a valid Email Address") ||
										(props.isEmailInvalid &&
											"Email is invalid")
									}
								/>
								<TextField
									name="mobile"
									label="Mobile"
									type="number"
									inputMode="numeric"
									id="mobile"
									defaultValue={
										tag_mobile != "" ? tag_mobile : ""
									}
									inputProps={{
										maxLength: 10,
									}}
									// error={!!errors.mobile}
									error={props.isMobileInvalid}
									className={classes.tag_number}
									inputRef={register({
										required: "Mobile Number is required.",
										pattern: {
											value: /^[0-9\b]+$/,
											message:
												"Please enter numbers only.",
										},
										maxLength: {
											value: 10,
											message:
												"Mobile Number should be 10 Digits",
										},
										minLength: {
											value: 10,
											message:
												"Mobile Number should be 10 Digits",
										},
									})}
									onChange={() =>
										props.setIsMobileInvalid(false)
									}
									autoComplete="mobile"
									helperText={
										(errors.mobile &&
											errors.mobile.message) ||
										(props.isMobileInvalid &&
											"Mobile No. is invalid")
									}
								/>
							</React.Fragment>
						)}

						<TextField
							id="tags"
							label="Enter TAG ID"
							name="tags"
							type="number"
							inputMode="numeric"
							defaultValue={tag_number != "" ? tag_number : ""}
							className={classes.tag_number}
							disabled={tag_number !== "" && !errors.tags}
							// error={!!errors.tags}
							error={props.isTagIdInvalid}
							onChange={(e) => handleTagChange(e.target.value)}
							inputRef={register({
								required: "Tag Number is required.",
								pattern: {
									value: /^[0-9\b]+$/,
									message: "Please enter numbers only.",
								},
								maxLength: {
									value: 10,
									message:
										"Tag Number should be 8 or 10 Digits",
								},
								minLength: {
									value: 8,
									message:
										"Tag Number should be 8 or 10Digits",
								},
							})}
							inputProps={{
								maxLength: 10,
							}}
							onChange={() => props.setIsTagIdInvalid(false)}
							helperText={
								(errors.tags && errors.tags.message) ||
								(props.isTagIdInvalid && "Tag Id is invalid")
							}
						/>
					</FormControl>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Button, Avatar, Typography } from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LinkMat from '@material-ui/core/Link';
import AuthService from "../../../../services/auth.service";
import {Link} from "react-router-dom";



const useStyles = makeStyles((theme) => ({
  menuLink: {
      display: 'flex',
      color: 'white',
      width: '100%'
  },
  listItem : {
    borderBottom: '1px solid rgba(255,255,255,0.2)',
    padding: '13px 5px',
    letterSpacing:"1px"
  },
  listItemFont: {
    fontFamily: "gilroy-regular",
    minWidth: 'auto',
    fontSize: '0.8rem',
  }
}));

export default function Profile(props)  {
  const classes = useStyles();
  const logOut = () => {
    AuthService.logout();
  };

  return (
    <React.Fragment>
      <Container>
        <Box pb={10}>
          <Grid className="profile_grid" container direction="row" justify="center" alignItems="center">
            <Avatar className="avatar">VM</Avatar>
            <Typography variant="p" component="p">
                <span style={{fontSize: "1rem",letterSpacing:"2px"}}>{props.currUser.name}</span>
            </Typography>
            <Button>
              <span style={{fontSize: ".8rem",letterSpacing:"2px"}}>EDIT PROFILE</span>
            </Button>
          </Grid>

          <List dense>
            <ListItem button className={classes.listItem} component={Link} to={"/tags/"+props.currUser.id}>
                <ListItemText><span className={classes.listItemFont}>MY TAGS</span></ListItemText>
                <ListItemIcon style={{ minWidth: 'auto' }}>
                  <ArrowForwardIosIcon style={{ color: '#747474', fontSize: '1rem' }}/>
                </ListItemIcon>
            </ListItem>
            <ListItem button className={classes.listItem} component={Link} to={"/"+props.currUser.id+"/tickets"} >
                <ListItemText><span className={classes.listItemFont}>MY TICKETS</span></ListItemText>
                <ListItemIcon style={{ minWidth: 'auto' }}>
                  <ArrowForwardIosIcon style={{ color: '#747474', fontSize: '1rem' }}/>
                </ListItemIcon>
            </ListItem>
            <ListItem button className={classes.listItem} component={Link} to={"/"+props.currUser.id+"/deals"}>
                <ListItemText><span className={classes.listItemFont}>MY DEALS</span></ListItemText>
                <ListItemIcon style={{ minWidth: 'auto' }}>
                  <ArrowForwardIosIcon style={{ color: '#747474', fontSize: '1rem' }}/>
                </ListItemIcon>
            </ListItem>

            <ListItem button className={classes.listItem} component={Link} to={"/"+props.currUser.id+"/orders"}>
                <ListItemText><span className={classes.listItemFont}>MY ORDERS</span></ListItemText>
                <ListItemIcon style={{ minWidth: 'auto' }}>
                  <ArrowForwardIosIcon style={{ color: '#747474', fontSize: '1rem' }}/>
                </ListItemIcon>
            </ListItem>
            <ListItem button className={classes.listItem}>
              <ListItemText><span className={classes.listItemFont}>SETTINGS</span></ListItemText>
              <ListItemIcon style={{ minWidth: 'auto' }}>
                <ArrowForwardIosIcon style={{ color: '#747474', fontSize: '1rem' }}/>
              </ListItemIcon>
            </ListItem>
            <ListItem button className={classes.listItem}>
              <ListItemText><span className={classes.listItemFont}>HELP</span></ListItemText>
              <ListItemIcon style={{ minWidth: 'auto' }}>
                <ArrowForwardIosIcon style={{ color: '#747474', fontSize: '1rem' }}/>
              </ListItemIcon>
            </ListItem>
            <ListItem button style={{padding: '13px 5px'}}>
              <LinkMat href="/" onClick={logOut} style={{color:'red'}}>
                <ListItemText><span className={classes.listItemFont}>LOGOUT</span></ListItemText>
              </LinkMat>
            </ListItem>
          </List>
        </Box>
      </Container>
    </React.Fragment>
  );
}

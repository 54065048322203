import React from 'react';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {useHistory, useLocation} from "react-router-dom";
import PropTypes from 'prop-types';
import { loadCSS } from  'fg-loadcss';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinkMat from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import { purple, green } from '@material-ui/core/colors';


import axios from "axios";
import * as Constants from "utils/constants";
import AuthService from "../../../../services/auth.service"; 
import smsClient from "../../../../services/auth.otpservice"; 
import {Controller, useForm} from 'react-hook-form';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const themeX = createMuiTheme({
    overrides: {
      Mui: {
        focused: {
          borderBottom: '1px solid #ffffff !important',
          color: '#ffffff !important'
        },
      },
      MuiInput: {
        underline: {
          "&:before" : {
            borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
          },
          "&:after" : {
            borderBottom: '1px solid #ffffff'
          }
        },
      },
      MuiInputBase: {
        input: {
          color: "#fff",
        },
        focused: {
          color: "#fff"
        },
      },
      MuiInputLabel: {
        formControl: {
          color: "#fff",
          fontSize: "0.75rem",
          textTransform: "uppercase"
        },
      },
    },
  });

const useStyles = makeStyles((theme) => ({
  main:{
    position: 'absolute',
    width: '100vw',
    minHeight: '100vh',
    marginTop: '-50px',
    zIndex: '1111',
    background: 'url("/assets/images/Links_Register.jpg") black no-repeat',
    backgroundSize: 'cover',
    padding: '0 30px',
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'scroll'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
    color: 'white'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: '20px',
    padding: '5px 30px',
  },
  drawerFooter: {
    textAlign: 'center',
    fontSize: '0.9rem'
  },
  linkFooter: {
    color: '#ffffff',
    textAlign: 'center',
    textDecoration: 'underline',
  },
  topNavigation: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: '9px',
    "& button": {
      padding: '0',
      position: 'absolute',
      right: '0', 
    }
  },
  headline: {
    fontFamily: 'gilroy-extrabold',
    alignSelf: 'flex-start',
    marginBottom: '20px',
  },
  tabDivider: {
    background: 'white',
    height: '14px',
    marginTop: '24px',
  },
  tabActive: {
    color: 'rgb(224, 68, 30)',
    borderTop: '2px solid rgb(224, 68, 30)',
  },
  login: {
    width: '49%',
    padding: '20px 0 0 0',
    letterSpacing: '0.1em',
    color: 'white',
    textDecoration: 'none',
  },
  register: {
    width: '49%',
    padding: '20px 0 0 0',
    textAlign: 'right',
    letterSpacing: '0.1em',
    textDecoration: 'none'
  },
  tncCheckbox: {
    "& svg" : {
      border: "1px solid #fff",
      marginLeft: "2px"
    }
  }
}));

export default function SignUp(props) {
    const classes = useStyles();
    const {register, errors, control, handleSubmit} = useForm({ mode: 'onChange'});
    const [tnc, setTnc] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [resMessage, setResponseMessage] = React.useState();
    let history = useHistory();
  
    React.useEffect(() => {
      const node = loadCSS(
        'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
        document.querySelector('#font-awesome-css'),
      );
  
      return () => {
        node.parentNode.removeChild(node);
      };
    }, []);
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    const handleCheckbox = (event) => {
      setTnc(event.target.checked);
    };
  
    const submitSignUp = (data) => {
        console.log("data");
        console.log(data);

      document.getElementsByName("regButton")[0].disabled = true;
      const registerData = JSON.parse(JSON.stringify(data));
      //Get State and Country 
      axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        
        const user_id = "W-" + registerData.mobile,
        password = registerData.password != undefined ? registerData.password : "",
        gender = "",
        dob = "2020-03-04",
        city = data.city,
        state = data.region + " ("+data.region_code+")",
        country = data.country_name + " ("+data.country+")",
        verified = 0,
        last_login = new Date().toLocaleString();
  
  
        const sms = {phone: registerData.mobile, verifyCode: Math.floor(1000 + Math.random() * 9000)};
        const verifyOTP = sms.verifyCode.toString();
  
        AuthService.register(
          registerData.firstName, 
          registerData.lastName, 
          registerData.email, 
          registerData.mobile, 
          password,
          user_id,
          gender,
          dob,
          city,
          state,
          country,
          verified,
          verifyOTP,
          registerData.invitecode,
          last_login).then(
          () => {
            console.log(props);
            smsClient.sendVerificationMessage(sms.phone);
            history.push("/verifyotp/"+sms.phone);
          },
          (error) => {
            const resError =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setOpen(true);
            setResponseMessage(resError);
            document.getElementsByName("regButton")[0].disabled = false;
            console.log(resMessage); 
          }
        );
  
      }).catch((error) => {
        console.log(error);
      });
  
      
    }
  
    let query = useQuery();

    return (
    <MuiThemeProvider theme={themeX}>
      <CssBaseline />
        <Container component="main" className={classes.main} maxWidth="xs">
        <Box className={classes.topNavigation}>
            <Link to="/">
            <img src="/assets/images/tapx-logo-white.png" width="80" />
            </Link>
            <Button onClick={() => history.push("/")}>
            <h2><i class="icon-Icons_Close"></i></h2>
            </Button>
        </Box>
        <div className={classes.paper}>
            <Typography className={classes.headline} component="h2">
            Welcome to the new <br/>
            age of contactless<br/>
            payments.
            </Typography>
            <Grid container alignItems="center">
            <Link className={`${classes.login}`} to="/signin">
                LOGIN
            </Link>
            <Divider className={classes.tabDivider} orientation="vertical" flexItem />
            <Link className={`${classes.tabActive} ${classes.register}`} to="/signup">
                REGISTER
            </Link>
            </Grid>
            <form className={classes.form} onSubmit={handleSubmit(submitSignUp)} noValidate>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                {resMessage}
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                <TextField
                    autoComplete="fname"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    value={query.get("firstname")}
                    error={!!errors.firstName}
                    inputRef={register({ 
                    required: "First Name is required.",
                    pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Text only."
                    },
                    maxLength: {
                        value: 50,
                        message: "First Name upto 50 characters only. "
                    }
                    })}
                    helperText={errors.firstName && errors.firstName.message}
                />
                </Grid>
                <Grid item xs={6}>
                <TextField
                    fullWidth
                    required
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    value={query.get("lastname")}
                    error={!!errors.lastName}
                    inputRef={register({ 
                    required: "Last Name is required.",
                    pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Text only."
                    },
                    maxLength: {
                        value: 50,
                        message: "Last Name upto 50 characters only. "
                    }
                    })}
                    helperText={errors.lastName && errors.lastName.message}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    fullWidth
                    required
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={query.get("email")}
                    error={!!errors.email}
                    inputRef={register({
                    required: true,
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    })}
                    helperText={errors.email && "Enter a valid Email Address"}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    name="mobile"
                    label="Mobile"
                    type="number"
                    inputMode="numeric"
                    id="mobile" 
                    autoFocus={query.get("firstname") != undefined ? true : false}
                    inputProps={{
                    maxLength: 10,
                    }}
                    error={!!errors.mobile}
                    inputRef={register({ 
                    required: "Mobile Number is required.",
                    pattern: {
                        value: /^[0-9\b]+$/,
                        message: "Please enter numbers only."
                    },
                    maxLength: {
                        value: 10,
                        message: "Mobile Number should be 10 Digits"
                    },
                    minLength: {
                        value: 10,
                        message: "Mobile Number should be 10 Digits"
                    }
                    })}
                    autoComplete="mobile"
                    helperText={errors.mobile && errors.mobile.message}
                />
                </Grid>
                {query.get("email") != undefined ? "" : (
                  <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        error={!!errors.password}
                        inputRef={register({ 
                        required: "Password is required.",
                        minLength: {
                            value: 8,
                            message: "Password should be 8 Characters "
                        }
                        })}
                        helperText={errors.password && errors.password.message}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="invitecode"
                        label="Invite Code"
                        type="text"
                        inputMode="numeric"
                        id="invitecode"
                        value={query.get("invitecode")}
                        inputProps={{
                            maxLength: 10,
                        }}
                        error={!!errors.invitecode}
                        inputRef={register({
                            maxLength: {
                                value: 10,
                                message: "Invite Code should be 10 Digits"
                            },
                            minLength: {
                                value: 2,
                                message: "Enter Valid Invite Code "
                            }
                        })}
                        autoComplete="Invite Code"
                        helperText={errors.invitecode && errors.invitecode.message}
                    />
                </Grid>
                <Grid item xs={12}>
                <FormControlLabel
                    control={
                    <Checkbox
                        as={Checkbox}
                        name="tnc"
                        color="secondary" 
                        className={classes.tncCheckbox}
                        onChange={handleCheckbox}
                        checked={tnc}
                    />}
                    inputRef={register({ required: true })}
                    label="I agree to all T&amp;C"
                />
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Button
                    type="submit"
                    variant="contained"
                    color="default"
                    name="regButton"
                    className={classes.submit}
                >
                    REGISTER
                </Button>
            </Grid>
            </form>


            {query.get("email") != undefined ? "" : (
              <React.Fragment>
                <Typography component="p">
                OR
                </Typography>
                <Typography component="p">
                <br/>
                REGISTER WITH SOCIAL MEDIA
                </Typography>

                <Box m="1rem">
                <LinkMat href={Constants.API_URL+"/auth/google"}>
                    <Icon className="fab fa-google-plus-square" style={{ fontSize: 40 }} color="secondary" />
                </LinkMat>
                <LinkMat href={Constants.API_URL+"/auth/facebook"}>
                    <Icon className="fab fa-facebook-square" style={{ fontSize: 40 }} color="primary" />
                </LinkMat>
                </Box>
                <Box textAlign="center" m={2}>
                  <Typography component="p">
                    <Link className={classes.linkFooter} to="/TermsAndConditions">Terms and Conditions</Link>
                    <br></br><Link className={classes.linkFooter} to="/PrivacyPolicy">Privacy Policy</Link>
                    <br></br><Link className={classes.linkFooter} to="/RefundsCancellationsPolicy">Refunds/Cancellations Policy</Link>

                  </Typography>
                </Box>
              </React.Fragment>
            )}
        </div>
        <Box mt={4} mb={2}>
            <Typography className={classes.drawerFooter} component="p">
            &copy; 2020 Copyright TapX. All rights reserved.
            </Typography>
        </Box>
        </Container>
    </MuiThemeProvider>
  );
}

import React from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import LinkMat from '@material-ui/core/Link';
import Rating from '@material-ui/lab/Rating';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';

import axios from "axios";
import * as Constants from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flex: '1',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  appBar: {
    position: 'relative',
    background: 'rgb(224, 68, 30)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  deals: {
    // background: 'linear-gradient(106deg, #fc4a1a 63%, #f7b733 60%) fixed'
  },
  headline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.5rem'
  },
  shortdesc: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    marginTop: '10px',
    fontSize: '0.75rem'
  },
  tagline: {
    fontFamily: 'gilroy-extrabold',
    fontSize: '1.25rem',
    marginTop: '30px',
    "& p": {
      transform: 'rotate(90deg)',
      width: '0',
      margin: '0 0 0 10px'
    }
  },
  overview:{
    display:'flex',
    flexWrap: 'wrap',
    marginBottom: '30px',
    "& p" : {
      fontFamily: 'gilroy-regular',
      letterSpacing : '0.1em',
      fontSize : '1em',
      width: '100%'
    },
  },
  overviewSection1 : {
    display:'flex',
    alignItems: 'center',
    marginTop: '10px',
    "& p" : {
      width: 'auto',
      "& img" : {
        width : '30vw',
        height : '30vw',
        borderRadius: '30vw'
      },
    },
    "& div" : {
      margin: '0 0 0 20px',
      fontSize: '1rem',
      fontFamily: 'gilroy-bold',
      letterSpacing: '0.1rem'
    }
  },
  overviewSection2 : {
    display:'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
    width: '100%',
    "& p" : {
      fontSize: '1rem',
      width: '65%',
      textAlign: 'justify',
      letterSpacing : 'normal',
    },
    "& div" : {
      fontSize: '0.9rem',
      textAlign: 'right',
      "& span.MuiRating-readOnly" : {
      fontSize: '0.75rem',
      },
      "& p": {
        margin: 0,
        padding: '5px 10px',
        borderRadius: '15px',
        backgroundColor: '#E04420',
        color: '#ffffff',
        textTransform: 'uppercase',
        width: '100%',
        lineHeight: 'normal',
        fontSize: '0.6rem',
        fontFamily: 'gilroy-bold'
      }
    }
  },
  about:{
    display:'flex',
    flexWrap: 'wrap',
    marginBottom: '30px',
    "& p" : {
      fontFamily: 'gilroy-regular',
      letterSpacing : '0.1em',
      fontSize : '1em'
    },
  },
  aboutSection1 : {
    display:'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    width:'100%',
    "& p" : {
      fontFamily: 'gilroy-bold',
      fontSize: '1.25rem',
      width: '50%',
      letterSpacing : 'normal',
    },
    "& div" : {
      display: 'inline-flex',
      fontSize: '0.9rem',
      "& span" : {
        margin: '5px 0 0 5px'
      }
    }
  },
  aboutSection2 : {
    display:'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    "& p" : {
      fontSize: '1rem',
      width: '60%',
      textAlign: 'justify',
      letterSpacing : 'normal',
    },
    "& div" : {
      fontSize: '0.9rem',
      textAlign: 'right',
      "& span.MuiRating-readOnly" : {
      fontSize: '0.75rem',
      }
    }
  },
  tnc : {
    fontFamily: 'gilroy-regular',
    fontSize: '0.85rem',
    marginTop: '10px'
  },
  inactive : {
    maxWidth: '100%',
  },
  paperHeadline : {
    fontFamily: 'gilroy-regular',
    letterSpacing : '0.1em',
    fontSize : '0.75em'
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    margin: '10px -2px !important'
  },
  gridListTile: {
    borderRadius: '6vw',
    marginRight: theme.spacing(2),
    padding: '2vw !important',
    height:'auto !important',
    backgroundSize: 'cover !important',
    fontFamily: 'gilroy-bold',
    background: '#323031',
    justifyContent: 'center',
    textAlign: 'center'
  },
  tagListId : {
    marginTop: '15px',
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: '100%',
    "& img" : {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%'
    }
  },
  tagListTitle : {
    fontSize: '0.75rem',
    fontFamily: 'gilroy-regular',
    marginTop: '10px',
  },
  tagListBottom : {
    display: 'flex',
    position: 'absolute',
    bottom: '5px',
    width: '100%'
  },
  tagListBalance : {
    fontSize: '0.5rem',
    "& span" : {
      fontSize: '0.6rem',
    },
    "& label" : {
      fontSize: '0.8rem',
      fontFamily: 'gilroy-regular',
    }
  },
  tagListActions : {
    fontSize:'1em',
    position: 'absolute',
    right: '0',
    bottom: '0',
    "& span" : {
      padding: '0 2px'
    }
  },
  redeem: {
    background: '#E04420',
    padding: '2px 3px',
    margin: '5px 0',
    color: 'white'
  },
  DialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: 'white',
    mixBlendMode: 'difference',
    "& canvas" : {
      width: '80vw !important',
      height: '80vw !important'
    }
  },

}));


const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    backgroundColor: 'red',
  },
}))(LinearProgress);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DealsDetails(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [statusloading, setStatusLoading] = React.useState(false);
  const [dealInfo, setDealInfo] = React.useState({});
  const [dealAvail, setDealAvail] = React.useState();
  const [dealStatus, setDealStatus] = React.useState();
  const [progress, setProgress] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [resMessage, setResponseMessage] = React.useState();
  const [snackSeverity, setSnackSeverity] = React.useState("error");
  const [MAX, setMax] = React.useState();
  let { dealid } = useParams();

  React.useEffect(() => {
    axios.get(Constants.API_URL+"/api/deals/" + dealid).then((response) => {
        console.log(response.data.DealData);
        setDealInfo(response.data.DealData[0]);
        setMax(parseInt(response.data.DealData[0].deal_goal));
    });
    checkDealAvail(props.currUser.id);
  }, []);

  const checkDealAvail = (user) => {
    axios.post(Constants.API_URL+"/api/checkRedemption/",{
      "userid" : user,
      "dealid" : parseInt(dealid)
    }).then((response) => {
        setDealStatus(response.data.message);
        setDealAvail(response.data.success);
        setLoading(false);
    });
  }

  const normalise = value => (value - 0) * 100 / (MAX - 0);

  const handleClickOpen = () => {
    setStatusLoading(true);
    axios.post(Constants.API_URL+"/api/deals/activateDeal",{
      "userid" : props.currUser.id,
      "accessToken" : props.currUser.accessToken,
      "dealid" : parseInt(dealid)
    }).then((response) => {
        setStatusLoading(false);
        if(!response.data.success) {
          setOpenSnackbar(true);
          setSnackSeverity("error");
          setResponseMessage(response.data.message);
        }
        else {
          if(response.data.openQR) {
            setDealAvail(response.data.success);
            setDealStatus("Open QR");
            setProgress();
            setOpen(true);
          }else {
            setOpenSnackbar(true);
            setSnackSeverity("success");
            setResponseMessage(response.data.message);
            if(response.data.milestone) setProgress(parseInt(response.data.milestone));
            setDealStatus("Get Status");
          }
        }

    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container spacing={5} className={classes.deals} maxWidth="md" component="main">
        {loading ? (
          <Box class="loader loader-quart"></Box>
        ) : (
          <Box>
            {statusloading ? (
              <Backdrop className={classes.backdrop} open={statusloading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            ):""}
            <Box pt={4}>
              <Typography className={classes.headline} variant="h4" component="h2" >
                  Deals
              </Typography>
            </Box>

            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
              open={openSnackbar}
              key={'top' + 'center'}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackSeverity}>
                {resMessage}
              </Alert>
            </Snackbar>

            <Box className={classes.overview} mt={2}>
              <Typography component="p">
                OVERVIEW
              </Typography>
              <Box className={classes.overviewSection1}>
                <Typography component="p">
                  <img src={dealInfo.deal_image} />
                </Typography>
                <Typography component="div">
                  {dealInfo.deal_name}
                </Typography>
              </Box>
              <Box className={classes.overviewSection2}>
                <Typography component="p">
                  Expires on: {dealInfo.deal_end_date}
                </Typography>
                {(props.currUser != "") ? (
                  dealInfo.is_expired ? (
                    <Typography component="div">
                      <p>Deal Expired</p>
                    </Typography>
                  ) : (
                    dealAvail ? (
                      dealStatus=="Open QR" ? (
                        <Typography component="div" onClick={() => setOpen(true)}>
                          <p>{dealStatus}</p>
                        </Typography>
                      ): (
                        <Typography component="div" onClick={handleClickOpen}>
                          <p>{dealStatus}</p>
                        </Typography>
                      )
                    ) : (
                      <Typography component="div" onClick={handleClickOpen}>
                        <p>{dealStatus}</p>
                      </Typography>
                    )
                  )
                ) : (
                  <Link to="/signin">
                    <Typography component="div">
                      <p>Login to Get Deal</p>
                    </Typography>
                  </Link>
                )}
              </Box>
            </Box>
            <Box className={classes.progress} mt={2}>
              {(dealInfo.deal_goal != null && progress!=undefined)? (
                <React.Fragment>
                  <p>PROGRESS ({Math.round(normalise(progress))}%)</p>
                  <BorderLinearProgress variant="determinate" value={normalise(progress)} />
                </React.Fragment>
              ) : ""}
            </Box>
            <Box className={classes.about} mt={2}>
              <Typography component="p">
                ABOUT MERCHANT
              </Typography>
              <Box className={classes.aboutSection1}>
                <Typography component="p">
                  The Daily All Day Bar &amp; Restaurant
                </Typography>
                <Typography component="div">
                  <LinkMat href="https://www.google.com/maps/@18.574378,73.9680659,17z" color="inherit">
                    Directions <span class="icon-Icons_Go-To"></span>
                  </LinkMat>
                </Typography>
              </Box>
              <Box className={classes.aboutSection2}>
                <Typography component="p">
                  <i>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et dolore</i>
                </Typography>
                <Typography component="div">
                  Ratings (4.6) <br/>
                  <Rating
                  name="read-only"
                  value={4.6}
                  precision={0.1}
                  readOnly
                  className={classes.ratings}/>
                </Typography>
              </Box>
            </Box>
            <Box className={classes.overview} mt={2}>
              <Typography component="p">
                TERMS &amp; CONDITIONS
              </Typography>
              <Box className={classes.tnc}>
                {dealInfo.deal_desc}
              </Box>
            </Box>

            {/* <Box className={classes.paperContainer} mt={2}>
              <Typography component="p" className={classes.paperHeadline}>
                SIMILAR DEALS
              </Typography>
              <Grid item className={classes.inactive}>
                <GridList className={classes.gridList} cols={3}>
                  <GridListTile
                  className={classes.gridListTile}>
                    <Box className={classes.tagListId}>
                      <img src="/assets/images/homescreen1.jpg" />
                    </Box>
                    <Box className={classes.tagListTitle}>
                      Buy 1 Get 1
                    </Box>
                    <Box className={classes.dealRedeem}>
                      <Link to={"/deals/2"}><Button className={classes.redeem}>REDEEM</Button></Link>
                    </Box>
                  </GridListTile>
                </GridList>
              </Grid>
            </Box> */}
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Redeem Deal
                </Typography>
                <Button autoFocus color="inherit" onClick={handleClose}>
                  Done
                </Button>
              </Toolbar>
            </AppBar>

            <Box className={classes.DialogContent}>
              {dealInfo.id != undefined ? (
                <QRCode value={
                  btoa('{"user_id":'+props.currUser.id+',"deal_id":'+dealInfo.id+',"deal_name":"'+dealInfo.deal_name+'"}')
                }
                  />
              ) : '' }
            </Box>

            </Dialog>
          </Box>
        )}
      </Container>
    </React.Fragment>
  );
}

import React, {Component} from 'react';
import {Switch, Route, Redirect} from "react-router-dom";
import './utils/fonts.css';
import './utils/tapx.css';
import './App.css';
import DynamicScrollToTop from "./utils/scrollTop";
import {Header, Footer, LandingPage} from './components/layout';
import Homepage from './modules/common/home';
import SignIn from './modules/development/user/signin';
import SignUp from './modules/development/user/signup';
import VerifyOTP from './modules/development/user/verifyotp';
import UserTags from './modules/development/user/tags';
import UserTickets from './modules/development/user/tickets';
import Profile from './modules/development/user/profile';
import Authenticate from './modules/development/user/authenticate';
import TopUp from './modules/development/topup/';
import TestSubmit from './modules/development/topup/test/test_submit';
import Deals from './modules/marketing/deals';
import DealsDetails from './modules/marketing/deals/details';
import UserDeals from './modules/development/user/deals';
import MerchantHome from './modules/development/merchants';
import MerchantDetails from './modules/development/merchants/details';
import MerchantCategories from './modules/development/merchants/categories';
import Transaction from './modules/development/transactions/';
import TicketCheckout from './modules/development/tickets/checkout';
import TicketResponse from './modules/development/tickets/checkout/response';
import Shop from './modules/marketing/shop';
import ShopDetails from './modules/marketing/shop/details';
import ShopCategory from './modules/marketing/shop/category';
import ShopCheckout from './modules/marketing/shop/checkout';
import ShopResponse from './modules/marketing/shop/checkout/response';
import Orders from './modules/development/user/orders';
import OrdersDetail from './modules/development/user/orders/details';
import Feedback from './modules/development/feedback/';
import About from './modules/common/about';
import Faq from './modules/common/faq';
import PrivacyPolicy from './modules/common/PrivacyPolicy';
import TermsAndConditions from './modules/common/TermsAndConditions';
import RefundsCancellationsPolicy from './modules/common/RefundsCancellationsPolicy';
import Contact from './modules/common/contact';
import NoMatch from './modules/common/404';
import AuthService from "./services/auth.service";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';

import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';


const theme = createMuiTheme({
	typography: createTypography(createPalette({}), {
		fontFamily: '"sourcesanspro-Regular"'
	}),
});

export default function App() {
	const [loading, setLoading] = React.useState(true);
	const [currentUser, setCurrentUser] = React.useState();
	const [fromEvent, setFromEvent] = React.useState(false);

	React.useEffect(() => {
		setUser();
		setLoading(false);
	}, []);

	function setUser() {
		const user = AuthService.getCurrentUser();
		if (user) {
			setCurrentUser(user);
		}
		console.log('user:', user, currentUser);
	}
	function setFromEventValue(state){
		setFromEvent(state);
	}

	return (
		<MuiThemeProvider theme={theme}>
			<div className="main">
				<LandingPage />
				<Header currUser={currentUser} fromEvent = {fromEvent} />
				<DynamicScrollToTop/>
				{!loading ? (
					<Switch>
						<Route exact path="/">
							{/*<Homepage currUser={currentUser}/>*/}
							<Redirect to="/topup" />
						</Route>
						<Route exact path={["/modules/development/user/authenticate/", "/authenticate"]}>
							<Authenticate onChange={setUser}/>
						</Route>
						<Route exact path={["/modules/development/user/signin/", "/signin"]}>
							<SignIn onChange={setUser}/>
						</Route>
						<Route exact path={["/modules/development/user/signup/", "/signup" ]}>
							<SignUp onChange={setUser}/>
						</Route>
						<Route exact path={["/modules/development/user/verifyotp/:id", "/verifyotp/:id"]}>
							<VerifyOTP onChange={setUser}/>
						</Route>

						<Route exact path={["/modules/development/user/profile/", "/profile/:user"]}>
							<Profile currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/development/user/tags/", "/tags/:user"]}>
							<UserTags  currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/development/user/tickets/", "/:user/tickets"]}>
							<UserTickets  currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/development/merchants/", "/merchants"]}>
							<MerchantHome currUser={currentUser || []}  />
						</Route>
						<Route exact path={["/modules/development/merchants/details/", "/merchants/:merchid"]}>
							<MerchantDetails currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/development/merchants/categories/", "/merchants/category/:catid"]}>
							<MerchantCategories currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/marketing/deals/", "/deals" ]}>
							<Deals currUser={currentUser || []}/>
						</Route>
						<Route exact path={["/modules/marketing/deals/details", "/deals/:dealid"]}>
							<DealsDetails currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/development/user/deals", "/:user/deals"]}>
							<UserDeals currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/development/topup/", "/topup"]}>
							<TopUp currUser={currentUser || []} handler={setFromEventValue} />
						</Route>
						<Route exact path={["/modules/development/transactions/", "/transactions" ]}>
							<Transaction currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/development/feedback/", "/feedback"]}>
							<Feedback />
						</Route>
						<Route exact path={["/modules/development/transactions/backup_index", "/transactions/backup_index"]}>
							<Transaction currUser={currentUser} />
						</Route>
						<Route exact path={["/modules/development/tickets/checkout/", "/tickets/checkout"]}>
							<TicketCheckout currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/development/tickets/checkout/response/", "/tickets/checkout/response"]}>
							<TicketResponse currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/marketing/shop/", "/shop"]}>
							<Shop currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/marketing/shop/category/", "/shop/category/:catid"]}>
							<ShopCategory currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/development/tickets/checkout/", "/tickets/checkout"]}>
							<TicketCheckout currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/marketing/shop/checkout/", "/shop/checkout"]}>
							<ShopCheckout currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/marketing/shop/checkout/response/", "/shop/checkout/response"]}>
							<ShopResponse currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/marketing/shop/details/", "/shop/details/:shopid"]}>
							<ShopDetails currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/development/user/orders/", "/:user/orders"]}>
							<Orders currUser={currentUser || []} />
						</Route>
						<Route exact path={["/modules/development/user/orders/details", "/:user/orders/:orderid"]}>
							<OrdersDetail currUser={currentUser || []} />
						</Route>

						<Route exact path={["/modules/common/about", "/about"]}>
							<About />
						</Route>
						<Route exact path={["/modules/common/faq", "/faq"]}>
							<Faq />
						</Route>
						<Route exact path={["/modules/common/PrivacyPolicy", "/PrivacyPolicy"]}>
							<PrivacyPolicy />
						</Route>
						<Route exact path={["/modules/common/TermsAndConditions", "/TermsAndConditions"]}>
							<TermsAndConditions />
						</Route>
						<Route exact path={["/modules/common/RefundsCancellationsPolicy", "/RefundsCancellationsPolicy"]}>
							<RefundsCancellationsPolicy />
						</Route>
						<Route exact path={["/modules/common/contact", "/contact"]}>
							<Contact />
						</Route>
						<Route exact path={["/modules/development/topup/test/test_submit", "/topup/test"]}>
							<TestSubmit />
						</Route>
						<Route path="*">
							<NoMatch />
						</Route>
					</Switch>

				) : ""
				}

				{!fromEvent && typeof currentUser!=='undefined' ? (<Footer currUser={currentUser}/>) : ''}


			</div>
		</MuiThemeProvider>
	);
}

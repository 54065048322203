import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  faq: {
    margin: '10px auto',
    width: '90vw',

  },
  title: {
    padding: '16px 0'
  },
  addressText: {
    marginTop: '30px',
    marginBottom:'100px'
  },
}));

export default function PrivacyPolicy()  {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <React.Fragment>
      <Box className={classes.faq}>
        <Typography  className={classes.title} variant="h3" component="h4">
          Privacy Policy
        </Typography>
        <Typography className={classes.addressText}>
          <p>This privacy policy (<b>“Privacy Policy”</b>) explains our privacy practices for the TapX Website (the <b>“Website”</b>). The Website is operated by AtomX Corporation Private Limited, and its subsidiaries, licensees and affiliated companies (collectively, <b>“AtomX/We/Our”</b>).</p>

          <p>By using the Website, and the services provided therein (the <b>“Services”</b>), you (<b>“You/Your/User”</b>) are accepting the terms of this Privacy Policy. Any external links to other websites are clearly identifiable as such, and we are not responsible for the content or the privacy policies of those other websites. We recognize the importance of your privacy and respect that you want to safeguard your personal information shared with us. Subsequently, we too make it a high priority to ensure that your personal information (including credit card details) remains secure and confidential and is used only for the intended purpose Website.</p>

          <b>1.	GENERAL</b>

          <p>1.1	This Privacy Policy describes how we collect, use, consult or process any personal information provided through the Website and/or our Services. This Privacy Policy is intended to inform Users about how we gather, define, and use the information that is provided to us when using our Website. Please take a moment to read this Privacy Policy carefully.</p>

          <p>1.2	AtomX may change or amend this Privacy Policy from time to time to incorporate necessary future changes. AtomX’s use of the Personal Information shall be in adherence with the provisions of the relevant Privacy Policy under which the information was collected, regardless of new or conflicting provisions in an updated Privacy Policy.</p>

          <p>1.3	AtomX’s terms of use requires all account owners to be at least eighteen (18) years of age (or have the permission and supervision of a responsible parent or legal guardian), therefore this Privacy Policy does not discuss the use of our Website or our Services by minors.</p>

          <p>1.4	Please read through this Privacy Policy to better understand what data we use and how we use your data.</p>

          <b>2	COLLECTION OF PERSONAL INFORMATION</b>

          <p>2.1	In order to ease the convenience of your use of the Website, we may collect the following personal information (together, <b>“Personal Information”</b>):</p>
          <p>
          2.1.1	Contact details (for example, last name, first name, telephone number, email)<br></br>
          2.1.2	Information (for example, date of birth)<br></br>
          2.1.3	Information that you provide to us such as your name, address, date of birth, telephone number, email address, bank account and payment card details<br></br>
          2.1.4	Your contact details and details of the emails and other electronic communications you receive from us, including whether that communication has been opened and if you have clicked on any links within that communication. We want to make sure that our communications are useful for you, so if you don’t open them or don’t click on any links in them, we know we need to improve our services.
          </p>
          <p>2.2	For the purposes of this Privacy Policy, personal information means information that can be used to identify and contact you, specifically your name, address, e-mail address, country of residence, as well as other information when such information is combined with your personal information. Personal information does not include “aggregate” information, which is data we collect about the use of our site and our services. Our Privacy Policy does not restrict or limit our collection and use of aggregate information.</p>

          <p>2.3	We may automatically collect some information about your hardware devices when you access the Website. For example, when you utilize our Website, we may collect your internet protocol (<b>“IP”</b>) address and the type of device you use and your geographic location. We may combine this automatically gathered information with other information, including personal information we have collected about you.</p>

          <p>2.4	We may receive information from third parties (including, for example, business partners, providers of technical, and payment services, advertising networks analytics providers, and search information providers) about your usage trends.</p>

          <p>2.5	If you send us personal correspondence, such as emails or queries through our Website, we may collect such information into a file specific to you.</p>

          <b>3	USE OF YOUR PERSONAL INFORMATION</b>

          <p>3.1	In order to provide a personalised browsing experience, AtomX may collect information from you and you agree that AtomX may use such information to improve its marketing and promotional efforts, to analyse usage, improve the contents of the Website, and to customize the AtomX content, layout and Services, in order to tailor it to better meet your needs.</p>

          <p>3.2	We may use your personal information and other information we obtain from your current and past activities on the Website to: resolve disputes, troubleshoot problems, help promote safe trading, collect any fees owed, measure consumer interest in the services provided by us, inform you about offers and updates; customize your experience; detect and protect us against any error, fraud and other criminal activity; enforce our terms of use; and as otherwise described to you at the time of collection. At times, we may look across multiple users to identify problems or resolve disputes. We may compare and review your personal information for errors, omissions and for accuracy.</p>

          <p>3.3	We may contact you about other services we might offer that are similar to those you have already purchased or enquired about, or with any other related marketing material.</p>

          <p>3.4	To extend this personalized experience, AtomX may track the internet protocol (<b>“IP”</b>) address of your device and may save certain Personal Information on your device in the form of cookies. AtomX uses this data to deliver web pages to you upon request, to customize the Website to your interests, to measure traffic on the Website and to let advertisers know the geographic location the Website’s users. You agree that we may use your Personal Information to contact you and deliver information to that, in some cases are targeted to your interests, such as targeted banner advertisements, administrative notices and communications relevant to your use of the Website. Such portion of the Personal Information provided by you to AtomX that may be personal or sensitive in nature, will not be provided to third parties without your prior consent. Personal Information of a general nature may however be revealed to external parties.</p>

          <p>3.5	We use or may use the data collected through cookies, log file, device identifiers, location data and clear gifs information to (a) remember information so that you do not have to re-enter it during your visit or the next time you visit the Website; (b) provide personalized content and information; (c) provide and monitor the effectiveness of our Services; (d) monitor aggregate metrics and demographic patterns; (e) diagnose or fix technology problems; and (f) otherwise to plan for an enhance our Website.</p>

          <p>3.6	We may use third parties that we refer to as internal service providers to facilitate or outsource one or more aspects of the business and service operations (such as credit card payments) that we provide to you on the Website, and therefore we may provide some of your personal information directly to those internal service providers. These internal service providers are subject to confidentiality agreements with us and other legal restrictions that prohibit their use of the information we provide to them for any other purpose except to facilitate the specific outsourced Website related operation, unless you have explicitly agreed or given your prior permission to them for additional uses. If you do provide additional information to an internal service provider directly, then their use of your personal information is governed by that internal service provider’s privacy policy.</p>

          <p>3.7	When you top up your AtomX tag via net banking, credit/debit card or any other online wallet, you shall be required to provide information, which may include payment instrument number (e.g credit card number) name, and billing address,  the security code associated with the payment instrument, etc (<b>“Payment Data”</b>). We use Payment Data to complete transactions, as well as to detect and prevent any fraud. When you provide Payment Data while logged in, AtomX may store such data to facilitate any future transactions. Upon deletion or closure of your account, we may retain the Payment Data for as long as reasonably necessary to comply with AtomX’s legal and reporting requirements. In the course of processing or transmitting your Payment Data, we will observe and fully comply with the current Data Security Standard of the PCI Security Standards Council.</p>

          <b>4	SHARING YOUR PERSONAL INFORMATION</b>

          <p>We do not sell, disclose or share the Personal Information that we collect and hold, except as described in this Privacy Policy.</p>

          <p>4.1	We may share your Personal Information and your information(including but not limited to, information from cookies, log files, device identifiers, location data and usage data) with businesses that are legally part of the same group of companies that AtomX is a part of, or that become part of that group (<b>“Affiliates”</b>). Affiliates may use this information to help provide, understand, and improve the Service (including by providing analytics) and Affiliates’ own services (including by providing you with better and more relevant experiences).</p>

          <p>4.2	We also may share your information as well as information from tools like cookies, log files, and device identifiers and location data, with third-party organizations that help us provide services to you. Our service providers will be given access to your information as is reasonably necessary to provide the services.</p>

          <p>4.3	We may remove parts of the data that can identify you and share anonymized data with other parties. We may also combine your information with other information in a way that it is no longer associated with you and share such aggregated information.</p>

          <p>4.4	We also may share your Personal Information as well as information tools like cookies, log files and device identifiers and location data, with third party organizations (including, but not limited to payment gateways, channel managers and email service providers, etc) that can help us provider our Services to you. Our service providers will be given access to your  information as is reasonably necessary to provide the Services under reasonable confidentiality terms. We may share your Personal Information with third party services such as email providers and network providers to send you confirmation emails.</p>

          <p>4.5	We may share your sensitive Personal Information with any third party without obtaining your prior consent in the following limited circumstances:</p>

          <p>4.5.1	When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing the terms of use, this Privacy Policy or for complying with the applicable laws and regulations.</p>
            <p>4.5.2	AtomX proposes to share such information within its group companies, officers and employees of such group companies for the purpose of processing Personal Information. AtomX ensures that the recipients of such information agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.</p>

          <b>5	COMMUNICATION AND MARKETING</b>

          <p>5.1	When you create an account and/or obtain access to our Services through our Website you will be able to receive promotional emails regarding latest offers and discounts from us.</p>

          <p>5.2	Please note that in case you don’t wish to receive such e-mail, you have the full liberty to opt-out of such communication at any time.</p>

          <p>5.2.1	To unsubscribe from our email list, you can send us an email at hello@atomx.in.</p>
          <p>5.2.2	Please note, that should you opt-out of our emailing list, as described here, we will not be able to remove your information from the databases of the affiliates, franchisees or business partners with which we have already shared your data, prior to the date of your opt-out request.</p>

          <b>6	SECURITY</b>

          <p>6.1	The security of your data is important to us. Your account information is protected by a password. It is important you protect against unauthorized access of your account and information by choosing your password carefully and keeping your password and computer secure by signing out after using our services.</p>

          <p>6.2	We have implemented procedures to help protect the information that you provide to us.  However, no method of transmitting or storing electronic data is ever completely secure, and we cannot guarantee that such information will never be accessed, used, or released in a manner that is inconsistent with this policy.  You agree and acknowledge that we may engage third party services providers for the provision of cloud service storage solutions, we are  not responsible for any lapse in security on the part of such service providers. We expressly disclaim any representation or warranty, whether express or implied, with respect to ensuring, guaranteeing, or otherwise offering any definitive promise of security in connection with your information. YOU ARE SOLELY RESPONSIBLE FOR MAINTAINING THE SECRECY OF YOUR ACCOUNT INFORMATION AT ALL TIMES.</p>

          <p>6.3	You shall be solely responsible for all activities performed by you through your account. We assume no responsibility or liability for the improper use of information relating to usage of credit cards and/or debit cards by you, whether online or off-line.</p>

          <p>6.4	To protect your privacy and security, we will verify your identity before granting access or making changes to your Personal Information. Once you have registered with AtomX, your AtomX username and password will be required in order for you to access your profile information.</p>

          <p>6.5	AtomX stores information about you for as long as we provide Service to you, and longer for our functions and business activities. After such information is no longer necessary for our purposes, we destroy the information.</p>

          <b>7	CONTACT US</b>

          <p>7.1	Questions, comments, remarks, requests or complains regarding this Privacy Policy are welcome and should be addressed by the User to hello@atomx.in</p>

        </Typography>
      </Box>
    </React.Fragment>
  );
}

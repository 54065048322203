import React from "react";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { red, blue, green, yellow, purple, lime, grey, deepPurple, amber } from "@material-ui/core/colors";
import {Container, CssBaseline, Box, Avatar, Paper, Grid, Typography, Button, Popover} from '@material-ui/core/';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import RedeemIcon from '@material-ui/icons/Redeem';
import CallMadeIcon from '@material-ui/icons/CallMade';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import QRCode from 'qrcode.react';
import axios from "axios";
import * as Constants from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    flex: '1',
  },
  status: {
    textTransform: "capitalize"
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 400 : 800],
    position:'absolute',
    left: 0
  },
}));


export default function UserDeals(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [dealInfo, setDealInfo] = React.useState({});

  const normalise = (value, goal) => (value - 0) * 100 / (goal - 0);

  React.useEffect(() => {
    //Get User Deals 
    axios.post(Constants.API_URL+"/api/user/deals",{
      "userid" : props.currUser.id,
      "accessToken" : props.currUser.accessToken,
    }).then((response) => {
      setDealInfo(response.data.userDealInfo);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container spacing={5} className={classes.deals} maxWidth="md" component="main">
        {(props.currUser.length != 0) ? (
          <Box mt={4}>
            <Box display="flex" alignItems="center" justifyContent="center">
                <RedeemIcon style={{ fontSize: 60, color: amber[600]}} />
                <Typography component="h3" variant="h5">
                  &nbsp;&nbsp;YOUR DEALS
                </Typography>
            </Box>
            {loading ? (
              // <Box class="loader loader-quart"></Box>
              <Box>
                <Box mt={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <Skeleton style={{backgroundColor:"#fff"}} variant="circle" width={40} height={40} />
                  <Skeleton style={{backgroundColor:"#fff"}} variant="text" width="60%" />
                  <Skeleton style={{backgroundColor:"#fff"}} variant="rect" width="90%" height={118} />
                </Box>
                <Box mt={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <Skeleton style={{backgroundColor:"#fff"}} variant="circle" width={40} height={40} />
                  <Skeleton style={{backgroundColor:"#fff"}} variant="text" width="60%" />
                  <Skeleton style={{backgroundColor:"#fff"}} variant="rect" width="90%" height={118} />
                </Box>
              </Box>
            ) : (
              <Box>
                {(dealInfo.length > 0) ? (
                  <Box>
                    <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                        <Typography component="h3" variant="p">
                          {dealInfo.length} Deals Found
                        </Typography>
                    </Box>

                    {dealInfo.map((deals, index) => (
                      <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                        <Card className={classes.root}>
                          <CardHeader
                            avatar={<Avatar alt={deals.deal_name} src={deals.deal_image} />}
                            action={
                              <IconButton aria-label="settings">
                                <Link to={"/deals/"+deals.deal_id}><CallMadeIcon /></Link>
                              </IconButton>
                            }
                            title={deals.deal_name+" @ "+deals.merch_name}
                            subheader={"Expires On: "+moment(deals.deal_end_date).format("MMM DD YYYY")}
                          />
                          <CardContent style={{background:grey[200]}}>
                              <Box display="flex" alignItems="center" justifyContent="center">
                                {/* <Box p={1} flexGrow={1}>
                                  <Typography className={classes.status} gutterBottom variant="h5" component="h2">
                                    {deals.status}
                                  </Typography>
                                </Box> */}
                                <Box p={1}>
                                  {(deals.deal_goal != null && deals.status=="activated")? (
                                    <React.Fragment>
                                      <Box position="relative" display="inline-flex">
                                        <CircularProgress 
                                        className={classes.bottom}
                                        variant="determinate" 
                                        value={100}
                                        thickness={10}
                                        size={80} />
                                        <CircularProgress 
                                        variant="determinate" 
                                        value={normalise(deals.total_amount,deals.deal_goal)}
                                        thickness={10}
                                        size={80} />
                                        <Box
                                          top={0}
                                          left={0}
                                          bottom={0}
                                          right={0}
                                          position="absolute"
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                        >
                                          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                                            normalise(deals.total_amount,deals.deal_goal)
                                          )}%`}</Typography>
                                        </Box>
                                      </Box>
                                    </React.Fragment>
                                  ) : ""}
                                  {deals.status=="completed" ? (
                                    <QRCode 
                                    style={{
                                      width:'40vw',
                                      height:'40vw',
                                    }}
                                    value={
                                      btoa('{"user_id":'+props.currUser.id+',"deal_id":'+deals.deal_id+',"deal_name":"'+deals.deal_name+'"}')
                                    }
                                    />
                                  ) : (
                                    deals.status=="redeemed" ? (
                                      <Box>
                                        <span class="stamp is-success">Redeemed</span>
                                      </Box>
                                    ) : (
                                      deals.status=="expired" ? (
                                      <Box>
                                        <span class="stamp is-nope">Expired</span>
                                      </Box>
                                      ): ""
                                    )
                                  )}
                                  
                                </Box>
                              </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    ))}
                  </Box>
                ): (
                  <Box mt={5} display="flex" alignItems="center" justifyContent="center">
                      <Typography component="h3" variant="p">
                        No Deals Found
                      </Typography>
                  </Box>
                ) }
              </Box>
            )}
          </Box> 
        ) : (
          <Box className="login_message">
              You need to <Link to="/signin">LOGIN</Link> to View Deals
          </Box>
        )}
      </Container>
    </React.Fragment>
  );
}
